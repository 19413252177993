import { DeleteIcon, ImageIcon, AccordionArrow, PillarIcon, CloseIcon } from '../../icons/icons';
import { decodeHtmlEntities } from '../../../utils/helpers';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

export default class ImageTextOverlay {

  static get toolbox() {
    return {
      icon: PillarIcon,
      title: 'Image text overlay',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Heading'
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Body'
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
      strong: true,
      a: true,
      i: true,
      sub: true,
      sup: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      btn: 'cdx-media-btn',
      addButton: 'add-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      /**
       * Tune styles
       */
      secondaryImgTuneContainer: 'cdx-img-tune-container',
      secondaryImgTuneLabel: 'cdx-img-tune-label',
      secondaryImgTuneBtn: 'cdx-img-tune-input',
      toggleTuneContainer: 'cdx-img-tune-container',
      toggleTuneLabel: 'cdx-img-tune-label',
      toggleTuneBtn: 'cdx-img-tune-input',
      /**
       * Standard editor tune styles
       */
      tuneWrapper: 'editor-block-tune',
      tuneTitle: 'editor-block-tune-title',
      tuneText: 'editor-block-tune-text',
      tuneInput: 'editor-block-tune-input',
      tuneButton: 'editor-block-tune-button',
      /**
       * Standard editor styles
       */
      customInput: 'editor-custom-input',
      /**
       * Text section classes
       */
      mainContainer: 'cdx-image-with-text-container-main',
      overlayContainer: 'cdx-overlay-container',
      overlayCloseBtn: 'cdx-overlay-close-btn',
      heading: 'cdx-heading-input',
      bodyMain: 'cdx-body-container',
      bodyRedactor: 'cdx-body-redactor',
      body: 'cdx-body-input',
      imgBtn: 'cdx-image-button',
      imgBtnText: 'cdx-image-button-text',
      chevron: 'cdx-image-button-chevron',
      imagesContainer: 'cdx-images-container',
      imgContainer: 'cdx-image-container',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      images: [
        {
          url: data?.images?.[0]?.url || '',
          mid: data?.images?.[0]?.mid || '',
          width: data?.images?.[0]?.width || '',
          height: data?.images?.[0]?.height || '',
          x: data?.images?.[0]?.x || '',
          y: data?.images?.[0]?.y || '',
          maxWidth: data?.images?.[0]?.maxWidth || '',
          maxHeight: data?.images?.[0]?.maxHeight || '',
        },
        {
          url: data?.images?.[1]?.url || '',
          mid: data?.images?.[1]?.mid || '',
          width: data?.images?.[1]?.width || '',
          height: data?.images?.[1]?.height || '',
          x: data?.images?.[1]?.x || '',
          y: data?.images?.[1]?.y || '',
          maxWidth: data?.images?.[1]?.maxWidth || '',
          maxHeight: data?.images?.[1]?.maxHeight || '',
        },
      ],
      heading: data.heading || '',
      body: data.body || '',
      secondaryImage: data.secondaryImage || false,
      toggleOption: data.toggleOption || this._settings.toggleEnabled || false,
    };

    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : ImageTextOverlay.DEFAULT_HEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : ImageTextOverlay.DEFAULT_BODY_PLACEHOLDER;
    this._files = config.customFiles || null;
    this._primaryImage = this.getImageElement(0);
    this._secondaryImage = this.getImageElement(1);
    this._heading = this.getHeadingElement();
    this._body = this.getBodyElement();
    this._overlay = this.getOverlayElement();
    this._imageButton = this.getImageToggleButton();
    this._mainContainer = null;
    this._imagesContainer = null;
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }

  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  getImageElement(imageIndex) {
    let img;

    if (this._data.images[imageIndex].url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', decodeHtmlEntities(this._data.images[imageIndex].url));
      img.setAttribute('data-mid', this._data.images[imageIndex].mid);
      img.setAttribute('data-x', this._data.images[imageIndex].x);
      img.setAttribute('data-y', this._data.images[imageIndex].y);
      img.setAttribute('data-width', this._data.images[imageIndex].width);
      img.setAttribute('data-height', this._data.images[imageIndex].height);
      img.setAttribute('data-maxwidth', this._data.images[imageIndex].maxWidth);
      img.setAttribute('data-maxheight', this._data.images[imageIndex].maxHeight);
    }

    return img;
  }

  getHeadingElement() {
    let tag = document.createElement('h4');

    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.heading, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, tag, true);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag, true);
      });
      tag.addEventListener("input", () => {
        let imgBtnText = this._imageButton.querySelector(`.${this._CSS.imgBtnText}`)
        if (imgBtnText) {
          imgBtnText.innerText = this.stripHtmlTags(tag.textContent);
        }
      });
    }

    return tag
  }

  getBodyElement() {
    let tag = document.createElement('p');

    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.body, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._body);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getOverlayElement() {
    const tag = document.createElement('div');
    const closeBtn = document.createElement('div');
    tag.classList.add(this._CSS.overlayContainer);
    closeBtn.classList.add(this._CSS.overlayCloseBtn);

    closeBtn.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"24px\" viewBox=\"0 -960 960 960\" width=\"24px\"><path d=\"m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z\"/></svg>"

    closeBtn.addEventListener('click', () => this.hideOverlay());

    tag.appendChild(closeBtn);
    tag.appendChild(this._heading);
    tag.appendChild(this._body);

    return tag
  }

  getImageToggleButton() {
    const tag = document.createElement('div');
    const btnText = document.createElement('div');
    const chevron = document.createElement('div');

    tag.classList.add(this._CSS.imgBtn);
    btnText.classList.add(this._CSS.imgBtnText);
    chevron.classList.add(this._CSS.chevron);

    btnText.innerHTML = this._data.heading;
    chevron.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"24px\" viewBox=\"0 -960 960 960\" width=\"24px\"> <path d=\"M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z\" /> </svg>"

    tag.appendChild(btnText);
    tag.appendChild(chevron);

    tag.addEventListener('click', () => {
      if (this._settings.toggleEnabled && this._data.toggleOption) {
        this.toggleOverlay();
      } else {
        this.showOverlay();
      }
    });

    return tag;
  }

  getImageContainer(imageIndex) {
    const container = document.createElement('div');
    container.classList.add(this._CSS.imgContainer);
    if (imageIndex === 1) {
      container.classList.add('secondary-image-container');
    }

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);


      if (this._data.images[imageIndex].url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.classList.add(this._CSS.replaceButton);
        btn.innerHTML = `${ImageIcon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall);
        btnRemove.classList.add(this._CSS.removeButton, this._CSS.clear, this._CSS.xxlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.classList.add(this._CSS.addButton);
        btn.innerHTML = `${ImageIcon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        if (this._files) {
          if (this._settings.secondaryImageEnabled) {
            if (this._data.secondaryImage) {
              this._settings.showMediaLibrary({
                imageIndex: imageIndex,
                details: block,
                data: this._data,
                bundle: 'image',
                customFiles: this._files.secondary,
              });
            } else {
              this._settings.showMediaLibrary({
                imageIndex: imageIndex,
                details: block,
                data: this._data,
                bundle: 'image',
                customFiles: this._files.primary,
              });
            }
          }
        } else if (this._settings.dynamicImageSize) {
          let target = btn.closest('.ce-block');
          let aspectRatio = target.offsetWidth / target.offsetHeight
          this._settings.showMediaLibrary({
            imageIndex: imageIndex,
            details: block,
            data: this._data,
            bundle: 'image',
            aspectRatio: aspectRatio,
          })
        } else {
          this._settings.showMediaLibrary({
            imageIndex: imageIndex,
            details: block,
            data: this._data,
            bundle: 'image',
          });
        }
      };

      btnRemove.onclick = () => {
        this._data.images[imageIndex].url = '';
        this._data.images[imageIndex].mid = '';
        this._data.images[imageIndex].width = '';
        this._data.images[imageIndex].height = '';
        this._data.images[imageIndex].x = '';
        this._data.images[imageIndex].y = '';
        this._data.images[imageIndex].maxWidth = '';
        this._data.images[imageIndex].maxHeight = '';
        if (imageIndex === 0) {
          this._primaryImage.remove();
        } else if (imageIndex === 1) {
          this._secondaryImage.remove();
        }
        container.classList.remove('has-img')
        btnRemove.remove();
      }

      if (imageIndex === 0) {
        if (typeof this._primaryImage !== 'undefined') {
          container.appendChild(this._primaryImage);
          container.appendChild(btn);
          container.appendChild(btnRemove);
          container.classList.add('has-img');
        } else {
          // hidden input hack
          // prevents empty blocks being created
          let input = document.createElement('input');
          input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

          container.appendChild(input);
          container.appendChild(btn);
        }
      } else if (imageIndex === 1) {
        if (this._settings.secondaryImageEnabled && this._data.secondaryImage) {
          if (typeof this._secondaryImage !== 'undefined') {
            container.appendChild(this._secondaryImage);
            container.appendChild(btn);
            container.appendChild(btnRemove);
            container.classList.add('has-img');
          } else {
            // hidden input hack
            // prevents empty blocks being created
            let input = document.createElement('input');
            input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

            container.appendChild(input);
            container.appendChild(btn);
          }
        }
      }
    } else {
      if (imageIndex === 0) {
        if (typeof this._primaryImage !== 'undefined') {
          container.appendChild(this._primaryImage);
          container.classList.add('has-img');
        }
      } else if (imageIndex === 1) {
        if (this._settings.secondaryImageEnabled && this._data.secondaryImage) {
          if (typeof this._secondaryImage !== 'undefined') {
            container.appendChild(this._secondaryImage);
            container.classList.add('has-img');
          }
        }
      }
    }

    return container;
  }

  showOverlay() {
    if (!this._mainContainer.classList.contains('show')) {
      this._mainContainer.classList.add('show');
    }
  }

  hideOverlay() {
    if (this._mainContainer.classList.contains('show')) {
      this._mainContainer.classList.remove('show');
    }
  }

  toggleOverlay() {
    if (this._mainContainer.classList.contains('show')) {
      this._mainContainer.classList.remove('show');
    } else if (!this._mainContainer.classList.contains('show')) {
      this._mainContainer.classList.add('show');
    }
  }

  stripHtmlTags(str) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;

    return tempDiv.textContent || tempDiv.innerText || "";
  }

  render() {
    this._mainContainer = document.createElement('div');
    this._imagesContainer = document.createElement('div');

    this._mainContainer.classList.add(this._CSS.mainContainer);
    this._imagesContainer.classList.add(this._CSS.imagesContainer);


    this._imagesContainer.appendChild(this.getImageContainer(0));
    if (this._settings.secondaryImageEnabled && this._data.secondaryImage) {
      this._mainContainer.classList.add('has-secondary');
      this._imagesContainer.appendChild(this.getImageContainer(1));
    }
    if (this._settings.toggleEnabled && this._data.toggleOption) {
      this._mainContainer.classList.add('toggle-option');
    }

    this._mainContainer.appendChild(this._imageButton);
    this._mainContainer.appendChild(this._overlay);
    this._mainContainer.appendChild(this._imagesContainer);

    return this._mainContainer;
  }

  renderSettings() {
    const tuneSettingsContainer = document.createElement('div');
    const secondaryImageContainer = document.createElement('div');
    const toggleOptionContainer = document.createElement('div');

    if (this._settings.secondaryImageEnabled) {
      const css = this._CSS;
      const secondaryImageLabel = document.createElement('label');
      const secondaryImageBtn = document.createElement('button');

      secondaryImageContainer.classList.add(css.secondaryImgTuneContainer, css.tuneWrapper);
      secondaryImageLabel.classList.add(css.secondaryImgTuneLabel, css.tuneTitle);
      secondaryImageBtn.classList.add(css.secondaryImgTuneBtn, css.tuneInput);

      secondaryImageLabel.innerText = '2 secondary images';
      secondaryImageBtn.innerText = this._data.secondaryImage ? 'True' : 'False';
      secondaryImageBtn.addEventListener('click', () => {
        this._data.secondaryImage = !this._data.secondaryImage;
        secondaryImageBtn.innerText = this._data.secondaryImage ? 'True' : 'False';

        if (!this._data.secondaryImage) {
          this._imagesContainer.querySelector('.secondary-image-container').remove();
          this._mainContainer.classList.remove('has-secondary');

        } else if (!this._imagesContainer.querySelector('.secondary-image-container')) {
          this._imagesContainer.appendChild(this.getImageContainer(1));

          if (!this._mainContainer.classList.contains('has-secondary')) {
            this._mainContainer.classList.add('has-secondary');
          }
        }
      });

      secondaryImageContainer.appendChild(secondaryImageLabel);
      secondaryImageContainer.appendChild(secondaryImageBtn);

      tuneSettingsContainer.appendChild(secondaryImageContainer);
    }

    if (this._settings.toggleEnabled && this._settings.toggleTune) {
      const css = this._CSS;
      const toggleLabel = document.createElement('label');
      const toggleBtn = document.createElement('button');

      toggleOptionContainer.classList.add(css.toggleTuneContainer, css.tuneWrapper);
      toggleLabel.classList.add(css.toggleTuneLabel, css.tuneTitle);
      toggleBtn.classList.add(css.toggleTuneBtn, css.tuneInput);

      toggleLabel.innerText = 'Toggle button';
      toggleBtn.innerText = this._data.toggleOption ? 'True' : 'False';
      toggleBtn.addEventListener('click', () => {
        this._data.toggleOption = !this._data.toggleOption;
        toggleBtn.innerText = this._data.toggleOption ? 'True' : 'False';
        if (this._data.toggleOption) {
          if (!this._mainContainer.classList.contains('toggle-option')) {
            this._mainContainer.classList.add('toggle-option');
          }
        } else {
          this._mainContainer.classList.remove('toggle-option');
        }
      });

      toggleOptionContainer.appendChild(toggleLabel);
      toggleOptionContainer.appendChild(toggleBtn);

      tuneSettingsContainer.appendChild(toggleOptionContainer);
    }
    return tuneSettingsContainer;
  }

  save() {
    this._data.heading = this._heading.innerHTML;
    this._data.body = this._body.innerHTML;

    return this._data;
  }
}