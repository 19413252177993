import React from "react";

import { Row, Col } from "react-bootstrap";
import { Typography, Box } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/BookmarkBorderOutlined";


export default function ListHeader(props) {

  return (
    <Box className="list-header-container"
      sx={{ marginTop: "50px" }}
    >

      <Row className="list-header-row">
        {props.items.map((item, index) => {
          return (
            <Col
              key={`list-${index}`}
              xs={item.width.xs}
              sm={item.width.sm}
              md={item.width.md}
              lg={item.width.lg}
              xl={item.width.xl}
              xxl={item.width.xxl}
              className={`list-header-label-col ${item.grow ? `flex-grow-${item.grow}` : ""} ${item.colClass ? item.colClass : ""}`}
            >
              <Typography
                owner={props.labelConfig.owner}
                variant={props.labelConfig.variant}
                color={props.labelConfig.color}
                weight={props.labelConfig.weight}
                lh={props.labelConfig.lh}
              >
                {/* {item.title === "Fav." ? <BookmarkIcon sx={{ fontSize: "1.3rem" }} /> : item.title} */}
                {item.title}
              </Typography>
            </Col>
          )
        })}
      </Row>
    </Box>

  )
}