/**
 * Build styles
 */
require('./index.css').toString();
const { make } = require('./util');

export default class BlockBackgroundTool {
    /**
     * Default colour
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_COLOR() {
        return '';
    }

    static get isTune() {
        return true;
    }

    getColor() {
        if (!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)) {
            return this.settings.blocks[this.block.name]
        }
        if (!!this.settings?.default) {
            return this.settings.default
        }
        return BlockBackgroundTool.DEFAULT_COLOR
    }

    constructor({ api, data, config, block }) {
        this.api = api;
        this.block = block;
        this.settings = config;
        this.data = data;
        this.colors = config.options;
        this._CSS = {
            wrapper: 'cdx-bg-color-picker-settings',
            button: 'cdx-color-picker-button',
            span: 'cdx-color-picker',
            tuneWrapper: 'editor-block-tune',
            tuneTitle: 'editor-block-tune-title',
            tuneText: 'editor-block-tune-text',
            tuneInput: 'editor-block-tune-input',
            tuneContent: 'editor-block-tune-content-container'
        };
    }

    wrap(blockContent) {
        this.wrapper = make("div");
        this.wrapper.append(blockContent);
        this.wrapper.style.width = '100%';
        this.wrapper.style.height = '100%';

        this.target = this.wrapper.querySelector(".cdx-text-container");
        if (this.data.bgColor !== "") {
            this.target.classList.add(this.data.bgColor.name);
        }

        return this.wrapper;
    }

    render() {
        const wrapper = make("div", [this._CSS.wrapper, this._CSS.tuneWrapper]);
        const label = make("label",[this._CSS.tuneTitle]);
        const content = make("div",[this._CSS.tuneContent]);

        label.innerText = 'Background colour:'
        this.colors.map(color => {
            const button = document.createElement('button');
            const span = document.createElement('span');

            button.classList.add(this._CSS.button)
            button.type = 'button';
            span.classList.add(this._CSS.span);

            span.style.boxShadow = `${color.value} 0px 0px 0px 15px inset`;
            button.classList.toggle('colorCircleActive', color.value === this.data?.bgColor?.value);
            button.appendChild(span);
            content.appendChild(button);
            return button
        }).forEach((element, index, elements) => {
            element.addEventListener('click', () => {
                this.data = {
                    bgColor: this.colors[index]
                }
                elements.forEach((el, i) => {
                    el.classList.toggle('colorCircleActive', this.colors[i].value === this.data?.bgColor?.value);
                    this.colors.forEach((e) => {
                        if(this.target.classList.contains(e.name)){
                            this.target.classList.remove(e.name);
                        }
                    })
                    this.target.classList.add(this.data?.bgColor?.name);
                });
            });
        });
        wrapper.append(label);
        wrapper.append(content);
        
        return wrapper;
    }

    save() {
        return this.data;
    }
}
