
const defaultState = {
  user: {},
};

export const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_SESSION':
      localStorage.setItem('user', JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
      };

    case 'REMOVE_SESSION':
      localStorage.removeItem('user');

      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};