import React from "react";

import { filesystem_root, serverUrl, subdomain } from "../../config";

import GridItem from "../../partials/gridItem";

export default function Forum(props) {

  let item = {
    title: props.item?.title,
    nid: props.item?.tid,
    body: props.item?.description__value
  }


  return (
    <GridItem
      xs={12}
      itemClass="forum"
      linkTo
      route="forums"
      nid={item?.nid}
      title={item?.title}
      field_body={item.body}
      disableCategory
    />
  )
}