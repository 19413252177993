import React, { useState, useEffect } from 'react';

const LoadingTextAnimated = ({text, speed}) => {
  const [loadingText, setLoadingText] = useState('Loading');
  const [numCommans, setNumCommans] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumCommans(prevNum => (prevNum + 1) % 5); // Cycle through 0, 1, 2, 3
    }, speed ? speed : 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const commas = '.'.repeat(numCommans);
    setLoadingText(`${text ? text : ''}${commas}`);
  }, [numCommans]);

  return loadingText;
};

export default LoadingTextAnimated;
