import { api } from '../../src/config';

export const get_csrf_token = () => {
  return api({
    method: 'get',
    url: '/session/token?_format=json',
  });
};

export const get_logout_token = () => {
  return api({
    method: 'get',
    url: '/session/token/logout?_format=json',
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
};

export const auth_login = (username, password, csrf_token) => {
  return api({
    method: 'post',
    url: '/user/login?_format=json',
    headers: {
      'X-CSRF-Token': csrf_token,
    },
    data: { name: username, pass: password },
  });
};

export const auth_logout = (logout_token) => {
  return api({
    method: 'get',
    url: `/user/logout?_format=json&token=${logout_token}`,
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
};