import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export function Redirect({url}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      navigate(url);
    }
  }, [navigate, url]);

  return null;
}