import React, { useEffect, useState } from "react";

// Modules
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// App
import { getOnlineNode } from "../../core/getNode";
import { postNode } from "../../core/postNode";
import { serverUrl } from "../../config";
import CompanyappLoader from "../../partials/companyappLoader";
import NoData from "../../partials/noData";
import Pagination from "../../partials/pagination";

// UI components
import { Row, Col } from "react-bootstrap";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Box,
  Alert,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  OutlinedInput,
  Input,
  MenuItem,
  Stack,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchOutlined from "@mui/icons-material/SearchOutlined";

const pageSize = 4;
const input_font_size = 14;
const input_variant = "outlined";

export default function PushNotifications() {
  // redux
  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer
    .permissions;

  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [isError, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const [pushError, setPushError] = useState(null);
  const [pushSuccess, setPushSuccess] = useState(null);

  const [selectedTokens, setSelectedTokens] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [keyValuePairs, setKeyValuePairs] = useState([
    {
      key: "nid",
      value: "",
    },
    {
      key: "screen",
      value: "",
    },
  ]);

  const [roles, setRoles] = useState([
    {
      id: "all",
      label: "All roles",
    },
  ]);
  const [filters, setFilters] = useState({
    uid: "",
    username: "",
    name: "",
    email: "",
    roles: ["all"],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tokens, setTokens] = useState([]);

  // redux listener
  // fetch content when filters are updated
  // also runs on initial render
  useEffect(() => {
    if (permissions["send push notifications"]) {
      setCurrentPage(1);
      getContent(0);
    } else {
      setErrorStatus(403);
      setErrorMessage("You do not have permission to access this page.");
      setError(true);
    }
  }, []);

  const onApply = () => {
    setCurrentPage(0);
    getContent();
  };

  const onReset = () => {
    setCurrentPage(0);
    setFilters({
      uid: "",
      username: "",
      name: "",
      email: "",
      roles: ["all"],
    });
    getContent();
  };

  const getContent = (page) => {
    setSearching(true);

    // Base API path with pagination
    let path = `api/fcm/token/list?items_per_page=${pageSize}&page=${
      page ? page : 0
    }`;

    // Add filters dynamically
    const params = new URLSearchParams();

    if (filters.uid && filters.uid !== "all") {
      params.append("uid", filters.uid);
    }

    if (filters.username && filters.username !== "all") {
      params.append("username", filters.username);
    }

    if (filters.name && filters.name !== "all") {
      params.append("name", filters.name);
    }

    if (filters.email && filters.email !== "all") {
      params.append("email", filters.email);
    }

    if (filters.roles.length > 0 && filters.roles[0] !== "all") {
      params.append("roles", filters.roles.join(","));
    }

    // Append filters to the path
    if (params.toString()) {
      path += `&${params.toString()}`;
    }

    getOnlineNode(path)
      .then((response) => {
        setLoading(false);
        setSearching(false);
        setTokens(response.data.rows);
        setTotalItems(response.data.pager.total_items);

        // Add "All roles" to roles list if it's part of the response
        const newRoles = [
          {
            id: "all",
            label: "All roles",
          },
          ...response.data.roles,
        ];
        setRoles(newRoles);
      })
      .catch((error) => {
        console.log("error: ", error);
        setError(true);

        if (error?.response?.status) {
          setErrorStatus(error.response.status);
        }

        if (error?.response?.statusText) {
          setErrorMessage(error.response.statusText);
        } else {
          setErrorMessage("Failed to fetch tokens. Unknown error!");
        }
      });
  };

  // Toggle token selection
  const handleToggle = (token) => {
    setSelectedTokens((prev) =>
      prev.includes(token) ? prev.filter((t) => t !== token) : [...prev, token]
    );
  };

  // Clear selected tokens
  const handleClear = () => setSelectedTokens([]);

  // Add new key-value pair
  const addKeyValuePair = () => {
    setKeyValuePairs([...keyValuePairs, { key: "", value: "" }]);
  };

  // Remove a key-value pair
  const removeKeyValuePair = (index) => {
    setKeyValuePairs((prev) => prev.filter((_, i) => i !== index));
  };

  // Update key-value pair
  const handleKeyValueChange = (index, field, value) => {
    const updatedPairs = [...keyValuePairs];
    updatedPairs[index][field] = value;
    setKeyValuePairs(updatedPairs);
  };

  // Updated handleSend using postNode
  const handleSend = async () => {
    const payload = keyValuePairs.reduce((acc, pair) => {
      if (pair.key) acc[pair.key] = pair.value;
      return acc;
    }, {});

    const requestBody = {
      tokens: selectedTokens,
      title,
      body,
      payload,
    };

    try {
      const response = await postNode(
        "api/fcm/push23",
        requestBody,
        user.csrf_token
      );

      if (response && response.status === 200) {
        setPushError(null);
        setPushSuccess("Notification sent successfully!");

        // Clear all data upon success
        setSelectedTokens([]);
        setTitle("");
        setBody("");
        setKeyValuePairs([
          {
            key: "nid",
            value: "",
          },
          {
            key: "screen",
            value: "",
          },
        ]);
      } else {
        setPushError("Failed to send notification.");
        setPushSuccess(null);
      }
    } catch (error) {
      setPushError("An error occurred while sending the notification.");
      setPushSuccess(null);
    } finally {
      setOpen(false);
    }
  };

  // const pagination = paginator(totalItems, currentPage, pageSize, 3);

  return (
    <>
      {isLoading ? (
        <div className="circular-container fs">
          <CompanyappLoader />
        </div>
      ) : (
        <>
          {isError ? (
            <Row className="h-100 justify-content-center align-self-center">
              <Col>
                <Box
                  className="d-flex h-100 justify-content-center align-items-center align-content-center"
                  sx={{ flexDirection: "column" }}
                >
                  <Alert variant="filled" severity="error">
                    {errorStatus ? `${errorStatus} - ` : ""}
                    {errorMessage}
                  </Alert>
                </Box>
              </Col>
            </Row>
          ) : (
            <main className="tokens screen">
              {isSearching ? (
                <div className="circular-container fs">
                  <CompanyappLoader />
                </div>
              ) : (
                <>
                  {pushError && (
                    <Row>
                      <Col>
                        <Box
                          className="d-flex h-100 justify-content-center align-items-center align-content-center"
                          sx={{ flexDirection: "column" }}
                        >
                          <Alert
                            variant="filled"
                            severity="error"
                            action={
                              <Button
                                color="inherit"
                                size="small"
                                onClick={() => setPushError(null)}
                              >
                                OK
                              </Button>
                            }
                          >
                            {pushError}
                          </Alert>
                        </Box>
                      </Col>
                    </Row>
                  )}

                  {pushSuccess && (
                    <Row>
                      <Col>
                        <Box
                          className="d-flex h-100 justify-content-center align-items-center align-content-center"
                          sx={{ flexDirection: "column" }}
                        >
                          <Alert
                            variant="filled"
                            severity="success"
                            action={
                              <Button
                                color="inherit"
                                size="small"
                                onClick={() => setPushSuccess(null)}
                              >
                                DONE
                              </Button>
                            }
                          >
                            {pushSuccess}
                          </Alert>
                        </Box>
                      </Col>
                    </Row>
                  )}

                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <Row className="filter-container w-100 align-items-end justify-content-center">
                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          className="filter-item mb-3"
                        >
                          <FormControl fullWidth size="small">
                            <TextField
                              id="uid"
                              label="UID"
                              variant={input_variant}
                              value={filters.uid}
                              size="small"
                              placeholder="User ID"
                              onChange={(event) => {
                                // event.target.value
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  uid: event.target.value,
                                }));
                              }}
                              InputProps={{
                                style: { fontSize: input_font_size },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchOutlined
                                      sx={{
                                        width: "20px",
                                        height: "18px",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                style: { fontSize: input_font_size },
                              }}
                              owner="admin"
                              sub_variant="presentation-filter"
                            />
                          </FormControl>
                        </Col>

                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          className="filter-item mb-3"
                        >
                          <FormControl fullWidth size="small">
                            <TextField
                              id="name"
                              label="Name"
                              variant={input_variant}
                              value={filters.name}
                              size="small"
                              placeholder="Name"
                              onChange={(event) => {
                                // event.target.value
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  name: event.target.value,
                                }));
                              }}
                              InputProps={{
                                style: { fontSize: input_font_size },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchOutlined
                                      sx={{
                                        width: "20px",
                                        height: "18px",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                style: { fontSize: input_font_size },
                              }}
                              owner="admin"
                              sub_variant="presentation-filter"
                            />
                          </FormControl>
                        </Col>

                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          className="filter-item mb-3"
                        >
                          <FormControl fullWidth size="small">
                            <TextField
                              id="username"
                              label="Username"
                              variant={input_variant}
                              value={filters.username}
                              size="small"
                              placeholder="Username"
                              onChange={(event) => {
                                // event.target.value
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  username: event.target.value,
                                }));
                              }}
                              InputProps={{
                                style: { fontSize: input_font_size },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchOutlined
                                      sx={{
                                        width: "20px",
                                        height: "18px",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                style: { fontSize: input_font_size },
                              }}
                              owner="admin"
                              sub_variant="presentation-filter"
                            />
                          </FormControl>
                        </Col>

                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          className="filter-item mb-3"
                        >
                          <FormControl fullWidth size="small">
                            <TextField
                              id="email"
                              label="Email"
                              variant={input_variant}
                              value={filters.email}
                              size="small"
                              placeholder="Email"
                              onChange={(event) => {
                                // event.target.value
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  email: event.target.value,
                                }));
                              }}
                              InputProps={{
                                style: { fontSize: input_font_size },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchOutlined
                                      sx={{
                                        width: "20px",
                                        height: "18px",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                style: { fontSize: input_font_size },
                              }}
                              owner="admin"
                              sub_variant="presentation-filter"
                            />
                          </FormControl>
                        </Col>

                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          className="filter-item mb-3"
                        >
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: "primary.light" }}
                          >
                            <InputLabel
                              sx={{
                                fontSize: input_font_size,
                                color: "rgba(0, 0, 0, 0.87)",
                              }}
                              size="small"
                              id="roles-select-label"
                            >
                              Roles
                            </InputLabel>
                            <Select
                              labelId="roles-select-label"
                              id="roles-select"
                              value={filters.roles} // Bind to the roles in the filters object
                              multiple
                              label="Roles"
                              onChange={(event) => {
                                const selectedRoles = event.target.value;

                                // Logic to handle "All roles" selection
                                if (selectedRoles.includes("all")) {
                                  setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    roles: selectedRoles.includes("all")
                                      ? ["all"]
                                      : selectedRoles,
                                  }));
                                } else {
                                  setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    roles: selectedRoles,
                                  }));
                                }
                              }}
                              renderValue={(selected) => selected.join(", ")}
                              input={
                                input_variant === "outlined" ? (
                                  <OutlinedInput
                                    owner="admin"
                                    sub_variant="presentation-filter"
                                    sx={{
                                      fontSize: input_font_size,
                                    }}
                                    label="Roles"
                                  />
                                ) : (
                                  <Input
                                    sx={{
                                      fontSize: input_font_size,
                                      color: "red",
                                    }}
                                    label="Roles"
                                  />
                                )
                              }
                            >
                              {roles.map((role, index) => (
                                <MenuItem
                                  key={`roles-${index}`}
                                  value={role.id}
                                  disabled={
                                    filters.roles.includes("all") &&
                                    role.id !== "all" // Disable other roles if "All roles" is selected
                                  }
                                >
                                  <Checkbox
                                    size="small"
                                    checked={
                                      filters.roles.indexOf(role.id) > -1
                                    }
                                    sx={{ marginLeft: "-10px", padding: "5px" }}
                                  />
                                  <ListItemText
                                    disableTypography
                                    primary={
                                      <Typography
                                        style={{ fontSize: input_font_size }}
                                      >
                                        {role.label}
                                      </Typography>
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={2}
                          className="filter-item mb-3 align-self-end"
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <FormControl className="btn" fullWidth>
                              <Button variant="outlined" onClick={onReset}>
                                <Typography
                                  owner="admin"
                                  variant="button"
                                  className="lh"
                                >
                                  Reset
                                </Typography>
                              </Button>
                            </FormControl>
                            <FormControl className="btn" fullWidth>
                              <Button
                                type="submit"
                                sx={{ fontSize: input_font_size }}
                                variant="contained"
                                onClick={onApply}
                              >
                                <Typography
                                  owner="admin"
                                  variant="button"
                                  className="lh"
                                >
                                  Apply
                                </Typography>
                              </Button>
                            </FormControl>
                          </Stack>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {tokens.length > 0 ? (
                    <>
                      <Row className="tokens-container">
                        <TableContainer component={Box}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <strong>UID</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Select</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Username</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Email</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Roles</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Token</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tokens.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.uid}</TableCell>
                                  <TableCell>
                                    <Checkbox
                                      checked={selectedTokens.includes(
                                        row.token
                                      )}
                                      onChange={() => handleToggle(row.token)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {`${row.firstName || ""} ${
                                      row.lastName || ""
                                    }`.trim()}
                                  </TableCell>
                                  <TableCell>{row.username}</TableCell>
                                  <TableCell>{row.email}</TableCell>
                                  <TableCell>{row.roles.join(", ")}</TableCell>
                                  <TableCell>
                                    {row.token}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Row>

                      <Row>
                        <Box
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                          marginTop={2}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClear}
                            sx={{ marginRight: "10px" }}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(true)}
                            disabled={!selectedTokens.length}
                          >
                            Send
                          </Button>
                        </Box>
                      </Row>

                      <Pagination
                        owner="admin"
                        containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
                        previousDisabled={currentPage === 1 ? true : false}
                        nextDisabled={tokens.length < pageSize ? true : false}
                        currentPage={currentPage}
                        onPrevious={() => {
                          const current = currentPage - 1;
                          setCurrentPage(current);
                          getContent(current - 1);
                        }}
                        onNext={() => {
                          const current = currentPage + 1;
                          setCurrentPage(current);
                          getContent(current - 1);
                        }}
                      />
                    </>
                  ) : (
                    <NoData activeIndex={0} />
                  )}
                </>
              )}

              {/* Modal for Send Notification */}
              <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Send Notification</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    margin="normal"
                    multiline
                    rows={3}
                  />
                  <Typography variant="h6" marginTop={2} marginBottom={2}>
                    Payload
                  </Typography>
                  {keyValuePairs.map((pair, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                      <Grid item xs={5} marginBottom={2}>
                        <TextField
                          label="Key"
                          fullWidth
                          value={pair.key}
                          onChange={(e) =>
                            handleKeyValueChange(index, "key", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={5} marginBottom={2}>
                        <TextField
                          label="Value"
                          fullWidth
                          value={pair.value}
                          onChange={(e) =>
                            handleKeyValueChange(index, "value", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton onClick={() => removeKeyValuePair(index)}>
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={addKeyValuePair}
                    style={{ marginTop: "10px" }}
                  >
                    Add Key-Value
                  </Button>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSend}
                    color="primary"
                    variant="contained"
                    disabled={title.length < 1 || body.length < 1}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
            </main>
          )}
        </>
      )}
    </>
  );
}
