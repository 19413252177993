import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { alertMessages } from "../../partials/alertMessages";
import SearchItem from "./searchItem";
import Pagination from "../../partials/pagination";
import IframeDialog from "../../partials/iframeDialog";
import { serverUrl } from "../../config";

import PageHeader from "../../partials/pageHeader";
import { Row, Col } from "react-bootstrap";
import CompanyappLoader from "../../partials/companyappLoader";
import { Typography } from "@mui/material";

export default function SearchScreen(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [activeForm, setActiveForm] = useState(null);
  const [showIframe, setShowIframe] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();
  const query = params.query;

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
    getContent(1);
  }, [query]);

  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const processData = (_data) => {
    let tempData = {}
    if (_data.length > 0) {
      _data.forEach((e) => {
        if (tempData[e.type]) {
          tempData[e.type].push(e);
        }
        else {
          tempData[e.type] = [e];
        }
      })
    }
    setData(tempData);
  }

  const getContent = (_currentPage) => {
    let path = `${apiEndpoints.search}?title=${query}&items_per_page=${pageSize}&page=${_currentPage - 1}&_format=json`;
    getOnlineNode(path, user.access_token)
      .then((response) => {
        processData(response.data.rows);
        // setData(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  const renderGroupHeading = (type, index) => {
    let heading = ""
    switch (type) {
      case "News":
        heading = "News";
        break;
      case "Events":
        heading = "Events";
        break;
      case "Blogs":
        heading = "Blogs";
        break;
      case "Videos":
        heading = "Videos";
        break;
      case "Podcasts":
        heading = "Podcasts";
        break;
      case "Course":
        heading = "Training Courses";
        break;
      case "Quiz":
        heading = "Tests & Quizzes";
        break;
      case "Documents":
        heading = "Documents";
        break;
      case "External Resource":
        heading = "Useful Links";
        break;
      case "Webform":
        heading = "Surveys & Forms";
        break;
      default:
        heading = `Undefined ${type}`;
        break;
    }

    return (
      <div key={`group-${index}`}>
        <Row className="mt-4 mb-2">
          <Col xs={12}>
            <Typography
              owner="client"
              variant="h5"
              color="clientPrimary.main"
            >
              {heading}
            </Typography>
          </Col>
        </Row>
        {data[type].map((e, index) => {
          return (
            <Row key={`row-${index}`}>
              <Col xs={12} className="ps-5">
                <SearchItem
                  item={e}
                  showForm={(e) => {
                    setActiveForm(e);
                    setShowIframe(true);
                  }}
                />
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);
    setCurrentPage(_index);
    getContent(_index);
  };


  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  return (
    <main className="search screen">
      <PageHeader
        pageName="Search results"
        filters={false}
        owner="client"
      />
      {isLoading ?
        <CompanyappLoader /> :
        Object.keys(data).length > 0 ?
          <>
            {Object.keys(data).map((e, index) => renderGroupHeading(e, index))}
            {renderPagination()}
            <IframeDialog
              open={showIframe}
              onClose={() => {
                setActiveForm(null)
                setShowIframe(false);
              }}
              title={activeForm?.title}
              src={`${serverUrl}/node/${activeForm?.id}?app=true`}
            />
          </> :
          <Row className="mt-5">
            <Col xs={12} className="d-flex justify-content-center align-items-center">
              <Typography
                owner="client"
                variant="h5"
              >
                No results found
              </Typography>
            </Col>
          </Row>
      }
    </main>
  )
}