import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// App
import { apiEndpoints } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { paginator } from '../../core/paginator';
// import Pagination from '../../core/pagination';
import Pagination from '../../partials/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
// import { _checkContent } from '../../core/checkContent';
import NoData from '../../partials/noData';
import { decodeHtmlEntities } from '../../utils/helpers';
import PollItem from './pollIItem';
import IframeDialog from '../../partials/iframeDialog';
import ScreenCategories from '../../partials/screenCategories';
import ClientTheme from "../../core/clientTheme";
import { favoritesEnabled } from "../../config";

// UI components
import { Row, Col, Container } from 'react-bootstrap';
import { FormControl, InputLabel, Select, MenuItem, Input, Dialog, DialogContent, Box, Typography, IconButton } from '@mui/material';
import CompanyappLoader from '../../partials/companyappLoader';
import { serverUrl } from '../../config';
import CloseIcon from '@mui/icons-material/CloseRounded';


export default function Polls(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0)
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [activePoll, setActivePoll] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const clientTheme = { ...ClientTheme() };

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    getContent();
  }, [selectedCategoryItem]);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = () => {
    let path = `${apiEndpoints.polls}?status=1&promote=1&items_per_page=${pageSize}&page=${currentPage - 1}&_format=json`;

    const tid = selectedCategory;
    if (tid !== 0) {
      path = `${apiEndpoints.polls}/${tid}/all?status=1&promote=1&items_per_page=${pageSize}&page=${currentPage - 1}&_format=json`;
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        setData(response.data.rows);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };


  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      `${apiEndpoints.categories}poll_categories`,
      user.access_token,
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        // if (_error.response) {
        //   _setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // } else if (_error.request) {
        //   _setError(true, 0, alertMessages.requestError.message);
        // } else {
        //   _setError(true, 0, alertMessages.unkownError.message);
        // }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent();
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Polls',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.tid[0].value,
        label: item.name[0].value,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {

      return (

        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={() => { }}
          onPrevious={() => { }}
          onNext={() => { }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id)
    return categoryName ? categoryName.label : ''
  }

  // Data component
  const dataComponent = () => {
    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map((item, index) => {
            let catName = getCategoryName(item.field_category)
            return (
              <React.Fragment key={`poll-${index}`}>
                <PollItem item={item} showPoll={(id, question) => handleActivePoll(id, question)} />
              </React.Fragment>
            );
          })}
        </Row>
      );
    }

    else {
      return (
        <NoData activeIndex={activeIndex} />
      );
    }
  };

  const handleActivePoll = (id, question) => {
    setActivePoll({
      id: id,
      question: question
    });
    setDialogOpen(true);
  }

  const clearActivePoll = () => {
    setActivePoll("");
    setDialogOpen(false);
  }


  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {
        isError ?

          <Error
            status={errorStatus}
            message={errorMessage}
          />
          :
          <main className="polls  screen">
            <PageHeader
              pageName="Opinion Polls"
              filters={true}
              owner='client'
              pageNameColor="clientHeadingScreens"
              svgLogo={clientTheme.pageLogos.svg}
              pageLogo={clientTheme.pageLogos.polls}
              enableFavorites={favoritesEnabled}
              categorySelector={
                <ScreenCategories
                  title="Polls"
                  categoryName="polls"
                  selectedCategoryItem={selectedCategoryItem}
                  categoryOpen={categoryOpen}
                  setCategoryOpen={(value) => setCategoryOpen(value)}
                  handleCategorySelection={(value) => handleCategorySelection(value)}
                  categories={categories}
                />
              }
              getContent={() => {
                setActiveIndex(0);
                setIsLoading(true);
                getContent();
              }}
              activeTabIndex={activeIndex}
            />
            {isLoading ?
              <CompanyappLoader />
              :
              <>
                {dataComponent()}
                {renderPagination()}
              </>
            }
          </main>
      }
      <IframeDialog
        open={dialogOpen}
        onClose={() => clearActivePoll()}
        title={activePoll.title}
        src={`${serverUrl}/poll/${activePoll.id}?app=true`}
      />
    </>
  )
}
