import React, { useEffect, useState } from "react";

// Modules

// App
import MediaLibrary from "../mediaLibrary";
import BackgroundImageOpacity from "./backgroundImageOpacity";
import BackgroundImageSize from "./backgroundImageSize";
import BackgroundImageRepeat from "./backgroundImageRepeat";
import BackgroundImagePosition from "./backgroundImagePosition";

// UI components
import { Row, Col, Container } from "react-bootstrap";
import { Tooltip, Tabs, Tab, Button, Typography, SvgIcon } from "@mui/material";
import { Crop } from '@mui/icons-material';

const DEFAULT_DIMENSIONS = {
  minWidth: 1366,
  minHeight: 768,
  maxWidth: 1920,
  maxHeight: 1080,
  aspectRatio: 16 / 9,
};

export default function BackgroundImagePicker(props) {
  const [backgroundFilter, setBackgroundFilter] = useState("all");
  const [backgroundImage, setBackgroundImage] = useState(
    props.slideBackgroundImage ? props.slideBackgroundImage : ""
  );
  const [isUyoImage, setUyoImage] = useState(false);
  const [uyo, setUyo] = useState(typeof props.slideBackgroundImage ? true : false);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [changeImageLibrary, setChangeImageLibrary] = useState(false);
  const [mediaType, setMediaType] = useState("image");
  const dimensions =
    props?.slideLayout?.id === "one-column-arch-image" ||
      props?.slideLayout?.id === "one-column-half-circle-image"||
      props?.slideLayout?.id === "campaign-info"
      ? undefined
      : DEFAULT_DIMENSIONS;
  const [transparentFocus, setTransparentFocus] = useState("");

  const background_images = props.background_images
    ? props.background_images
    : [""];

  useEffect(() => {
    if (typeof backgroundImage === "string") {
      setUyo(false);
      setUyoImage(false);
    } else {
      setUyo(true);
      setUyoImage(true);
    }
  }, [backgroundImage, isUyoImage]);

  const onImageSelected = (image) => {
    setBackgroundImage(image);
    props.onImageSelected(image);
  };

  const onUyoImageSelected = (media) => {
    setBackgroundImage(media);
    props.onImageSelected(media);
  };

  const renderFilters = () => {
    let items = [];
    items.push(
      <Tab
        sx={{ textTransform: "none" }}
        key={`flt-all`}
        label="All"
        value="all"
      />
    );

    for (var key in background_images) {
      if (key === "Old system") {
        if (props.user?.current_user?.name === "companyapp") {
          items.push(
            <Tab
              sx={{ textTransform: "none" }}
              key={`flt-${key}`}
              value={key}
              label={key}
            />
          );
        }
      } else {
        items.push(
          <Tab
            sx={{ textTransform: "none" }}
            key={`flt-${key}`}
            value={key}
            label={key}
          />
        );
      }
    }

    return (
      <Tabs
        className={`${props.transparentSettings ? "transparent-opacity" : ""}`}
        value={backgroundFilter}
        onChange={(event, newValue) => {
          setBackgroundFilter(newValue);
        }}
      >
        {items}
      </Tabs>
    );
  };

  const renderImages = () => {
    let data = [];
    data.push(
      <div
        key={`image-col-no-img`}
        className={`image-item ${backgroundImage === "" ? `selected` : ""
          } all ${backgroundFilter !== "all" ? "d-none" : ""
          } col-xxl-2 col-xl-3 col-lg-4 col-sm-6`}
      >
        <div className="btn-container">
          {props.showTooltip ? (
            <Tooltip title={`none`} placement="top">
              <Button
                onClick={() => {
                  onImageSelected("");
                  setUyoImage(false);
                }}
              >
                <span className="nbg">No image</span>
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={() => {
                onImageSelected("");
                setUyoImage(false);
              }}
            >
              <span className="nbg">No image</span>
            </Button>
          )}
        </div>
      </div>
    );

    if (Object.keys(background_images).length > 0) {
      for (var key in background_images) {
        const itemKey = key;
        const group = background_images[itemKey];

        if (group.length > 0) {
          group.forEach((image, index) => {
            const isSelected =
              `${props.themeDirectory}/dist/images/backgrounds/${image}` ===
                backgroundImage
                ? true
                : false;
            const bgGroup = itemKey;
            let buttonClass = isSelected ? "selected" : "";

            data.push(
              <div
                key={`image-col-${itemKey}-${index}`}
                className={`image-item ${buttonClass} ${bgGroup} ${backgroundFilter !== "all" && backgroundFilter !== bgGroup
                  ? "d-none"
                  : ""
                  } col-xxl-2 col-xl-3 col-lg-4 col-sm-6`}
              >
                <div className="btn-container">
                  {props.showTooltip ? (
                    <Tooltip
                      id={`tooltip-${itemKey}-${index}`}
                      title={image}
                      placement="top"
                    >
                      <Button
                        onClick={() => {
                          onImageSelected(
                            `${props.themeDirectory}/dist/images/backgrounds/${image}`
                          );
                        }}
                      >
                        <img
                          alt={`bg-${image}`}
                          key={`${index}-image`}
                          src={`${props.themeDirectory}/dist/images/bg-thumb/${image}`}
                        />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      onClick={() => {
                        onImageSelected(
                          `${props.themeDirectory}/dist/images/backgrounds/${image}`
                        );
                      }}
                    >
                      <img
                        alt={`bg-${image}`}
                        key={`${index}-image`}
                        src={`${props.themeDirectory}/dist/images/backgrounds/${image}`}
                      />
                    </Button>
                  )}
                </div>
              </div>
            );
          });
        }
      }
    }

    return data;
  };

  const renderUyo = () => {
    if (typeof backgroundImage == "object") {
      return (
        <div
          className={`img-selected-container ${props.transparentSettings ? "transparent-opacity" : ""
            }`}
        >
          <Row>
            <Col xs={6} md={6} lg={5}>
              <Row>
                <Col xs={12}>
                  <Container className="ratio ratio-16x9">
                    <img alt={`bg`} src={backgroundImage.field_media_image} />
                  </Container>
                </Col>
              </Row>
            </Col>
            <Col>
              {backgroundImage.mid && (
                <Row className="my-3">
                  <Col className="property">
                    <Typography variant="form-group-label" owner="admin">
                      MID:
                    </Typography>
                  </Col>
                  <Col className="value">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="light"
                      color="adminText.dark"
                    >
                      {backgroundImage.mid}
                    </Typography>
                  </Col>
                </Row>
              )}

              {backgroundImage.name && (
                <Row className="my-3">
                  <Col className="property">
                    <Typography variant="form-group-label" owner="admin">
                      File name:
                    </Typography>
                  </Col>
                  <Col className="value">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="light"
                      color="adminText.dark"
                    >
                      {backgroundImage.name}
                    </Typography>
                  </Col>
                </Row>
              )}

              {backgroundImage.name && (
                <Row className="my-3">
                  <Col className="property">
                    <Typography variant="form-group-label" owner="admin">
                      Original
                    </Typography>
                  </Col>
                  <Col className="value">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="light"
                      color="adminText.dark"
                    >
                      {backgroundImage.name}
                    </Typography>
                  </Col>
                </Row>
              )}

              {backgroundImage.properties && (
                <Row className="my-3">
                  <Col className="property">
                    <Typography variant="form-group-label" owner="admin">
                      Dimensions
                    </Typography>
                  </Col>
                  <Col className="value">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="light"
                      color="adminText.dark"
                    >
                      {backgroundImage.properties[0]}x
                      {backgroundImage.properties[1]}px
                    </Typography>
                  </Col>
                </Row>
              )}

              {backgroundImage.size && (
                <Row className="my-3">
                  <Col className="property">
                    <Typography variant="form-group-label" owner="admin">
                      File size
                    </Typography>
                  </Col>
                  <Col className="value">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="light"
                      color="adminText.dark"
                    >
                      450kb
                    </Typography>
                  </Col>
                </Row>
              )}
            </Col>

            <Col xs={2} className="pt-1 btn-grp">
              <Row className="py-2">
                <Col xs={12} className="pe-3">
                  <Button
                    className="mx-0 w-100"
                    owner="admin"
                    variant="contained"
                    dialogbtn="true"
                    dialogvariant="primary"
                    onClick={() => {
                      setShowMediaLibrary(true);
                    }}
                    endIcon={<Crop />}
                  >
                    <Typography variant="button" owner="admin">
                      Edit cropping
                    </Typography>
                  </Button>
                </Col>
              </Row>
              <Row className="py-2">
                <Col xs={12} className="pe-3">
                  <Button
                    className="mx-0 w-100"
                    owner="admin"
                    variant="outlined"
                    dialogbtn="true"
                    dialogvariant="cancel"
                    onClick={() => {
                      setChangeImageLibrary(true);
                    }}
                    endIcon={
                      <SvgIcon>
                        <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                          <g fill="currentColor">
                            <path d="m1150.8 573.6h-124.8c-13.199-224.4-199.2-400.8-426-400.8-73.199 0-145.2 19.199-207.6 54l-22.801 13.199c-9.6016 6-13.199 18-7.1992 27.602l36 55.199c6 8.3984 16.801 12 26.398 6l19.199-10.801c48-26.398 102-40.801 156-40.801 169.2 0 307.2 129.6 320.4 295.2h-121.2c-15.602 0-25.199 16.801-16.801 30l175.2 270c7.1992 12 25.199 12 32.398 0l175.2-270c9.6055-12 1.2031-28.797-14.395-28.797z" />
                            <path d="m801.6 877.2c-6-8.3984-16.801-12-26.398-6l-19.203 10.801c-49.199 26.398-100.8 40.801-156 40.801-169.2 0-307.2-130.8-321.6-295.2h121.2c15.602 0 25.199-16.801 16.801-30l-175.2-271.2c-7.1992-12-25.199-12-32.398 0l-175.2 270c-8.3984 13.199 1.1992 30 16.801 30h123.6c13.195 223.2 199.2 400.8 426 400.8 72 0 144-19.199 207.6-54l22.801-13.199c9.6016-6 13.199-18 7.1992-27.602z" />
                          </g>
                        </svg>
                      </SvgIcon>
                    }
                  >
                    <Typography variant="button" owner="admin">
                      Change image
                    </Typography>
                  </Button>
                </Col>
              </Row>
              <Row className="py-2">
                <Col xs={12} className="pe-3">
                  <Button
                    className="mx-0 w-100"
                    owner="admin"
                    variant="contained"
                    dialogbtn="true"
                    dialogvariant="error"
                    onClick={() => {
                      setBackgroundImage("");
                      setUyoImage(false);
                      onImageSelected("");
                    }}
                    endIcon={
                      <SvgIcon sx={{ fontSize: "0.8rem !important" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 881.19 1069.98" fill="currentColor">
                          <path d="M248,1070a166.75,166.75,0,0,1-118.45-49.28l-.07-.07c-31.82-32-49.35-74-49.35-118.52V410.69H46a46.06,46.06,0,0,1-46-46L.53,256.88A122.12,122.12,0,0,1,36.59,170.2l.19-.18a122.17,122.17,0,0,1,86.65-36H246.87V96a96.14,96.14,0,0,1,96-96h196a96.14,96.14,0,0,1,96,96v38H758.29A122.08,122.08,0,0,1,845,170.08l.46.45a122.56,122.56,0,0,1,35.75,86.39l-.53,107.81a46.07,46.07,0,0,1-46,46H800.52v491.4A168.23,168.23,0,0,1,632.65,1070ZM172.11,902.13a75.12,75.12,0,0,0,22.17,53.52l.44.44A75.15,75.15,0,0,0,248,978H632.63a76.26,76.26,0,0,0,75.86-75.86V410.69H172.11ZM788.66,318.68l.52-62a30.43,30.43,0,0,0-9-21.61l-.35-.34a30.53,30.53,0,0,0-21.59-8.79H123.43a30.57,30.57,0,0,0-21.78,9l-.17.17a30.6,30.6,0,0,0-9,21.69L92,318.67ZM562.84,134V96a24.15,24.15,0,0,0-24-24h-196a24.15,24.15,0,0,0-24,24v38Z" transform="translate(0 -0.02)" />
                          <path d="M575.05,844a31.1,31.1,0,0,1-31-31V575.68a31,31,0,1,1,62,0V813A31,31,0,0,1,575.05,844Z" transform="translate(0 -0.02)" />
                          <path d="M295.57,844a31,31,0,0,1-31-31V575.68a31,31,0,0,1,62,0V813A31.09,31.09,0,0,1,295.57,844Z" transform="translate(0 -0.02)" />
                          <path d="M435.3,844a31,31,0,0,1-31-31V575.68a31,31,0,0,1,62,0V813A31.09,31.09,0,0,1,435.3,844Z" transform="translate(0 -0.02)" />
                        </svg>
                      </SvgIcon>
                    }
                  >
                    <Typography variant="button" owner="admin">
                      Remove image
                    </Typography>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  };

  const renderBgSettings = () => {
    return (
      <Row className="image-settings-container mt-4 mb-3">
        <Col
          xs={3}
          className={`d-flex align-items-center ${transparentFocus === "image-opacity"
            ? "transparent-focus"
            : props.transparentSettings
              ? "transparent-opacity"
              : ""
            }`}
        >
          <Row className="opacity-container justify-content-xl-start w-100">
            <Col
              xs={"12"}
              lg={"auto"}
              className="title d-flex align-items-center pe-0 "
            >
              <Typography variant="form-group-label" owner="admin">
                {props.opacityTitle}
              </Typography>
            </Col>
            <Col xs={12} xxl={6} className="flex-grow-1">
              <BackgroundImageOpacity
                slideBackgroundOpacity={props.slideBackgroundOpacity}
                onBackgroundImageOpacity={props.onBackgroundImageOpacity}
                sliderMouseDown={() => {
                  props.sliderMouseDown();
                  setTransparentFocus("image-opacity");
                }}
                sliderChangeCommit={() => {
                  props.sliderChangeCommit();
                  setTransparentFocus("");
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          className={`d-flex align-items-center ${props.transparentSettings ? "transparent-opacity" : ""
            }`}
        >
          <Row className="size-container justify-content-xl-start justify-content-xxl-end w-100">
            <Col
              xs={"12"}
              lg={"auto"}
              className="title d-flex align-items-center pe-0 "
            >
              <Typography variant="form-group-label" owner="admin">
                {props.sizeTitle}
              </Typography>
            </Col>
            <Col xs={12} xxl={6} className="flex-grow-1">
              <BackgroundImageSize
                slideBackgroundSize={props.slideBackgroundSize}
                onBackgroundImageSize={props.onBackgroundImageSize}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          className={`d-flex align-items-center ${props.transparentSettings ? "transparent-opacity" : ""
            }`}
        >
          <Row className="repeat-container justify-content-xl-start justify-content-xxl-end w-100">
            <Col
              xs={"12"}
              lg={"auto"}
              className="title d-flex align-items-center pe-0 "
            >
              <Typography variant="form-group-label" owner="admin">
                {props.repeatTitle}
              </Typography>
            </Col>
            <Col xs={12} xxl={6} className="flex-grow-1">
              <BackgroundImageRepeat
                slideBackgroundRepeat={props.slideBackgroundRepeat}
                onBackgroundImageRepeat={props.onBackgroundImageRepeat}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          className={`d-flex align-items-center ${props.transparentSettings ? "transparent-opacity" : ""
            }`}
        >
          <Row className="position-container justify-content-xl-start justify-content-xxl-end">
            <Col
              xs={"12"}
              lg={"auto"}
              className="title d-flex align-items-center pe-0 "
            >
              <Typography variant="form-group-label" owner="admin">
                {props.positionTitle}
              </Typography>
            </Col>
            <Col xs={12} xxl={6} className="flex-grow-1">
              <BackgroundImagePosition
                slideBackgroundPosition={props.slideBackgroundPosition}
                onBackgroundImagePosition={props.onBackgroundImagePosition}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div className="settings-wrap background-images">
      <Row
        className={`mb-3 ${props.transparentSettings ? "transparent-opacity" : ""
          }`}
      >
        <Col xs="auto" className="d-flex align-items-center">
          <Typography
            variant="h5"
            owner="admin"
            color="adminText.dark"
            className="m-0"
          >
            {props.title}
          </Typography>
          {/* <h5 className='m-0 ss-subtitle'>{props.title}</h5> */}
        </Col>
        <Col xs="auto" className="uyo-switch d-flex align-items-center">
          <Row>
            <Col xs="auto" className="p-0">
              <Button
                className={`preset-btn ${uyo ? "" : "active"}`}
                onClick={() => setUyo(false)}
              >
                Preset Image
              </Button>
            </Col>
            <Col xs="auto" className="p-0">
              <Button
                className={`new-btn ${uyo ? "active" : ""}`}
                onClick={() => setUyo(true)}
              >
                New Image
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bi-tpl">
        <Col>
          <div
            className={`main-wrapper ${uyo ? "upload-own" : ""}`}
            style={
              props.transparentSettings
                ? {
                  borderColor: "rgba(0,0,0,0)",
                  transition: "border-color .5s ease",
                }
                : {}
            }
          >
            <Col>
              {uyo ? (
                <>
                  {isUyoImage ? (
                    <Row>
                      <Col>
                        <div>{renderUyo()}</div>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="bi-uyo">
                      <Col className="d-flex justify-content-center flex-column">
                        <Row className="hint">
                          <Col className="d-flex justify-content-center text-center">
                            <Typography
                              variant="body1"
                              owner="admin"
                              color="adminText.main"
                              lh="wide"
                            >
                              To create your own background, please select
                              <br />
                              or upload an image from the media library
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-center text-center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setShowMediaLibrary(true);
                              }}
                            >
                              <Typography
                                variant="button"
                                owner="admin"
                                className="lh"
                              >
                                Media library
                              </Typography>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <div className="background-ct">{renderFilters()}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className={`images-container  ${props.transparentSettings ? "transparent-opacity" : ""
                          }`}
                      // 'inner-wrapper'
                      >
                        {renderImages()}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {typeof backgroundImage == "string" &&
                backgroundImage !== "" &&
                !uyo &&
                renderBgSettings()}
              {typeof backgroundImage == "object" && uyo && renderBgSettings()}
            </Col>
          </div>
        </Col>
      </Row>

      {showMediaLibrary && (
        <MediaLibrary
          user={props.user}
          presentation={props.presentation}
          slide={props.slide}
          mediaType={mediaType}
          setMediaType={(type) => {
            setMediaType(type);
          }}
          setShowMediaLibrary={setShowMediaLibrary}
          showMediaLibrary={showMediaLibrary}
          onMediaInsert={(media) => {
            if (media.bundle === "image") {
              onUyoImageSelected(media);
            }
          }}
          files={dimensions}
          slideLayout={props.slideLayout}
          selectedMid={backgroundImage?.mid}
          selectedListingId={backgroundImage?.listingId}
          selectedListingName={backgroundImage?.listingName}
          selectedAssetId={backgroundImage?.assetId}
          selectedFileId={backgroundImage?.fileId}
          selectedIceMedia={backgroundImage?.iceMedia}
          currentBlock={{
            data: {
              x: backgroundImage?.x,
              y: backgroundImage?.y,
              width: backgroundImage?.width,
              height: backgroundImage?.height,
            },
          }}
        />
      )}
      {changeImageLibrary && (
        <MediaLibrary
          user={props.user}
          presentation={props.presentation}
          slide={props.slide}
          mediaType={mediaType}
          setMediaType={(type) => {
            setMediaType(type);
          }}
          setShowMediaLibrary={setChangeImageLibrary}
          showMediaLibrary={changeImageLibrary}
          onMediaInsert={(media) => {
            if (media.bundle === "image") {
              onUyoImageSelected(media);
            }
          }}
          files={dimensions}
          slideLayout={props.slideLayout}
        />
      )}
    </div>
  );
}
