import { LeftIcon, RightIcon, DeleteIcon, AddIcon, GalleryIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Gallery {

  static get toolbox() {
    return {
      // icon: IconPicture,
      icon: GalleryIcon,
      title: 'Gallery',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._settings = config;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-gallery-container',
      wrapper: 'cdx-gallery',
      imageContainer: 'cdx-gallery-image-container',
      itemContainer: 'cdx-gallery-item-container',
      caption: 'cdx-gallery-item-caption',
      addBtn: 'cdx-gallery-add-btn',
      btnGroup: 'cdx-gallery-btn-group',
      moveLeftBtn: 'cdx-gallery-left-btn',
      moveRightBtn: 'cdx-gallery-right-btn',
      removeBtn: 'cdx-gallery-remove-btn',
      blockSettingsWrapper: 'cdx-nothing-settings',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xl-icon',
      smIcon: 'sm-icon',
      xsIcon: 'xs-icon',
      clear: 'clear',
    };

    this._icons = {
      moveLeft: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>',
      moveRight: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>',
      remove: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
      add: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>',
    };

    this._data = data;
    this._element = this.getElement();
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  updateItemPosition(array, fromIndex, toIndex) {
    let tmp = array[fromIndex];
    array[fromIndex] = array[toIndex];
    array[toIndex] = tmp;

    this._data.images = array;
  }

  updateNodePosition(parent, node, direction) {
    const element = node.parentNode.parentNode;

    if (direction === -1 && element.previousElementSibling) {
      parent.insertBefore(element, element.previousElementSibling);
    } else if (direction === 1 && element.nextElementSibling) {
      parent.insertBefore(element, element.nextElementSibling.nextElementSibling)
    }
  }

  getNodeIndex(parent, node, step) {
    const nodes = Array.prototype.slice.call(parent.children);
    let nodeIndex = 0
    if (step && step === 1) {
      nodeIndex = nodes.indexOf(node.parentNode);
    }
    else {
      nodeIndex = nodes.indexOf(node.parentNode.parentNode);
    }

    return nodeIndex;
  }

  getElement() {
    let gal;
    gal = document.createElement('div');
    gal.classList.add(this._CSS.wrapper);

    const that = this;

    /**
     * Create image container and controls per image in array
     */
    if (this._data?.images?.length > 0) {
      this._data?.images?.forEach((image) => {
        let itemContainer = document.createElement('div');
        let caption = document.createElement('span');
        let img = document.createElement('img');

        // Image
        img.classList.add(this._CSS.imageContainer);

        if (image.url) {
          img.setAttribute('src', image.url);
          img.setAttribute('data-mid', image.mid);
          img.setAttribute('data-x', image.x);
          img.setAttribute('data-y', image.y);
          img.setAttribute('data-width', image.width);
          img.setAttribute('data-height', image.height);
          img.setAttribute('data-maxwidth', image.maxWidth);
          img.setAttribute('data-maxheight', image.maxHeight);
        }

        // Caption
        caption.contentEditable = this.readOnly ? 'false' : 'true';
        caption.dataset.placeholder = this.api.i18n.t(this._settings.placeholder || `Add a caption`);
        caption.innerHTML = `${image.caption ? image.caption : ''}`;
        caption.classList.add(this._CSS.caption);

        caption.addEventListener('keyup', function (e) {
          that._data.images[that.getNodeIndex(gal, this, 1)].caption = e.target.innerHTML;
        });

        // Container
        itemContainer.classList.add(this._CSS.itemContainer);

        // Main element wrapper
        itemContainer.append(img);
        itemContainer.append(caption);

        // Buttons
        if (!this.readOnly) {
          let btnGroup = document.createElement('div');
          let moveLeft = document.createElement('button');
          let removeBtn = document.createElement('button');
          let moveRight = document.createElement('button');
          BtnTooltip(moveLeft, EdjsTooltips.gallery.moveLeft);
          BtnTooltip(removeBtn, EdjsTooltips.gallery.remove);
          BtnTooltip(moveRight, EdjsTooltips.gallery.moveRight);

          moveLeft.innerHTML = `${LeftIcon}`;
          removeBtn.innerHTML = `${DeleteIcon}`;
          moveRight.innerHTML = `${RightIcon}`;

          removeBtn.addEventListener('click', function () {
            const index = that.getNodeIndex(gal, this);

            if (index !== -1) {
              that._data.images.splice(index, 1);
              this.parentNode.parentNode.remove();
            }
          });

          moveLeft.addEventListener('click', function () {
            const index = that.getNodeIndex(gal, this);

            if (index !== -1) {
              that.updateItemPosition(that._data.images, index, index - 1);
              that.updateNodePosition(gal, this, -1);
            }
          });

          moveRight.addEventListener('click', function () {
            const index = that.getNodeIndex(gal, this);

            if (index !== -1) {
              that.updateItemPosition(that._data.images, index, index + 1);
              that.updateNodePosition(gal, this, 1);
            }
          });

          moveLeft.classList.add(this._CSS.moveLeftBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall);
          removeBtn.classList.add(this._CSS.removeBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, this._CSS.xlIcon);
          moveRight.classList.add(this._CSS.moveRightBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall);


          /** hide / show left/right buttons via CSS on first and last item */
          /** index here can change without re-render */
          btnGroup.append(moveLeft);
          btnGroup.append(removeBtn);
          btnGroup.append(moveRight);

          btnGroup.classList.add(this._CSS.btnGroup);

          itemContainer.append(btnGroup);
        }

        gal.append(itemContainer);
      });
    }

    return gal;
  }

  render() {
    let container = document.createElement('div');
    let img = this._element;

    if (this._data?.images?.length > 0) {
      container.appendChild(img);
    }

    if (!this.readOnly) {
      let btn = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.gallery.add);
      btn.innerHTML = `${AddIcon} Add Image`;
      btn.classList.add(this._CSS.addBtn, this._CSS.blockBtn, this._CSS.blockBtnLarge);
      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      container.appendChild(btn);
    }

    container.classList.add(this._CSS.container);
    return container;
  }

  save() {
    return this._data;
  }
}