import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// App
import { getOnlineNode } from '../../core/getNode';
import { paginator } from '../../core/paginator';
// import Pagination from '../../core/pagination';
import Pagination from '../../partials/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
// import { _checkContent } from '../../core/checkContent';
import NoData from '../../partials/noData';
import { decodeHtmlEntities } from '../../utils/helpers';
import Forum from './forum';

// UI components
import Row from 'react-bootstrap/Row';
import { FormControl, InputLabel, Select, MenuItem, Input } from '@mui/material';
import CompanyappLoader from '../../partials/companyappLoader';


export default function ForumsBoard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0)
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [forums, setForums] = useState([]);
  const [discussion, setDiscussion] = useState(null);

  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    getForums()
  }, [])

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  // const _setData = async (_data) => {
  //   setIsLoading(false);
  //   setIsRefreshing(false);

  //   setData(_data.rows);
  //   setTotalItems(_data.pager.totalItems);


  //   // !!! CHECK DEPENDENCY
  //   // const serverCount = await getContentCount('news')

  //   if (serverCount && serverCount[0]) {
  //     const count = serverCount[0] ? serverCount[0].serverCount : 0;
  //     updateContentCounterPreviousCount('news', count)
  //   }

  //   // _checkContent();
  // };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  // const favorite = async (_nid) => {
  //   let news = await db.news.get(_nid);
  //   news.favorite = 'true';
  //   db.news.put(news, _nid);

  //   if (activeIndex === 0) {
  //     getContent();
  //   }

  //   if (activeIndex === 1) {
  //     getFavourites();
  //   }
  // }

  // const unfavorite = async (_nid) => {
  //   let news = await db.news.get(_nid);
  //   news.favorite = 'false';
  //   db.news.put(news, _nid);

  //   if (activeIndex === 0) {
  //     getContent();
  //   }

  //   if (activeIndex === 1) {
  //     getFavourites();
  //   }
  // }

  // const dataHandler = async (_data) => {
  //   let newsArray = [];

  //   for (const data of _data.rows) {
  //     let existingNews = await db.news.get(data.nid);
  //     let favorite = 'false';

  //     if (existingNews) {
  //       favorite = existingNews.favorite;
  //     }

  //     const news = {
  //       nid: data.nid,
  //       title: data.title,
  //       body: data.body,
  //       created: data.created,
  //       categoryID: data.field_category,
  //       categoryLabel: data.field_category_1,
  //       sticky: data.sticky,
  //       favorite: favorite,
  //       feature: data.field_feature,
  //       field_featured_image: data.field_featured_image.length > 0 ? data.field_featured_image : 'null',
  //     };

  //     db.news.put(news, data.nid);

  //     newsArray.push(news);
  //   }

  //   let data = _data;

  //   data.rows = newsArray;

  //   _setData(data);
  // }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */

  const getForums = () => {
    getOnlineNode(
      // 'all_categories/news_categories',
      'api/categories?vid=forums',
      user.access_token,
    )
      .then((response) => {
        setForums(response.data);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Boards',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
    // if (activeIndex === 0) {
    //   getContent();
    // }
    // if (activeIndex === 1) {
    //   getFavourites()
    // }
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1)
          }}
          onNext={() => {
            onPaginate(currentPage + 1)
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id)
    return categoryName ? categoryName.label : ''
  }

  // Data component
  const dataComponent = () => {
    if (typeof forums !== 'undefined' && forums.length > 0) {
      return (
        <Row>
          {forums.map((item, index) => {
            return (
              <Forum
                item={item}
                setDiscussion={(id) => setDiscussion(id)}
              />
            );
          })}
        </Row>
      );
    }

    else {
      return (
        <NoData activeIndex={activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {
        isError ?

          <Error
            status={errorStatus}
            message={errorMessage}
          />
          :
          <main className="forums  screen">
            <PageHeader
              pageName="Discussion Boards"
              // filters={true}
              owner='client'
              pageNameColor='clientText.dark'
              // categorySelector={
              //   <FormControl variant="standard" fullWidth size='small' >
              //     <InputLabel sx={{ fontSize: 14 }}>Boards</InputLabel>
              //     <Select
              //       labelId='forums-category-select-label'
              //       id='forums-category-select'
              //       value={selectedCategoryItem}
              //       label='Boards'
              //       open={categoryOpen}
              //       onClose={() => setCategoryOpen(false)}
              //       onOpen={() => setCategoryOpen(true)}
              //       input={
              //         <Input
              //           sx={{
              //             fontSize: 14,
              //             '&.MuiInput-underline:before': {
              //               borderBottomColor: '#d2d0cf',
              //             },
              //           }}
              //           label='Boards'
              //         />
              //       }
              //     >
              //       {categories.map((category, index) => {
              //         return (
              //           <MenuItem
              //             key={`forums-category-${category.value}`}
              //             value={category.value}
              //             onClick={() => {
              //               handleCategorySelection(category.value)
              //             }}
              //           >
              //             {decodeHtmlEntities(category.label)}
              //           </MenuItem>
              //         )
              //       })}
              //     </Select>
              //   </FormControl>
              // }
              getContent={() => {
                setActiveIndex(0);
                setIsLoading(true);
              }}
              // getFavourites={() => {
              //   setActiveIndex(1);
              //   setIsLoading(true);
              //   getFavourites()
              // }}
              activeTabIndex={activeIndex}
            />
            {isLoading ?
              <CompanyappLoader />
              :
              <>
                {dataComponent()}

                {renderPagination()}
              </>
            }
          </main>
      }
    </>
  )
}
