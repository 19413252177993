import { DeleteIcon, ImageIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

export default class CaptionedImage {

  static get toolbox() {
    return {
      icon: ImageIcon,
      title: 'Captioned Image',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get DEFAULT_CAPTION_PLACEHOLDER() {
    return 'Caption'
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
      strong: true,
      a: true,
      i: true,
      sub: true,
      sup: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      /**
       * Block-specific classes
       */
      caption: 'cdx-captioned-image-caption',
      /**
       * Standard editor styles
       */
      customInput: 'editor-custom-input',
      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      btn: 'cdx-media-btn',
      addButton: 'add-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      listingId: data.listingId || '',
      listingName: data.listingName || '',
      assetId: data.assetId || '',
      fileId: data.fileId || '',
      iceMedia: data?.iceMedia || '',
      caption: data?.caption || '',
      tunes: data?.tunes || ''
    };

    this._headingPlaceholder = config.captionPlaceHolder ? config.captionPlaceHolder : CaptionedImage.DEFAULT_CAPTION_PLACEHOLDER;
    this._element = this.getElement();
    this._caption = this.getCaptionElement();
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }

  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  getElement() {
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxHeight);
      // iceportal
      img.setAttribute('data-listingId', this._data.listingId);
      img.setAttribute('data-listingName', this._data.listingName);
      img.setAttribute('data-assetId', this._data.assetId);
      img.setAttribute('data-fileId', this._data.fileId);
      img.setAttribute('data-iceMedia', this._data.iceMedia);

    }

    return img;
  }

  getCaptionElement() {
    let tag = document.createElement('p');

    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.caption || '';
    tag.classList.add(this._CSS.caption, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._caption);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag;
  }

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let img = this._element;

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.classList.add(this._CSS.replaceButton);
        btn.innerHTML = `${CaptionedImage.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall);
        btnRemove.classList.add(this._CSS.removeButton, this._CSS.clear, this._CSS.xlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btn.classList.add(this._CSS.addButton);
        btn.innerHTML = `${CaptionedImage.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        if (this._settings.dynamicImageSize) {
          let target = btn.closest('.ce-block');
          let aspectRatio = target.offsetWidth / target.offsetHeight
          this._settings.showMediaLibrary({
            details: block,
            data: this._data,
            bundle: 'image',
            aspectRatio: aspectRatio,
          })
        }
        else {
          this._settings.showMediaLibrary({
            details: block,
            data: this._data,
            bundle: 'image',
          });
        }
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height = '';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';
        // iceportal
        this._data.listingId = '';
        this._data.listingName = '';
        this._data.assetId = '';
        this._data.fileId = '';
        this._data.iceMedia = '';

        this._element.remove();
        container.classList.remove('has-img')
        btnRemove.remove();
      }



      if (typeof img !== 'undefined') {
        container.appendChild(img);
        container.appendChild(btn);
        container.appendChild(btnRemove);
        container.classList.add('has-img');
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        container.appendChild(input);
        container.appendChild(btn);
      }
    } else {
      if (typeof img !== 'undefined') {
        container.appendChild(img);
        container.classList.add('has-img');
      }
    }
    container.appendChild(this._caption);
    return container;
  }

  save() {
    this._data.caption = this._caption.innerHTML;
    return this._data;
  }
}