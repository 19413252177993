// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";

// UI components
import SinglesHeader from "../../partials/singlesHeader";

export default function DocumentSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [post, setPost] = useState(null);

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    console.log("?");
    loadNode();
  }, []);

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
    } else {
      setErrorStatus(404);
      setErrorMessage("Not found");
      setIsError(true);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.documents}/all/${nid}?_format=json`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          if (response.data.rows[0].field_document) {
            window.location.href = `${serverUrl}/${response.data.rows[0].field_document}`;
          } else {
            setErrorStatus(404);
            setErrorMessage("No data");
            setIsError(true);
          }
        } else {
          setErrorStatus(404);
          setErrorMessage("No data");
          setIsError(true);
        }
      })
      .catch((_error) => {
        setErrorStatus(500);
        setErrorMessage("Failed to get data");
        setIsError(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      return null;
      // let category = "Uncategorised";

      // if (post.category_labels) {
      //   category = post.category_labels;
      // }

      // return (
      //   <main className="documents single">
      //     <article className="article">
      //       <SinglesHeader
      //         route={"/documents"}
      //         backBtnText={"Documents"}
      //         category={category}
      //         timestamp={post.created}
      //         title={post.title}
      //       />
      //       <div className="article__body">
      //         <div class="ratio ratio-4x3">
      //           <embed
      //             src={`${serverUrl}/${post.field_document}`}
      //             className="w-100 h-100"
      //             type="application/pdf"
      //             style={{ minHeight: 500 }}
      //           />
      //         </div>
      //       </div>
      //     </article>
      //   </main>
      // );
    }
  }
}
