import React, { Component } from 'react';

// App

// UI components
import { User } from 'react-feather';
import { Avatar } from '@mui/material';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

export default function UserImage(props) {

  const stringAvatar = (name) => {
    let initials = null
    let names = name.trim().split(' ');
    if (names.length > 1) {
      initials = `${names[0][0]}${names[1][0]}`
    }
    else if (names.length === 1) {
      initials = `${names[0][0]}`
    }
    else {
      initials = null
    }
    return {
      sx: {
        bgcolor: stringToColor(props.userBrand),
        border: `1px solid ${stringToColor(props.userBrand)}`,
      },
      // children: name ? `${name.split(' ')[0][0] ? name.split(' ')[0][0] : ""}${name.split(' ')[1][0] ? name.split(' ')[1][0] : ""}` : <PersonOutlinedIcon />,
      children: (initials !== "" && typeof initials !== "undefined" && initials !== "undefined") ? initials : <PersonOutlinedIcon sx={{fontSize:'1rem'}} />
    };
  }

  const stringToColor = (brand) => {
    switch (brand) {
      case 'rhg':
        return '#53565A'
      case 'blu':
        return '#18448a'
      case 'park-inn':
        return '#f5b335'
      case 'red':
        return '#E62528'
      case 'core':
        return '#559EAA'
      case 'collection':
        return '#101820'
      case 'park-plaza':
        return '#252728'
      case 'individuals':
        return '#f7b03d'
      default:
        return '#53565A'
    }
  }

  let style = props.className ? `${props.className} user-image` : 'user-image';

  return (
    <>
      <Avatar className={style} {...stringAvatar(props.name)} src={props.image} />
    </>
  );
}
