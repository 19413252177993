import { decode } from "html-entities";
import store from "../redux/store";
import { client } from '../config';

export const jsonParser = (_json) => {
  let json = {};

  try {
    json = JSON.parse(_json);

    if (json === null) {
      json = {};
    }
  } catch (error) {
    json = {};
  }

  return json;
};

export const decodeHtmlEntities = (label) => {
  let item = label;

  try {
    item = decode(item);
  } catch (error) {
    // console.log('Cannot decode categories');
    return label;
  }

  return item;
};

export const getFrameWindow = (iframe) => {
  let doc;

  if (iframe.contentWindow) {
    return iframe.contentWindow;
  }

  if (iframe.window) {
    return iframe.window;
  }

  if (!doc && iframe.contentDocument) {
    doc = iframe.contentDocument;
  }

  if (!doc && iframe.document) {
    doc = iframe.document;
  }

  if (doc && doc.defaultView) {
    return doc.defaultView;
  }

  if (doc && doc.parentWindow) {
    return doc.parentWindow;
  }

  return undefined;
};

export const runAnimation = (iframe) => {
  var interval;
  var iteration = 0;

  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
          ? iframe.contentWindow.document
          : iframe.document;

      interval = setInterval(function () {
        if (iframe_document.readyState === 'complete') {
          if (typeof iframe_window.runAnimation !== 'undefined') {
            clearInterval(interval);
            // fail safe for widescreen class
            iframe_window.document.body?.classList?.add('widescreen');
            iframe_window.runAnimation();
          } else {
            if (iteration === 10) {
              clearInterval(interval);
            }
            iteration++;
          }
        }
      }, 1000);
    }
  }
};

export const addWidescreen = (iframe) => {
  var interval;
  var iteration = 0;

  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
          ? iframe.contentWindow.document
          : iframe.document;

      interval = setInterval(function () {
        if (iframe_document.readyState === 'complete') {
          if (typeof iframe_window.document?.body !== 'undefined') {
            clearInterval(interval);
            iframe_window.document.body?.classList?.add('widescreen');
          } else {
            if (iteration === 10) {
              clearInterval(interval);
            }

            iteration++;
          }
        }
      }, 500);
    }
  }
};

export const iframeClickEvents = (iframe) => {
  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
          ? iframe.contentWindow.document
          : iframe.document;

      var interval;
      interval = setInterval(function () {
        if (
          iframe_document.readyState === 'complete' ||
          iframe_document.readyState === 'interactive'
        ) {
          clearInterval(interval);
          iframe_window.document.addEventListener('click', clickEvents, false);
        }
      }, 500);
    }
  }
};

export const clickEvents = (event) => {
  const link = event?.target?.closest('a');

  if (link && link.href) {
    const href = link.href.toLowerCase();

    if (href.indexOf('gotoslide') > -1 || href.indexOf('reloadslide') > -1 || href.indexOf('opendocument') > -1 || href.indexOf('openexternal') > -1 || href.indexOf('openViewerMenu') > -1) {
      event.stopPropagation();
      event.preventDefault();

      if (href.indexOf('openexternal') > -1) {
        let linkToOpen = href;
        linkToOpen = linkToOpen.split('link=');
        linkToOpen = linkToOpen[1];
        window.open(linkToOpen);
      }
    } else {
      const url = new URL(link.href);

      if (url.origin !== window.location.origin) {
        event.stopPropagation();
        event.preventDefault();
        window.open(url);
      }
    }

    return false;
  }
};

export const getThemeJson = (theme_label) => {
  const state = store.getState();
  const themes = state?.themesReducer?.themes
    ? state?.themesReducer?.themes
    : {};
  let json = {};

  if (theme_label) {
    json = themes[theme_label];

    if (json) {
      try {
        json = JSON.parse(json);
      } catch (error) {
        // some error with parsing
        console.error("App", "Theme parse error!");
      }
    }
  }

  return json;
};

export const getThemeIds = (slide_layout) => {
  const state = store.getState();
  const themes = state?.themesReducer?.themes
    ? state?.themesReducer?.themes
    : {};
  let allowedThemes = [];
  if (themes) {
    const themeLabels = Object.keys(themes);
    let themeItems = [];
    themeLabels.forEach((e) => {
      let themeItem = {
        label: e,
        data: JSON.parse(themes[e])
      };
      themeItems.push(themeItem);
    });
    if (themeItems.length > 0) {
      themeItems.forEach((e) => {
        let result = e.data.layouts.find((e) => e.id === slide_layout);
        if (result) {
          allowedThemes.push(e.label);
        }
      });
    }
  }
  return allowedThemes
}

export const getLayoutIcons = (layout_id, theme_label) => {
  let icons;
  const theme_json = getThemeJson(theme_label);

  theme_json?.layouts.forEach((layout) => {
    if (layout.id === layout_id) {
      icons = layout.icons;
    }
  });

  return icons;
};

export const getLayoutFiles = (layout_id, theme_label) => {
  let files;
  const theme_json = getThemeJson(theme_label);

  theme_json?.layouts.forEach((layout) => {
    if (layout.id === layout_id) {
      files = layout.files;
    }
  });

  return files;
};

export const getThemeBackgrounds = (theme_label) => {
  let colors = [];
  const theme_json = getThemeJson(theme_label);

  if (theme_json?.backgroundColors) {
    colors = theme_json.backgroundColors;
  }

  return colors;
};

export const getParamFromURL = (url, parameter) => {
  const urlParams = new URLSearchParams(url);
  const myParam = urlParams.get(parameter);

  return myParam;
};

export const processObject = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively process nested objects
      obj[key] = processObject(obj[key]);
    } else if (typeof obj[key] === "string") {
      // Perform operations on string values
      obj[key] = decode_utf8(obj[key]);
    }
  }
  return obj;
};

/**
 * Deprecated use of escape
 */
export const decode_utf8 = (string) => {
  try {
    return decodeURIComponent(escape(string));
  } catch (e) {
    return string;
  }
};

export const isValidJson = (json) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Format date
export const formatTime = (time, short) => {
  let timestamp;

  const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  
  const dateCreated = new Date(time * 1000);

  if (client === 'equans') {
    // Convert day to a number to remove leading zero
    const day = parseInt(dateCreated.toLocaleString("en-GB", { day: "2-digit" }), 10);
    const month = dateCreated.toLocaleString("en-GB", { month: short ? "short" : "long" });
    const year = dateCreated.toLocaleString("en-GB", { year: "numeric" });

    timestamp = `${day} ${month} ${year}`;
  } else {
    const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" });
    const month = dateCreated.toLocaleString("en-GB", { month: short ? "short" : "long" });
    const year = dateCreated.toLocaleString("en-GB", { year: "numeric" });

    timestamp = `${day}${nthNumber(parseInt(day, 10))} ${month} ${year}`;
  }

  return timestamp;
};


// Check if item exists in array for favorites
export const checkIfFavorite = (array, id, contentType) => {
  // const foundObject = array.find(obj => obj.id === id && obj.contentType === contentType);
  let foundObject = false;
  if (array.length > 0) {
    foundObject = array.find(obj => obj.id === id);
  }
  return foundObject ? foundObject.favorite : false;
};

export const getFavoritesByContentType = (favorites, contentType, returnCommaSeparatedString) => {
  if (favorites.length > 0) {
    const filteredFavorites = favorites.filter(fav => fav.contentType === contentType && fav.favorite === true);
    if (returnCommaSeparatedString) {
      return filteredFavorites.map(fav => fav.id).join(',');
    } else {
      return filteredFavorites;
    }
  }
  else return returnCommaSeparatedString ? "" : [];
};

export const generateStateToken = () => {
  const array = new Uint8Array(32);
  for (let i = 0; i < 32; i++) {
    array[i] = Math.floor(Math.random() * 256);
  }
  const hexString = Array.from(array)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
  return hexString;
};

export const hexToRgbA = (hex, alpha) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
  throw new Error('Bad Hex');
};

export const buildReactions = (data, user) => {
  let likes = [];
  let love = [];
  let thanks = [];
  let insightful = [];
  let surprised = [];
  let laugh = [];

  let hasReacted = false;
  let reactionType = 'none';
  let reactionId = 'none';

  if (data.rows && data.rows.length > 0) {
    data.rows.forEach(row => {
      if (row.user_id === user.current_user.uid) {
        hasReacted = true;
        reactionType = row.type;
        reactionId = row.id;
      }

      if (row.type === 'reaction_like') {
        likes.push(row);
      }

      if (row.type === 'reaction_laughing') {
        laugh.push(row);
      }

      if (row.type === 'reaction_love') {
        love.push(row);
      }

      if (row.type === 'reaction_surprised') {
        surprised.push(row);
      }

      if (row.type === 'reaction_thanks') {
        thanks.push(row);
      }

      if (row.type === 'reaction_insightful') {
        insightful.push(row);
      }
    });
  }

  return {
    hasReacted: hasReacted,
    reactionType: reactionType,
    reactionId: reactionId,
    likes: likes.length,
    love: love.length,
    surprised: surprised.length,
    thanks: thanks.length,
    insightful: insightful.length,
  }
}