import React from "react";
import { Link as RouterLink } from "react-router-dom";

// Modules
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { serverUrl } from "../config";
import { Typography, Box, Link } from "@mui/material";
import ClientTheme from "../core/clientTheme";
import { hexToRgbA } from "../utils/helpers";

export default function PostBody(props) {
  const clientTheme = { ...ClientTheme() };
  const options = {
    replace: domNode => {
      // links
      switch (domNode.name) {
        case "a":
          const href = domNode.attribs.href;
          const regex = /^\/[\w-]+$/;

          if (href.match(regex)) {
            <RouterLink to={href} >
              <Link
                owner="client"
                variant="h6"
                singles="true"
                weight="regular"
                lh="wide"
                hoverbehavior={clientTheme.components.link.hoverbehavior}
              >
                {domToReact(domNode.children, options)}
              </Link>
            </RouterLink>
          }

          else if (
            href.indexOf('communicator://') > -1 ||
            href.indexOf('caequans://') > -1
          ) {
            let newHref = href.replace('communicator://', '');
            newHref = href.replace('caequans://', '');
            newHref = newHref.replace('node/', '');

            const isDocument = newHref.indexOf('documents/') > -1;

            return (
              <Link
                owner="client"
                variant="body2"
                singles="true"
                weight="regular"
                lh="wide"
                hoverbehavior={clientTheme.components.link.hoverbehavior}
                href={window.location.origin + '/' + newHref}
                target={isDocument ? '_blank' : '_self'}
                rel="noreferrer">
                {domToReact(domNode.children, options)}
              </Link>
            );
          }

          else {
            return (
              <Link
                owner="client"
                variant="body2"
                singles="true"
                weight="regular"
                lh="wide"
                underline="hover"
                hoverbehavior={clientTheme.components.link.hoverbehavior}
                href={domNode.attribs.href}
              >
                {domToReact(domNode.children, options)}
              </Link>
            )
          }

        case "img":
          const src = serverUrl + domNode.attribs.src;
          let attribs = domNode.attribs;
          attribs.src = src;

          const props = attributesToProps(attribs);

          // eslint-disable-next-line jsx-a11y/alt-text
          return <img {...props} />

        case "h1":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h1"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "h2":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h2"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "h3":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h3"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "h4":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h4"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "h5":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h5"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "h6":
          return (
            <Typography
              owner="client"
              className="pt-2 mb-3"
              variant="h6"
              color="clientText.main"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "p":
          return (
            <Typography
              className="mb-3"
              owner="client"
              variant="body2"
              color="clientText.main"
              lh="wide"
              weight="light"
              singles="true"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "blockquote":
          return (
            <Box
              className="mb-3"
              sx={{
                backgroundColor: `${hexToRgbA(clientTheme.color.brand.primary, 0.2)}`,
                padding: "1rem 1rem 0.001rem 1rem",
              }}
            >
              {domToReact(domNode.children, options)}
            </Box>
          );

        case "li":
          return (
            <Typography
              className="mb-3"
              owner="client"
              variant="body2"
              color="clientText.main"
              lh="wide"
              weight="light"
              singles="true"
            >
              <li>
                {domToReact(domNode.children, options)}
              </li>
            </Typography>
          )
      }
    }
  }

  if (props.type && props.type === 'raw') {
    return (
      <div dangerouslySetInnerHTML={{ __html: parse(props.body, options) }} />
    );
  } else {
    return (
      <div>
        {parse(props.body, options)}
      </div>
    );
  }
}
