import React, { useState, useRef } from "react";

// Modules
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageSizeMetadata from "filepond-plugin-image-size-metadata";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";

// App
import { get_csrf_token } from "../../core/auth";
import { serverUrl } from "../../config";
import { postNode } from "../../core/postNode";

// UI components
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Alert, Dialog, DialogContent } from "@mui/material";

// Register the plugins
registerPlugin(
  FilePondPluginImageTransform,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageSizeMetadata,
  FilePondPluginImageResize,
  FilePondPluginImageValidateSize
);

export default function Add(props) {
  const [isError, setError] = useState(null);
  const [isSuccess, setSuccess] = useState(null);
  const [csrf_token, setCSRFToken] = useState(
    props.csrf_token || props.user.csrf_token
  );

  const pond = useRef(null);

  const createMedia = (response, media_type, field) => {
    const data = {
      bundle: media_type,
      field: field,
      fid: response.drupal_internal__fid,
      filename: response.filename ? response.filename : `unknown-${Date.now()}`,
    };

    return postNode("/media_library", data, csrf_token);
  };

  const uploadFile = async (
    field,
    url,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
    media_type
  ) => {
    let request; // Declare request in the outer scope

    const performUpload = async (csrfToken) => {
      request = new XMLHttpRequest(); // Assign to the outer-scoped request variable
      request.open("POST", url);

      request.withCredentials = true;
      request.setRequestHeader("Accept", "application/vnd.api+json");
      request.setRequestHeader("Content-Type", "application/octet-stream");
      request.setRequestHeader(
        "Content-disposition",
        `file; filename="${file.name}"`
      );
      request.setRequestHeader("X-CSRF-Token", csrfToken);

      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = async function () {
        if (request.status >= 200 && request.status < 300) {
          try {
            const response = JSON.parse(request.response);
            try {
              await createMedia(response.data.attributes, media_type, field);
              pond?.current?.removeFiles();
              props.onUpload();
              load(request.responseText);
            } catch (err) {
              error("Failed to create media!");
            }
          } catch (err) {
            error("Failed to parse response!");
          }
        } else if (
          request.status === 403 &&
          request.responseText.includes(
            "X-CSRF-Token request header is invalid"
          )
        ) {
          // Handle invalid CSRF token
          const newCsrfTokenResponse = await get_csrf_token();
          if (newCsrfTokenResponse.data) {
            setCSRFToken(newCsrfTokenResponse.data);
            await performUpload(newCsrfTokenResponse.data);
          } else {
            error("Failed to refresh CSRF token!");
          }
        } else {
          error("Upload failed!");
        }
      };

      request.onerror = function () {
        error("Upload failed due to network error!");
      };

      request.send(file);
    };

    // Start the upload with the initial CSRF token
    await performUpload(csrf_token);

    return {
      abort: () => {
        if (request) {
          request.abort();
        }
        abort();
      },
    };
  };

  return (
    <>
      {isError && (
        <Alert
          severity="error"
          onClose={() => {
            setError(null);
          }}
        >
          <p>{isError}</p>
        </Alert>
      )}
      {isSuccess && (
        <Alert
          severity="success"
          onClose={() => {
            setSuccess(null);
          }}
        >
          <p>{isSuccess}</p>
        </Alert>
      )}

      <FilePond
        ref={pond}
        allowMultiple={false}
        maxFiles={1}
        imageResizeTargetWidth={3000}
        imageResizeTargetHeight={3000}
        imageResizeUpscale={false}
        imageResizeMode={"contain"}
        imagePreviewMaxHeight={200}
        // imageValidateSizeMinWidth={props.minWidth}
        // imageValidateSizeMinHeight={props.minHeight}
        server={{
          revert: null,
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            const type = file.type;
            let size = props.maxSize;
            let url;
            let field = "field_media_image";

            let media_type = props.mediaType;

            if (type.indexOf("audio") > -1) {
              if (media_type.length < 1) {
                media_type = "audio";
              }

              size = props.maxAudioSize;
              field = "field_media_audio_file";
              url = `${serverUrl}/jsonapi/media/${media_type}/${field}`;
              if (file.size > size) {
                error(`File exceeds the ${size} limit.`);
              } else {
                uploadFile(
                  field,
                  url,
                  file,
                  metadata,
                  load,
                  error,
                  progress,
                  abort,
                  transfer,
                  options,
                  media_type
                );
              }
            } else if (type.indexOf("video") > -1) {
              if (media_type.length < 1) {
                media_type = "video";
              }

              size = props.maxVideoSize;
              field = "field_media_video_file";
              url = `${serverUrl}/jsonapi/media/${media_type}/${field}`;

              if (file.size > size) {
                error(`File exceeds the ${size} limit.`);
              } else {
                uploadFile(
                  field,
                  url,
                  file,
                  metadata,
                  load,
                  error,
                  progress,
                  abort,
                  transfer,
                  options,
                  media_type
                );
              }
            } else if (type.indexOf("image") > -1) {
              if (media_type.length < 1) {
                media_type = "image";
              }

              size = props.maxImageSize;
              url = `${serverUrl}/jsonapi/media/${media_type}/${field}`;

              if (file.size > size) {
                error(`File exceeds the ${size} limit.`);
              } else {
                uploadFile(
                  field,
                  url,
                  file,
                  metadata,
                  load,
                  error,
                  progress,
                  abort,
                  transfer,
                  options,
                  media_type
                );
              }
            } else {
              error(`This file format is not allowed.`);
            }
          },
        }}
        labelTapToUndo="tap to close"
        name="files"
        labelIdle={`Drag files here, or <span class="filepond--label-action">Browse</span>`}
        acceptedFileTypes={["image/*", "video/*", "audio/*"]}
        labelFileTypeNotAllowed={"Invalid file type"}
      />
    </>
  );
}
