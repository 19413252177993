import { ShapeIcon, CloseIcon } from '../../icons/icons';
import { Svg } from '@svgdotjs/svg.js';
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/monolith.min.css';

/**
 * Build styles
 */

export default class Shape {

  static get toolbox() {
    return {
      icon: ShapeIcon,
      title: 'Shape',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }



  constructor({ data, config, api, readOnly, block }) {
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      shapeSettingsContainer: 'cdx-shape-settings-container',
      shapeRadiusLabel: 'cdx-shape-radius-label',
      cornerRadiusInput: 'cdx-shape-radius-input',
      shapeBorderLabel: 'cdx-shape-border-label',
      borderWidthInput: 'cdx-shape-border-input',
      shapeBorderColorBtn: 'cdx-shape-border-color-btn',
      shapeSettingsTitle: 'cdx-shape-settings-title',
      shapeSettingsLabel: 'cdx-shape-settings-label',
      close: 'cdx-shape-settings-close',
      shapeElement: 'cdx-shape',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      tuneWrapper: 'editor-block-tune',
      tuneTitle: 'editor-block-tune-title',
      tuneText: 'editor-block-tune-text',
      tuneInput: 'editor-block-tune-input',
      tuneButton: 'editor-block-tune-button',
      editorFont: 'editor-font',
      editorBlockToolbar: 'editor-block-toolbar',
      editorBlockModalBackdrop: 'editor-block-modal-backdrop',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      adminFont: 'admin-font'
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      shape: data.shape || 'rect',
      shapeRadius: data.shapeRadius || '0',
      shapeBorder: data.shapeBorder || '0',
      shapeBorderColor: data.shapeBorderColor || '#000000',
      shapeFill: data.shapeFill || '#333333',
    };
    this._container = ''
    this._shape = document.createElement('button');
    this._shapeNames = ['rect', 'circle', 'triangle'];
    this._shapeModal = this.shapeModal();
  }

  shapeModal() {
    const css = this._CSS;

    const modal = document.createElement('div');
    const modalBackdrop = document.createElement('div');
    const modalInner = document.createElement('div');
    const shapeList = document.createElement('div');

    modal.classList.add('shape-change-modal');
    modalBackdrop.classList.add(css.editorBlockModalBackdrop);
    modalInner.classList.add(css.editorBlockModal);
    shapeList.classList.add('shape-list-container');


    this._shapeNames.forEach((e) => {
      const shapeButton = document.createElement('button');
      shapeButton.classList.add('shape-option-btn', css.blockBtn, css.xxlIcon);
      shapeButton.addEventListener('click', () => {
        this.replaceShape(e);
        this._shapeModal.classList.remove('show');
      });
      const draw = new Svg().addTo(shapeButton).size('100%', '100%');
      this.getShapeByName(e, draw, '#333');

      shapeList.appendChild(shapeButton);
    })

    modalInner.appendChild(shapeList);
    modal.appendChild(modalBackdrop);
    modal.appendChild(modalInner);

    return modal;
  }

  shapeToolBar() {
    const css = this._CSS;

    const container = document.createElement('div');
    const shapeBtn = document.createElement('button');
    const fillColorLabel = document.createElement('label');
    const fillColorInput = document.createElement('input');
    const borderColorLabel = document.createElement('label');
    const borderColorInput = document.createElement('input');
    const cornerRadiusLabel = document.createElement('label');
    const cornerRadiusInput = document.createElement('input');
    const borderWidthLabel = document.createElement('label');
    const borderWidthInput = document.createElement('input');

    container.classList.add(css.editorBlockToolbar);
    shapeBtn.classList.add(css.blockBtn, css.blockBtnSmall, css.xxlIcon);
    fillColorLabel.classList.add(css.shapeSettingsLabel, css.editorBlockModalLabel, css.adminFont);
    borderColorLabel.classList.add(css.shapeSettingsLabel, css.editorBlockModalLabel, css.adminFont);
    cornerRadiusLabel.classList.add(css.shapeSettingsLabel, css.editorBlockModalLabel, css.adminFont);
    borderWidthLabel.classList.add(css.shapeSettingsLabel, css.editorBlockModalLabel, css.adminFont);

    fillColorLabel.innerText = 'Shape color';
    borderColorLabel.innerText = 'Border color';
    cornerRadiusLabel.innerText = 'Corner radius';
    borderWidthLabel.innerText = 'Border width';

    shapeBtn.addEventListener('click', () => {
      if (!this._shapeModal.classList.contains('show')) {
        this._shapeModal.classList.add('show');
      }
    });

    cornerRadiusInput.classList.add(css.cornerRadiusInput);
    cornerRadiusInput.type = 'range';
    cornerRadiusInput.min = '0';
    cornerRadiusInput.max = '200';
    cornerRadiusInput.value = this._data.shapeRadius;
    cornerRadiusInput.addEventListener('input', (e) => {
      this.modifyCornerRadius(e.target.value);
    });


    borderWidthInput.classList.add(css.borderWidthInput);
    borderWidthInput.type = 'range';
    borderWidthInput.min = '0';
    borderWidthInput.max = '50';
    borderWidthInput.value = this._data.shapeBorder;
    borderWidthInput.addEventListener('input', (e) => {
      this.modifyBorderWidth(e.target.value);
    });

    container.appendChild(shapeBtn);
    container.appendChild(fillColorLabel);
    container.appendChild(fillColorInput);
    container.appendChild(borderColorLabel);
    container.appendChild(borderColorInput);
    container.appendChild(cornerRadiusLabel);
    container.appendChild(cornerRadiusInput);
    container.appendChild(borderWidthLabel);
    container.appendChild(borderWidthInput);

    const fillPicker = new Pickr({
      el: fillColorInput,
      theme: 'monolith',
      default: this._data.shapeFill,
      swatches: this._settings.options,
      components: {

        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          input: true,
          cancel: true,
          save: true
        }
      }
    });

    const borderPicker = new Pickr({
      el: borderColorInput,
      theme: 'monolith',
      default: this._data.shapeBorderColor,
      swatches: this._settings.options,
      components: {

        // Main components
        preview: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          input: true,
          cancel: true,
          save: true
        }
      }
    });



    fillPicker.on('change', (color) => {
      this._drawShape.attr('fill', color.toHEXA().toString());
    }).on('save', (color) => {
      this._drawShape.attr('fill', color.toHEXA().toString());
      this._data.shapeFill = color.toHEXA().toString();
      fillPicker.hide();
    }).on('hide', () => {
      this._drawShape.attr('fill', this._data.shapeFill);
    }).on('cancel', () => {
      this._drawShape.attr('fill', this._data.shapeFill);
      fillPicker.hide();
    }).on('swatchselect', (color) => {
      this._drawShape.attr('fill', color.toHEXA().toString());
      this._data.shapeFill = color.toHEXA().toString();
      fillPicker.applyColor();
      fillPicker.hide();
    });

    borderPicker.on('change', (color) => {
      this._drawShape.attr('stroke', color.toHEXA().toString());
    }).on('save', (color) => {
      this._drawShape.attr('stroke', color.toHEXA().toString());
      this._data.shapeBorderColor = color.toHEXA().toString();
      borderPicker.hide();
    }).on('hide', () => {
      this._drawShape.attr('stroke', this._data.shapeBorderColor);
    }).on('cancel', () => {
      this._drawShape.attr('stroke', this._data.shapeBorderColor);
      borderPicker.hide();
    }).on('swatchselect', (color) => {
      this._drawShape.attr('stroke', color.toHEXA().toString());
      this._data.shapeBorderColor = color.toHEXA().toString();
      borderPicker.applyColor();
      borderPicker.hide();
    });

    shapeBtn.innerHTML = `${ShapeIcon}`;

    return container;
  }


  getShapeByName(shapeName, drawInstance, color) {
    switch (shapeName) {
      case 'rect':
        if (drawInstance) {
          return drawInstance.rect('100%', '100%').attr({ fill: color });
        }
        break;
      case 'circle':
        if (drawInstance) {
          return drawInstance.rect('100%', '100%').attr({ fill: color, rx: '100%', ry: '100%' });
        }
        break;
      case 'triangle':
        const points = 'M150.000267,0.191915L300.000534,260l-300.000535-.000001L150.000267,0.191915Z';
        if (drawInstance) {
          return drawInstance.path(points).attr({ fill: color });
        }
        break;
      default:
        if (drawInstance) {
          return drawInstance.rect('100%', '100%').attr({ fill: color });
        }
        break;
    }
  }

  replaceShape(shape) {
    this._data.shape = shape;
    this._draw.clear();
    this._drawShape = this.getShapeByName(shape, this._draw, this._data.shapeFill);
    this.resizeMain();
    this.initBorder();
  }

  modifyBorderWidth(value) {
    if (this._drawShape) {
      if (this._drawShape.attr('clip-path')) {
        this._drawShape.stroke({ width: value, color: this._data.shapeBorderColor });
      }
      else {
        this._clipShape = this.getShapeByName(this._data.shape, this._draw);
        this._drawClip = this._draw.clip().add(this._clipShape);
        this._drawShape.clipWith(this._drawClip);
        this._drawShape.stroke({ width: value, color: this._data.shapeBorderColor });
      }
      this._data.shapeBorder = `${value}`;
    }
  }

  modifyCornerRadius(value) {
    if (this._drawShape && this._data.shape !== 'circle') {
      this._drawShape.radius(value);
      this._data.shapeRadius = `${value}`;
      if (this._drawShape.attr('clip-path') && this._clipShape) {
        this._clipShape.radius(value);
      }
    }
    else {
      this._drawShape.radius('100%');
      this._data.shapeRadius = `${value}`;
      if (this._drawShape.attr('clip-path') && this._clipShape) {
        this._clipShape.radius('100%');
      }
    }
  }

  initBorder() {
    if (this._data.shapeBorder) {
      this.modifyBorderWidth(this._data.shapeBorder);
    }
    // if (this._data.shapeRadius && this._data.shape !== 'circle') {
    if (this._data.shapeRadius) {
      this.modifyCornerRadius(this._data.shapeRadius);
    }
  }

  render() {
    const shapeBlockContainer = document.createElement('div');
    shapeBlockContainer.classList.add('cdx-shape-block-container');

    this._shape.classList.add(this._CSS.shapeElement);
    this._draw = new Svg().addTo(this._shape).size('100%', '100%');

    this._drawShape = this.getShapeByName(this._data.shape, this._draw, this._data.shapeFill);
    this.initBorder();
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        // Handle the size change
        this.initBorder();
        // this.modifyBorderWidth(this._data.shapeBorder);
      }
    });
    resizeObserver.observe(this._shape);

    shapeBlockContainer.appendChild(this._shape);
    shapeBlockContainer.appendChild(this._shapeModal);
    shapeBlockContainer.appendChild(this.shapeToolBar());


    return shapeBlockContainer;
  }


  resizeMain() {
    const target = this._shape.closest('.ce-block')
    if (target) {
      const width = target.style.width;
      const height = target.style.height;

      if (width > height) {
        target.style.height = width;
      } else {
        target.style.width = height;
      }
    }
  }

  save() {
    let data = {
      shape: this._data.shape,
      shapeRadius: this._data.shapeRadius,
      shapeBorder: this._data.shapeBorder,
      shapeBorderColor: this._data.shapeBorderColor,
      shapeFill: this._data.shapeFill,
    }

    return data;
  }
}