import React from 'react';

// Modules

// App
import SelectedImage from './selectedImage';
import SelectedAudio from './selectedAudio';
import SelectedVideo from './selectedVideo';

// UI components
import 'cropperjs/dist/cropper.css';
import { Alert } from '@mui/material';

export default function SelectedMedia(props) {
  if (!props?.selectedMedia) {
    return (
      <Alert
        variant='filled'
        severity='error'
        onClose={() => {
          props.onError();
        }}
      >
        <p>No image selected. Please go back and select an image.</p>
      </Alert>
    );
  }

  return (
    <>
      {props.selectedMedia.bundle === 'image' && (
        <SelectedImage
          isNotForInsert={props.isNotForInsert}
          selectedMedia={props.selectedMedia}
          user={props.user}
          permissions={props.permissions}
          cropApplied={props.cropApplied}
          setCropApplied={props.setCropApplied}
          onError={props.onError}
          onUpload={props.onUpload}
          onMediaInsert={props.onMediaInsert}
          onCancel={props.onCancel} 
          minWidth={props.minWidth}
          minHeight={props.minHeight}
          maxWidth={props.maxWidth}
          maxHeight={props.maxHeight}
          aspectRatio={props.aspectRatio}
          maxSize={props.maxSize}
          maxImageSize={props.maxImageSize}
          maxAudioSize={props.maxAudioSize}
          maxVideoSize={props.maxVideoSize}
          onAltUpdate={props.onAltUpdate}
          currentBlock={props.currentBlock}
          categories={props.categories}
        />
      )}

      {props.selectedMedia.bundle === 'audio' && (
        <SelectedAudio 
          selectedMedia={props.selectedMedia} 
          onMediaInsert={props.onMediaInsert} 
          onCancel={props.onCancel} 
          isNotForInsert={props.isNotForInsert}
          user={props.user}
          permissions={props.permissions}
          categories={props.categories}
        />
      )}

      {props.selectedMedia.bundle === 'video' && (
        <SelectedVideo 
          selectedMedia={props.selectedMedia} 
          onMediaInsert={props.onMediaInsert} 
          onCancel={props.onCancel} 
          isNotForInsert={props.isNotForInsert}
          user={props.user}
          onTimestampUpdate={props.onTimestampUpdate}
          permissions={props.permissions}
          categories={props.categories}
        />
      )}
    </>
  );
};