import {IconH3} from '@codexteam/icons';

export default class H3 {

    static get isInline() {
      return true;
    }
 
    static get sanitize() {
      return {
        h3: {}
      }
    }

    get state() {
      return this._state;
    }
  
    set state(state) {
      this._state = state;
  
      this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }
  
    constructor({api}) {
      this.api = api;
      this.button = null;
      this._state = false;
  
      this.tag = 'H3';
      this.headings = ['H1','H2','H3','H4','H5','H6']
    //   this.class = 'cdx-marker';
    }
  
    render() {
      this.button = document.createElement('button');
      this.button.type = 'button';
      this.button.innerHTML = IconH3;
      this.button.classList.add(this.api.styles.inlineToolButton);
  
      return this.button;
    }
  
    surround(range) {
      if (this.state) {
        this.unwrap(range);
        return;
      }
      this.wrap(range);
    }
  
    wrap(range) {
      const selectedText = range.extractContents();
      const tag = document.createElement(this.tag);

      if(this.checkTag().status){
        const tagExists = this.api.selection.findParentTag(this.checkTag().tag)
        tagExists.remove()
        // console.log(tagExists)
      }
      
      tag.appendChild(selectedText);
      range.insertNode(tag);
  
      this.api.selection.expandToTag(tag);
    }
  
    unwrap(range) {
      const tag = this.api.selection.findParentTag(this.tag);
        // this.class);
      const text = range.extractContents();
  
      tag.remove();
  
      range.insertNode(text);
    }
  
  
    checkState() {
      const mark = this.api.selection.findParentTag(this.tag);
  
      this.state = !!mark;
    }

    checkTag() {
      const tagExists = {
        status: false,
        tag: null
      }
      this.headings.forEach(tag=>{
        if(this.api.selection.findParentTag(tag)){
          tagExists.status = true
          tagExists.tag = tag
        }
      })
      return tagExists
    }
  }