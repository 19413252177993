import React, { Component, useState } from "react";

// Modules
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// UI components
import { Home, User, FileText } from "react-feather";
import { FormControlLabel, IconButton, Typography } from "@mui/material";
import {
  InsertDriveFileOutlined,
  VideocamOutlined,
  InsertLinkOutlined,
} from "@mui/icons-material";
import PieChart from "@mui/icons-material/PieChartOutlineOutlined";
import FormIcon from "@mui/icons-material/CreateOutlined";
import MicIcon from "@mui/icons-material/MicNoneRounded";
import TrainingIcon from "@mui/icons-material/SchoolOutlined";
import EventsIcon from "@mui/icons-material/CalendarTodayOutlined";
import QuizIcon from "@mui/icons-material/QuizOutlined";
import ContributeIcon from "@mui/icons-material/VolunteerActivismOutlined";
import HRServiceIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

export default function EquansSidebar(props) {
  const [activeItem, setActiveItem] = useState("home");

  const user = useSelector((state) => state).authReducer.user;

  const handleClick = () => {
    if (props.isMobile) {
      props.sidebarVisibility();
    }
  };

  // const isMobile = !this.state.desktopQuery.matches;
  let showNavigation = "collapsed";
  if (props.showNavigation === true || props.showNavigation === "true") {
    showNavigation = "expanded";
  }

  return (
    <div className={`navbar-container ${showNavigation}`}>
      {props.isMobile && (
        <div className="mobile-overlay" onClick={props.sidebarVisibility}></div>
      )}
      <nav className={`navbar ${showNavigation}`}>
        {typeof user.current_user !== "undefined" ? (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to={"news"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("news");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "news" ? "active" : ""}
                    control={
                      <IconButton>
                        <FileText size={20} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Latest News
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  MEDIA LIBRARIES
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"videos"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("videos");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "videos" ? "active" : ""}
                    control={
                      <IconButton>
                        <VideocamOutlined sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Videos
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"documents"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("documents");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "documents" ? "active" : ""}
                    control={
                      <IconButton>
                        <InsertDriveFileOutlined sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Documents
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"podcasts"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("podcasts");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "podcasts" ? "active" : ""}
                    control={
                      <IconButton>
                        <MicIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Podcasts
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  TRAINING & RESOURCES
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"training"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("training");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "training" ? "active" : ""}
                    control={
                      <IconButton>
                        <TrainingIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Training Courses
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"events"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("events");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "events" ? "active" : ""}
                    control={
                      <IconButton>
                        <EventsIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Upcoming Events
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"links"}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("links");
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "links" ? "active" : ""}
                    control={
                      <IconButton>
                        <InsertLinkOutlined sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Useful Links
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  INTERACTIVE
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'/contribute'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("/contribute")
                    }
                    return "nav-link";
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === '/contribute' ? 'active' : ''}
                    control={
                      <IconButton>
                        <ContributeIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        Contribute
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#"
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("/my-hr-services");
                    }
                    return "nav-link";
                  }}
                  onClick={() => {
                    window.open('https://fiori.equans.co.uk', '_blank');
                  }}

                  // https://fioridev.equans.co.uk
                >
                  <FormControlLabel
                    variant="nav-item"
                    color={activeItem === "/" ? "active" : ""}
                    control={
                      <IconButton>
                        <HRServiceIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                    label={
                      <Typography variant="span" owner="client">
                        SAP Fiori
                      </Typography>
                    }
                  />
                </NavLink>
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/login"
                  className="nav-link"
                  onClick={() => handleClick()}
                >
                  <div className="nav-item__icon">
                    <User size={20} />
                  </div>
                  <span className="nav-item__label">Login</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
}
