const defaultState = {
  search: '',
};

export const searchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_TERM':
      return {
        search: action.payload,
      };

    case 'REMOVE_TERM':
      return {
        search: '',
      };

    default:
      return state;
  }
};