import { InlineImageIcon, DeleteIcon, SizeIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

export default class InlineImage {

  static get toolbox() {
    return {
      icon: InlineImageIcon,
      title: 'Inline image',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-inline-media-container',
      wrapper: 'cdx-inline-media-wrapper',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      dimensionSettings: 'cdx-dim-settings',
      widthContainer: 'cdx-dim-width-container',
      heightContainer: 'cdx-dim-height-container',
      dimensionsLabel: 'cdx-dim-label',
      dimensionsInput: 'cdx-dim-input',
      dimensionBtn: 'cdx-dim-btn',
      tuneWrapper: 'editor-block-tune',
      tuneTitle: 'editor-block-tune-title',
      tuneText: 'editor-block-tune-text',
      tuneInput: 'editor-block-tune-input',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      dimensions: data.dimensions || { width: 'auto', height: 'auto' },
      listingId: data.listingId || '',
      listingName: data.listingName || '',
      assetId: data.assetId || '',
      fileId: data.fileId || '',
      iceMedia: data?.iceMedia || '',
    };

    this._element = this.getElement();
  }

  getElement() {
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxHeight);
      // iceportal
      img.setAttribute('data-listingId', this._data.listingId);
      img.setAttribute('data-listingName', this._data.listingName);
      img.setAttribute('data-assetId', this._data.assetId);
      img.setAttribute('data-fileId', this._data.fileId);
      img.setAttribute('data-iceMedia', this._data.iceMedia);

      img.style.width = `${this._data.dimensions.width}px`;
      img.style.height = `${this._data.dimensions.height}px`;
    }

    return img;
  }

  renderSettings() {
    const css = this._CSS

    const mainContainer = document.createElement('div');
    const widthContainer = document.createElement('div');
    const heightContainer = document.createElement('div');
    const widthLabel = document.createElement('label');
    const widthInput = document.createElement('input');
    const heightLabel = document.createElement('label');
    const heightInput = document.createElement('input');

    mainContainer.classList.add(css.dimensionSettings);
    widthContainer.classList.add(css.widthContainer, css.tuneWrapper);
    heightContainer.classList.add(css.heightContainer, css.tuneWrapper);
    widthLabel.classList.add(css.dimensionsLabel, css.tuneTitle);
    widthInput.classList.add(css.dimensionsInput, css.tuneInput);
    heightLabel.classList.add(css.dimensionsLabel, css.tuneTitle);
    heightInput.classList.add(css.dimensionsInput, css.tuneInput);

    widthInput.setAttribute('type', 'number');
    heightInput.setAttribute('type', 'number');
    widthInput.setAttribute('min', 0);
    heightInput.setAttribute('min', 0);
    widthInput.setAttribute('max', 500);
    heightInput.setAttribute('max', 500);

    widthLabel.innerHTML = 'Width:';
    heightLabel.innerHTML = 'Height:';
    widthInput.value = this._data.dimensions.width;
    heightInput.value = this._data.dimensions.height;

    widthInput.addEventListener("input", (e) => this.inputHandler.call(this, "width", widthInput, e.target.value), false);
    widthInput.addEventListener("change", (e) => this.inputHandler.call(this, "width", widthInput, e.target.value), false);
    heightInput.addEventListener("input", (e) => this.inputHandler.call(this, "height", heightInput, e.target.value), false);
    heightInput.addEventListener("change", (e) => this.inputHandler.call(this, "height", heightInput, e.target.value), false);

    widthContainer.appendChild(widthLabel);
    widthContainer.appendChild(widthInput);
    heightContainer.appendChild(heightLabel);
    heightContainer.appendChild(heightInput);
    mainContainer.appendChild(widthContainer);
    mainContainer.appendChild(heightContainer);

    return (mainContainer);
  }

  inputHandler(dimension, input, value) {
    if (value <= 0 || value == '') {
      input.value = ''
      if (dimension === "width") {
        this._data.dimensions.width = 'auto';
        this._element.style.width = 'auto';
      }
      if (dimension === "height") {
        this._data.dimensions.height = 'auto';
        this._element.style.height = 'auto';
      }
    }
    else {
      if (dimension === "width") {
        this._data.dimensions.width = value;
        this._element.style.width = `${value}px`;
      }
      if (dimension === "height") {
        this._data.dimensions.height = value;
        this._element.style.height = `${value}px`;
      }
    }
  }

  render() {
    this.container = document.createElement('div');
    this.container.classList.add(this._CSS.container);
    let img = this._element;

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);


      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.innerHTML = `${InlineImage.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, this._CSS.xlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
        this.container.classList.add('has-img');
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
        btn.innerHTML = `${InlineImage.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height = '';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';
        // iceportal
        this._data.listingId = '';
        this._data.listingName = '';
        this._data.assetId = '';
        this._data.fileId = '';
        this._data.iceMedia = '';

        this._element.remove();
        btnRemove.remove();
      }

      if (typeof img !== 'undefined') {
        this.container.appendChild(img);
        this.container.appendChild(btn);
        this.container.appendChild(btnRemove);
        this.container.classList.add('has-img');
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        this.container.appendChild(input);
        this.container.appendChild(btn);
      }
    } else {
      if (typeof img !== 'undefined') {
        this.container.appendChild(img);
        this.container.classList.add('has-img');
      }
    }

    return this.container;
  }

  save() {
    return this._data;
  }
}