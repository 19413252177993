import React from "react";

// Modules
import parse from 'html-react-parser';

import { Typography } from "@mui/material";

const regex = /(<([^>]+)>)/gi;

export default function PostExcerpt(props) {
  let defaultStyle = 'excerpt text-break';
  let style = props.className ? `${props.className} ${defaultStyle}` : defaultStyle;

  let EXCERPT_MAX_LENGTH;
  if (props.maxLength) {
    EXCERPT_MAX_LENGTH = props.maxLength;
  }

  if (!props.body) {
    return null;
  }

  let excerpt = props.body;
  excerpt = excerpt.replace(regex, "").trimEnd();

  if (excerpt.length > EXCERPT_MAX_LENGTH) {
    excerpt = excerpt.substring(0, EXCERPT_MAX_LENGTH);
    excerpt = excerpt.substring(0, excerpt.lastIndexOf(" "));
    excerpt += "...";
  }

  return (
    // <div
    //   dangerouslySetInnerHTML={{ __html: parse(excerpt) }}
    //   className={style}
    // />
    <Typography
      className={style}
      owner={props.owner}
      variant={props.variant}
      color={props.excerptColor}
      weight={props.weight}
      lh={props.lh}
    >
      {parse(excerpt)}
    </Typography>
  );
}
