const defaultState = {
  token_type: "Bearer",
  expires_in: "",
  access_token: "",
  refresh_token: "",
};

export const tokenReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SAVE_TOKEN":
      localStorage.setItem('token', JSON.stringify(action.payload));

      return action.payload;

    case "REMOVE_TOKEN":
      return defaultState;
    default:
      return state;
  }
};
