import { useEffect, useState } from 'react';

// Modules
import { useSelector } from 'react-redux';

// App
import PageHeader from "../../partials/pageHeader";
import Creator from "../../partials/creator";

// UI
import { Row, Col } from "react-bootstrap";
import { Box, Typography, Button } from "@mui/material";
import { FileDownloadOutlined } from '@mui/icons-material';

export default function AddIn(props) {
  const content = useSelector((state) => state).contentReducer;

  return (
    <>
      <div className="add-in screen d-flex flex-column justify-content-between h-100 mx-lg-auto">
        <Row className="gx-0">
          <div className='dynamic-content addin' dangerouslySetInnerHTML={{ __html: content.outlook ? content.outlook : '' }} />
        </Row>
        <Creator />
      </div>
    </>
  );
}