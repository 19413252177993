import React, { Component, useState } from 'react';

// Modules
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

// UI components
import { BookOpen, Home, User, BarChart2, FileText } from 'react-feather';
import { FormControlLabel, IconButton, Typography } from '@mui/material';
import { InsertDriveFileOutlined, VideocamOutlined, InsertLinkOutlined } from '@mui/icons-material';
import PieChart from '@mui/icons-material/PieChartOutlineOutlined';
import FormIcon from '@mui/icons-material/CreateOutlined';
import MicIcon from '@mui/icons-material/MicNoneRounded';
import TrainingIcon from '@mui/icons-material/SchoolOutlined';
import EventsIcon from '@mui/icons-material/CalendarTodayOutlined';
// import ArticleIcon from '@mui/icons-material/Article';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import ForumsIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CallIcon from '@mui/icons-material/CallOutlined';
import CompanyDirectoryIcon from '@mui/icons-material/PeopleOutlineOutlined';
import QuizIcon from '@mui/icons-material/QuizOutlined';

export default function CompanyappSidebar(props) {
  const [navItems, setNavItems] = useState({
    noAuth: {
      // media: {
      //   categoryLabel: 'MEDIA LIBRARIES',
      //   options: [
      //     {
      //       label: 'Presentations',
      //       path: '/presentations',
      //       icon: <BookOpen size={20} />,
      //       key: 'presentations',
      //     },
      //   ]
      // },
    },
    auth: {
      media: {
        categoryLabel: 'MEDIA LIBRARIES',
        options: [
          {
            label: 'Radisson4u',
            path: '/radisson4u',
            icon: <BarChart2 size={20} />,
            key: 'radisson4u',
          },
          {
            label: 'Presentations',
            path: '/presentations',
            icon: <BookOpen size={20} />,
            key: 'presentations',
          },

        ]
      },
    }
  })
  const [activeItem, setActiveItem] = useState("home")

  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;
  const currentLocation = useSelector((state => state.locationReducer.currentLocation));

  const handleClick = () => {
    if (props.isMobile) {
      props.sidebarVisibility();
    }
  };

  // const isMobile = !this.state.desktopQuery.matches;
  let showNavigation = "collapsed";
  if (props.showNavigation === true || props.showNavigation === 'true') {
    showNavigation = "expanded";
    // If mobile add class to body
    // if (!this.state.desktopQuery.matches) {
    //   document.body.classList.add('navbar-expanded');
    // }
  } else {
    // document.body.classList.remove('navbar-expanded');
  }

  const hasCreate = permissions.access_presentation_creator_mode ? true : false;

  return (
    <div className={`navbar-container ${showNavigation} ${currentLocation === 'presentations-creator' ? 'presentations-creator' : ''}`}>
      {props.isMobile && (
        <div className="mobile-overlay" onClick={props.sidebarVisibility}></div>
      )}
      <nav className={`navbar ${showNavigation}`}>
        {typeof user.current_user !== 'undefined' ? (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("home")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'home' ? 'active' : ''}
                    control={
                      <IconButton >
                        <Home size={20} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Home
                      </Typography>}
                  />
                  {/* <div className="nav-item__icon">
                    <Home size={20} />
                  </div>
                  <span className="nav-item__label">Home</span> */}
                </NavLink>
              </li>

              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  NEWS & EVENTS
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'news'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("news")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'news' ? 'active' : ''}
                    control={
                      <IconButton >
                        <FileText size={20} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Latest News
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'blogs'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("blogs")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'blogs' ? 'active' : ''}
                    control={
                      <IconButton >
                        <ArticleIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Company Blogs
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'events'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("events")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'events' ? 'active' : ''}
                    control={
                      <IconButton >
                        <EventsIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Upcoming Events
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  MEDIA LIBRARIES
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={hasCreate ? 'presentations-creator' : 'presentations'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("presentations")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'presentations' ? 'active' : ''}
                    control={
                      <IconButton >
                        <BookOpen size={20} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Presentations
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'videos'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("videos")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'videos' ? 'active' : ''}
                    control={
                      <IconButton >
                        <VideocamOutlined sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Video Library
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'galleries'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("galleries")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'galleries' ? 'active' : ''}
                    control={
                      <IconButton >
                        <CollectionsOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Galleries
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'podcasts'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("podcasts")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'podcasts' ? 'active' : ''}
                    control={
                      <IconButton >
                        <MicIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Podcasts
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  TRAINING & RESOURCES
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'training'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("training")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'training' ? 'active' : ''}
                    control={
                      <IconButton >
                        <TrainingIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Training Courses
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'quizzes'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("quizzes")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'quizzes' ? 'active' : ''}
                    control={
                      <IconButton >
                        <QuizIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Tests & Quizzes
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'documents'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("documents")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'documents' ? 'active' : ''}
                    control={
                      <IconButton >
                        <InsertDriveFileOutlined sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Documents
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'links'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("links")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'links' ? 'active' : ''}
                    control={
                      <IconButton >
                        <InsertLinkOutlined sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Useful Links
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  INTERACTIVE
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'polls'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("polls")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'polls' ? 'active' : ''}
                    control={
                      <IconButton >
                        <PieChart sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Opinion Polls
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'forms'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("forms")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'forms' ? 'active' : ''}
                    control={
                      <IconButton >
                        <FormIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Surveys & Forms
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'forums'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("forums")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'forums' ? 'active' : ''}
                    control={
                      <IconButton >
                        <ForumsIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Discussion Boards
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="section-heading">
                <Typography
                  owner="client"
                  variant="button"
                  color="clientHeading.sideBar.main"
                >
                  INFORMATION HUB
                </Typography>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'company_directory'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("company_directory")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'company_directory' ? 'active' : ''}
                    control={
                      <IconButton >
                        <CompanyDirectoryIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Company Directory
                      </Typography>}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={'contacts'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("contacts")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'contacts' ? 'active' : ''}
                    control={
                      <IconButton >
                        <CallIcon sx={{ fontSize: 20 }} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        User Directory
                      </Typography>}
                  />
                </NavLink>
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  onClick={() => handleClick()}
                >
                  <div className="nav-item__icon">
                    <Home size={20} />
                  </div>
                  <span className="nav-item__label">Home</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/login"
                  className="nav-link"
                  onClick={() => handleClick()}
                >
                  <div className="nav-item__icon">
                    <User size={20} />
                  </div>
                  <span className="nav-item__label">Login</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
}
