import React, { useState } from "react";

// Modules
import { FormControl, InputLabel, MenuItem } from "@mui/material";

// App
import { decodeHtmlEntities } from "../utils/helpers";

// UI components
import { Select } from '@mui/material';

export default function CategorySelector(props) {

  const handleCategorySelection = (event) => {
    props.handleCategorySelection(event);
  };

  // Decode categories
  let decodedCategories = [];

  if (
    props.categories !== "undefined" &&
    props.categories !== undefined
  ) {

    props.categories.map((item) => {
      let valueParam = item.value;
      let labelParam = item.label;

      if (props.name === 'presentationCategories') {
        valueParam = item.tid;
        labelParam = item.title;
      }

      if (props.name === 'presentationThemes') {
        valueParam = item.tid;
        labelParam = item.label;

        if (!item.label || item.label === '') {
          labelParam = item.title;
        }
      }

      if (props.name === 'slideThemes') {
        valueParam = item.tid;
        labelParam = item.label;

        if (!item.label || item.label === '') {
          labelParam = item.title;
        }
        return decodedCategories.push({
          value: valueParam,
          label: decodeHtmlEntities(labelParam),
          title: item.title
        })
      }

      return decodedCategories.push({
        value: valueParam,
        label: decodeHtmlEntities(labelParam),
      });
    });
  }

  return (
    <>
      <FormControl
        className={props.className}
        variant={props.variant ? props.variant : "standard"}
        size={props.size}
        sx={{
          width: 1,
          "& label[data-shrink=true]": {
            visibility: "hidden"
          },
          "& legend": {
            width: 0
          }
        }}>
        <InputLabel id={`${props.label}-id`}>{props.label}</InputLabel>
        <Select
          labelId={`${props.label}-id`}
          defaultValue={decodedCategories[0]?.value}
          value={props.selectedCategory?.value}
          label={props.label}
          disabled={props.disabled}
          onChange={(e => {
            handleCategorySelection(e.target.value);
          })}
        >
          {decodedCategories.map((item, index) => (
            <MenuItem
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                "&.Mui-selected": {
                  backgroundColor: "primary.light"
                }
              }}
              key={`d-${index}`}
              id={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl >
    </>
  );
}

