import React from 'react';

// UI components
import { Box, IconButton, PaginationItem } from "@mui/material/";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Pagination(props) {
  return (
    <Box className={props.containerClasses}>
      <IconButton
        disabled={props.previousDisabled}
        color={props.owner === 'client' ? 'clientPrimary' : 'primary'}
        onClick={props.onPrevious}
        sx={{ borderRadius: '15px' }}
      >
        <ChevronLeft /> <span style={{ fontSize: 14 }}>PREV</span>
      </IconButton>

      <PaginationItem
        // color={props.owner === 'client' ? 'clientPrimary' : 'primary'}
        owner={props.owner}
        page={props.currentPage}
        type={'page'}
        selected={true}
        shape='circular'
        sx={{ margin: '0 10px' }}
      />

      <IconButton
        disabled={props.nextDisabled}
        color={props.owner === 'client' ? 'clientPrimary' : 'primary'}
        onClick={props.onNext}
        sx={{ borderRadius: '15px' }}
      >
        <span style={{ fontSize: 14 }}>NEXT</span> <ChevronRight />
      </IconButton>
    </Box>
  );
}
