const defaultState = {
  title: '',
  category: 0,
  language: 0,
  author: '',
  idSita: '',
  library: 0,
  status: [],
  theme: 0,
  currentPage: 1,
};

export const filtersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        title: action.payload.title ? action.payload.title : '',
        category: action.payload.category ? action.payload.category : 0,
        language: action.payload.language ? action.payload.language : 0,
        author: action.payload.author ? action.payload.author : '',
        idSita: action.payload.idSita ? action.payload.idSita : '',
        library: action.payload.library ? action.payload.library : 0,
        status: action.payload.status ? action.payload.status : [],
        theme: action.payload.theme ? action.payload.theme : 0,
        currentPage: action.payload.currentPage ? action.payload.currentPage : 1,
      };

    case 'RESET_FILTERS':
      return {
        title: '',
        category: 0,
        language: 0,
        author: '',
        idSita: '',
        library: 0,
        status: [],
        theme: 0,
        currentPage: 1,
      };

    default:
      return state;
  }
};