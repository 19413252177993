import React, { useState } from  'react';

// Modules

// App
import { filesystem_root } from '../../../../config';
import SlidePreview from '../slidePreview';

// UI components
import { Col } from 'react-bootstrap';
import { Box, Card, CardMedia, Skeleton, Typography, Fab, Tooltip, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { PlayArrow } from '@mui/icons-material';

export default function Layout(props) {
  const [hasImage, setHasImage] = useState(true);
  const [previewDialog, setPreviewDialog] = useState(false);

  const onAddSlide = (layout) => {
    if (layout.slide) {
      props.onAddSlide(layout.slide, layout);
    } else {
      props.onAddSlide(null, layout);
    }
  }

  return (
    <Col
      className={`layout-item ${props.className}`}
      xs={12}
      sm={6}
      lg={3}
      xxl={2}
    >
      <Box sx={{marginBottom: 1}}>
        <Box sx={{height: 40, overflow: 'auto'}}>
          <Typography variant='body'>
            <b>{props.layout.name}</b>
            {props.layout.version && props.layout.version > 1 && (
              <>
                {` (v${props.layout.version})`}
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <Card className='mui-card' sx={{ width: '100%' }}>
        {hasImage ? (
          <CardMedia
            className='thumb'
            component='img'
            image={`${filesystem_root}${props.layout?.slide?.thumbnail}`}
            alt={''}
            onError={() => setHasImage(false)}
          />
        ) : (
          <Box className='ratio ratio-16x9'>
            <Box className='skeleton d-flex'>
              <Box className='w-100 h-100'>
                <Box className='d-flex h-100 justify-content-center align-content-center' sx={{padding: '5%', flexDirection: 'column'}}>
                  <Skeleton animation={false} width='30%' height={20} />
                  <Skeleton animation={false} width='100%' height={20} />
                  <Skeleton animation={false} width='100%' height={20} />
                  <Skeleton animation={false} width='100%' height={20} />
                  <Skeleton animation={false} width='100%' height={20} />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {props?.layout?.slide?.id && (
          <Box className='preview-slide-btn-container'>
            <Button
              className='preview-slide-btn d-flex flex-column align-items-center'
              onClick={() => setPreviewDialog(true)}
            >
              <Box className='preview-slide-btn-icon-container'>
                <PlayArrow className='preview-slide-btn-icon'/>
              </Box>
              <Typography
                owner='admin'
                variant='caption'
              >
                View
              </Typography>
            </Button>
          </Box>
        )}
      </Card>
      <Box sx={{marginTop: '-20px'}} textAlign='center'>
        <Tooltip title={`Select "${props.layout.name}"`}>
          <Fab
            id='select-layout'
            onClick={() => {
              onAddSlide(props.layout);
            }}
            size='small'
            color='primary'
            aria-label='Select layout'>
            <Add />
          </Fab>
        </Tooltip>
      </Box>
      {props?.layout?.slide?.id && (
        <SlidePreview
          open={previewDialog}
          onClose={() => {
            setPreviewDialog(false)
          }}
          mode={'view'}
          slideId={props?.layout?.slide?.id}
          presentationId={props?.layout?.slide?.initial_presentation}
        />
      )}
    </Col>
  );
};
