const defaultCountry = {
  countryCode: "",
  countryID: 0,
  name: "All Countries",
  phonePrefix: "0",
};

const defaultState = {
  // activeIndex = 0
  selectedRegion: "All Regions",
  selectedCountry: defaultCountry,
  selectedChain: "All Chains",
  regions: [],
  countries: [],
  chains: [],
  // activeIndex = 1
  selectedMediaType: 'PH',
  fileId: '',
  publicMediaId: '',
  privateMediaId: '',
  // listing
  selectedListingId: null,
  selectedListingName: null,
}

export const iceportalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER':
      return {
        ...state,
        ...action.payload,
      };

    case 'RESET_FILTER':
      return {
        ...defaultState,
      };

    default:
      return state;
  }
};