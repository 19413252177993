import React from 'react';

// Modules

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoWhite from '../assets/ca-logo-reverse-stroke.svg';
import logoBlue from '../assets/ca-logo-pwa.png';
import { Box, CircularProgress } from '@mui/material';

export default function CompanyappLoader() {
  return (
    <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
      <Row className='text-center justify-content-center'>
        <Col xs={8}>
          <Box sx={{ width: 220, textAlign: 'center', margin: '0 auto 20px' }}>
            <img className='logo-white' style={{ margin: '0 auto' }} src={logoWhite} alt={"companyapp logo"} />
            <img className='logo-blue' style={{ margin: '0 auto' }} src={logoBlue} alt={"companyapp logo"} />
          </Box>
        </Col>
        <Col xs={8}><CircularProgress size={32} disableShrink /></Col>
        <Col xs={8}>
          <span style={{ margin: '5px 0 0 10px', display: 'block' }}>
            Loading...
          </span>
        </Col>
      </Row>
    </div>
  );
}