import { serverUrl, filesystem_root, subdomain } from '../config';

export const backgroundImageURIGenerator = (image, isLive, slide, presentation_id) => {
  let uri = ''
  
  if (image) {
    if (typeof image === 'string') {
      uri = image;
    } else {
      if (image.external) {
        uri = image.field_media_image;
      } else {
        if (image.x && image.y && image.width && image.height) {
          uri = `${serverUrl}/api/image?mid=${image.mid}&x=${image.x}&y=${image.y}&width=${image.width}&height=${image.height}&maxWidth=${image.maxWidth}&maxHeight=${image.maxHeight}`
        } else {
          uri = image.field_media_image;
        }
      }
    }
  }

  if (isLive) {
    const isLibrary = parseInt(presentation_id) === parseInt(slide.initial_presentation) ? false : true;

    if (isLibrary) {
      uri = `${filesystem_root}/${subdomain}/presentations/${slide.initial_presentation}/${slide.initial_presentation}/${uri}`
    } else {
      uri = `${filesystem_root}/${subdomain}/presentations/${presentation_id}/${presentation_id}/${uri}`;
    }
  }

  return decodeURI(uri);
}