import React, { useEffect, useState } from "react";

// Modules
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

// App
import { getOnlineNode } from "../../../core/getNode";
import { patchNode } from "../../../core/postNode";
import NoData from "../../../partials/noData";
import Presentation from "../partials/presentation/view/presentation";
import CategorySelector from "../../../partials/categorySelector";

// UI components
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Toolbar,
  Typography,
  Alert,
  Box,
  TextField,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";

export default function CreatePresentationFromTemplate(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const defaultThemeItem = {
    label: "Select a theme",
    value: 0,
  };

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [themes, setThemes] = useState([{ value: 0, title: "Select a theme" }]);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [selectedThemeItem, setSelectedThemeItem] = useState(defaultThemeItem);

  /**
   * Data
   */
  const [presentations, setPresentations] = useState([]);
  const [selectedPresentation, setSelectedPresentation] = useState(null);

  const navigate = useNavigate();
  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    getTaxonomies();
  }, []);

  useEffect(() => {
    let path = `api/user_presentations?template=true`;

    getOnlineNode(path)
      .then((response) => {
        setPresentations(response.data.rows);
        setLoading(false);
      })
      .catch((_error) => {
        setError(true);
      });
  }, []);

  const getTaxonomies = () => {
    getOnlineNode("api/categories?vid=themes")
      .then((response) => {
        let themes = [{ tid: 0, label: "Select a theme" }];

        response.data.forEach((item) => {
          if (item.has_view_permission === true) {
            if (item.vid === "themes") {
              themes.push({
                ...item,
                value: item.tid,
                label: item.label ? item.label : item.title,
              });
            }
          }
        });

        setThemes(themes);
      })
      .catch((_error) => {});
  };

  const onClose = () => {
    props.onClose();
    setSelectedPresentation(null);
    setTitle("");
    setBody("");
    setSelectedTheme(0);
    setSelectedThemeItem(defaultThemeItem);
  };

  const onPresentationSelected = () => {
    if (selectedPresentation.id) {
      setLoading(true);
      const path = `/api/presentation_clone/${selectedPresentation?.id}`;
      getOnlineNode(path)
        .then((response) => {
          if (response.data?.id[0]?.value) {
            let data = {
              id: response.data.id[0].value,
              title: title,
              body: body,
              theme: selectedTheme,
            };

            patchNode(
              `api/presentation/${response.data.id[0].value}`,
              data,
              user.csrf_token
            )
              .then((_response) => {
                setSelectedPresentation(null);
                props.onClose();
                props.onSuccess();
                setLoading(false);
                navigate(`/presentations/edit/${response.data.id[0].value}`);
              })
              .catch((error) => {});
          } else {
            setSelectedPresentation(null);
            props.onClose();
            props.onSuccess();
            setLoading(false);
          }
        })
        .catch((_error) => {
          setLoading(false);
          setError(true);
        });
    }
  };

  const handleThemeSelection = (tid) => {
    let themeItem = themes.filter((item) => {
      return item.tid === tid;
    });

    setSelectedThemeItem({
      label: themeItem[0].label,
      value: themeItem[0].tid,
    });
    setSelectedTheme(parseInt(tid, 10));
  };

  return (
    <Dialog fullScreen={true} open={props.isOpen} onClose={onClose}>
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "primary.light",
          color: "solid.dark",
        }}
      >
        <DialogContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Row className="w-100">
              <Col>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`Create presentation from template`}
                </Typography>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Stack direction="row" spacing={2}>
                      <Button
                        sx={{
                          backgroundColor: "primary.contrastText",
                          width: "130px",
                          height: "25px",
                          textTransform: "none",
                          fontWeight: "400",
                          fontSize: 14,
                        }}
                        variant="outlined"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </Button>
                      {/* <Button
                        sx={{
                          backgroundColor: "primary.confirm",
                          width: "130px",
                          height: "25px",
                          textTransform: "none",
                          fontWeight: "400",
                          fontSize: 14,
                        }}
                        disabled={selectedPresentation ? false : true}
                        variant="contained"
                        onClick={() => {
                          onPresentationSelected();
                        }}
                      >
                        Create
                      </Button> */}
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Toolbar>
        </DialogContent>
      </AppBar>

      <DialogContent sx={{ textAlign: isLoading ? "center" : "" }}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Container fluid className="add-presentation-container">
              {isError && (
                <Row className="mt-4">
                  <Col xs={12} sm={6}>
                    <Alert severity="error">
                      An error occurred. Please try again.
                    </Alert>
                  </Col>
                </Row>
              )}

              {selectedPresentation && selectedPresentation.id ? (
                <div className="ps-modal presentation-settings-modal">
                  <div className="ps-modal-content" style={{ maxWidth: 500 }}>
                    <Row className="mt-4">
                      <Col xs="12 mb-2">
                        <Typography variant="h6">IMPORTANT</Typography>
                      </Col>
                      <Col>
                        <Typography>
                          Please enter a title, a short description and select a brand theme, then click 'Create Presentation'.
                        </Typography>
                        <br />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Box className="ls">
                          <div className="form-group">
                            <label htmlFor="presentation-title">Title*</label>
                            <TextField
                              required
                              id="presentation-title"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={title}
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: 28,
                                  // paddingTop: '11px',
                                  // paddingBottom: '11px'
                                },
                              }}
                              onInput={(event) => {
                                setTitle(event.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="description">Description*</label>
                            <TextField
                              required
                              id="description"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={body}
                              sx={{
                                "& .MuiInputBase-root": {
                                  minHeight: 45,
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                },
                              }}
                              onInput={(event) => {
                                setBody(event.target.value);
                              }}
                              multiline
                              maxRows={3}
                            />
                          </div>
                        </Box>
                      </Col>
                      <Col xs={12}>
                        <Box className="rs">
                          <Box className="form-group">
                            <label htmlFor="presentationThemes">Theme*</label>
                            <CategorySelector
                              name="presentationThemes"
                              label="Theme"
                              categories={themes}
                              selectedCategory={
                                selectedThemeItem?.value === 0
                                  ? {
                                      label: selectedThemeItem?.label,
                                      value: 0,
                                    }
                                  : selectedThemeItem
                              }
                              outlined={true}
                              variant="outlined"
                              size="small"
                              handleCategorySelection={(tid) => {
                                handleThemeSelection(tid);
                              }}
                            />
                          </Box>
                        </Box>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={1}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              backgroundColor: "primary.contrastText",
                              textTransform: "none",
                              marginRight: "10px",
                            }}
                            className="cancel"
                            onClick={() => {
                              setSelectedPresentation(null);
                              setTitle("");
                              setBody("");
                              setSelectedTheme(0);
                              setSelectedThemeItem(setSelectedThemeItem);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            disabled={
                              isLoading ||
                              title === "" ||
                              body === "" ||
                              selectedTheme === 0
                                ? true
                                : false
                            }
                            variant="contained"
                            color="secondary"
                            className="action"
                            sx={{
                              textTransform: "none",
                            }}
                            onClick={() => {
                              onPresentationSelected();
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              `Create Presentation`
                            )}
                          </Button>
                        </Stack>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <>
                  <Row className="mt-4">
                    <Col xs="12 mb-2">
                      <Typography variant="h6">IMPORTANT</Typography>
                    </Col>
                    <Col xs="6">
                      <Typography>
                        Please note: the '
                        <b>Hotel Presentation Starter template</b>' should only
                        be used if you wish to create a presentation to showcase
                        your hotel.
                      </Typography>
                      <br />
                      <Typography>
                        If you want to create a presentation for any other
                        purpose, please return to the presentation list page and
                        choose 'Create presentation >> New blank presentation'
                        instead.
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {presentations.length > 0 ? (
                      <>
                        {presentations.map((presentation) => {
                          return (
                            <Presentation
                              isLibrary={true}
                              key={`presentation-${presentation.id}`}
                              user={props.user}
                              item={presentation}
                              selector
                              onSelected={() => {
                                setSelectedPresentation(presentation);
                              }}
                              isSelected={
                                selectedPresentation?.id === presentation.id
                                  ? true
                                  : false
                              }
                            />
                          );
                        })}
                      </>
                    ) : (
                      <NoData activeIndex={0} />
                    )}
                  </Row>
                </>
              )}
            </Container>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
