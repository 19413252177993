const defaultState = {
  themes: [],
};

export const themesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_THEMES':
      return {
        themes: action.payload,
      };

    default:
      return state;
  }
};