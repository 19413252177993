import React from "react";

import { Box, Typography, IconButton } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Link as ScrollLink } from 'react-scroll'
import CloseIcon from '@mui/icons-material/CloseRounded';

export default function ForumCommentReply(props) {

  const parseHtml = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.innerText
  }

  return (
    <Box className="forum-comment-reply"
      sx={{
        border: "1px solid",
        borderLeft: "3px solid",
        borderColor: 'clientPrimary.main'
      }}
    >
      <Row className="justify-content-between mb-2">
        <Col xs="auto">
          <Row className="gx-0">
            <Col xs="auto">
              <Typography
                className="me-1"
                owner="client"
                variant="body2"
                color="clientPrimary.main !important"
                lh="light"
              >
                {props.item?.field_first_name && props.item?.field_last_name ? `${props.item?.field_first_name} ${props.item?.field_last_name} ` : props.item?.name}
              </Typography>
            </Col>
            <Col xs="auto">
              <Typography
                owner="client"
                variant="body2"
                color="clientText.main"
                lh="light"
              >
                said:
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {props.read &&
            <Typography
              className="forum-comment-reply-read-btn"
              owner="client"
              variant="body2"
              color="clientPrimary.main"
              lh="light"
            >
              <ScrollLink
                className="parent-comment-link"
                to={`topic-comment-${props.item?.cid}`}
                smooth={true}
                duration={500}
                hashSpy={true}
                offset={-74}
              >
                Read comment
              </ScrollLink>
            </Typography>
          }

        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Typography
            owner="client"
            variant="body2"
            weight="light"
            color="clientText.main"
            lh="medium"
          >
            {parseHtml(props.item?.comment_body)}
          </Typography>
        </Col>
      </Row>

      {!props.read &&
        <IconButton
          className="forum-comment-reply-clear-btn"
          onClick={() => props.clearReply()}
          size="small"
        >
          <CloseIcon
            sx={{
              fontSize: 'inherit'
            }}
          />
        </IconButton>
      }
    </Box>
  )
}