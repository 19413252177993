// Dependencies
import React from 'react';

// Modules
import { connect } from 'react-redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// App
import { serverUrl, filesystem_root, subdomain } from "../../config";
import { getOnlineNode } from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import { formatTime } from '../../utils/helpers';
import UserImage from '../../partials/userImage';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Chip, Typography, IconButton } from '@mui/material';
import SinglesHeader from '../../partials/singlesHeader';

import EmailIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/CallOutlined';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

// Dummy data
export default function ContactSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(null);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_pages, setTotal_Pages] = useState(0);
  const [isReactionsLoading, setIsReactionsLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(null);
  const [reactions, setReactions] = useState(
    {
      hasReacted: false,
      likes: 0,
      love: 0,
      suprised: 0,
    },);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams()


  useEffect(() => {
    loadNode()
  }, []);
  useEffect(() => {
    if (post) {
      getCategory()
    }
  }, [post]);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
    getCategory(_data.field_category);
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = 'custom_user/' + nid;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        // if (_error.response) {
        //   setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // }
        // else if (_error.request) {
        //   setError(true, 0, alertMessages.requestError.message);
        //   console.log('this got triggered 1');
        // } else {
        //   setError(true, 0, alertMessages.unkownError.message);
        //   console.log('this got triggered 2');
        // }
      });
  };

  const getCategory = (id) => {
    if (post.field_category) {
      getOnlineNode(
        `api/categories?tid=${post.field_category}`,
        user.access_token,
      )
        .then((response) => {
          let categoryTemp = response.data.length > 0 ? response.data[0] : { title: "" }
          setCategoryName(categoryTemp.title)
        })
        .catch((_error) => {
          if (_error.response) {
            setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            //console.log('@rest request: ', _error.request);
            setError(true, 0, alertMessages.requestError.message);
          } else {
            //console.log('@rest unknown: ', _error);
            setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  }

  const formatName = (item) => {
    let name
    if (item.field_first_name && (item.field_first_name !== "")) {
      name = `${item.field_first_name} ${item.field_last_name}`;
    }
    else {
      name = item.name;
    }
    return name
  }

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {
      const timestamp = formatTime(post.created);

      let category = 'Uncategorised';

      if (categoryName) {
        category = categoryName;
      }

      if (post.categoryLabel) {
        category = post.categoryLabel;
      }

      return (
        <main className="contacts  single">
          <article className="article">
            <SinglesHeader 
              route={"/contacts"}
              backBtnText={"User Directory"}
              category={category}
            />
            <div className="contact-page-body">
              <Row className="information-container justify-content-center">
                <Col
                  xs={6}
                  sm={4}
                  className="avatar-container mb-4 mb-sm-0"
                >
                  <UserImage className="ratio ratio-1x1"
                    name={formatName(post)}
                    image={post.user_picture}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={8}
                  className="contact-info-container ps-5"
                >
                  <Row className='mb-3'>
                    <Col>
                      <PostTitle
                        title={formatName(post)}
                        headingLevel={'h1'}
                        titleColor="clientText.dark"
                      />
                      <div className="sub-title position mt-2 mb-3">
                        <Typography
                          variant="h4"
                          owner="client"
                          color="clientText.main"
                        >
                          {post.field_job_title}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col>
                      <Typography
                        variant='h5'
                        owner="client"
                        color="clientText.dark"
                      >
                        Email
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>
                      <Row className='d-flex align-items-center gx-0'>
                        <Col xs={"auto"}>
                          <IconButton
                            size="small"
                            color="clientPrimary"
                            variant="contained"
                          >
                            <EmailIcon
                              onClick={() => { window.location.href = `mailto:${encodeURIComponent(post.mail)}` }}
                            />
                          </IconButton>
                        </Col>
                        <Col xs={"auto"} className='ps-2'>
                          <Typography
                            variant="body1"
                            textTransform="lowercase"
                            color="clientText.secondary"
                          >
                            <a href={`mailto:${encodeURIComponent(post.mail)}`} className="contact-mail-link">
                              {post.mail}
                            </a>
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {(post.phone_number !== "") &&
                    <>
                      <Row className='mb-2'>
                        <Col>
                          <Typography
                            variant='h5'
                            owner="client"
                            color="clientText.dark"
                          >
                            Phone
                          </Typography>
                        </Col>
                      </Row>
                      <Row className='mb-4'>
                        <Col>
                          <Row className='d-flex align-items-center gx-0'>
                            <Col xs={"auto"}>
                              <IconButton
                                size="small"
                                color="clientPrimary"
                                variant="contained"
                                sx={{
                                  fontSize: "16px"
                                }}
                              >
                                <CallIcon
                                  onClick={() => { window.location.href = `tel:${encodeURIComponent(post.phone_number)}` }}
                                />
                              </IconButton>
                            </Col>
                            <Col xs={"auto"} className='ps-2'>
                              <Typography
                                variant="body1"
                                textTransform="lowercase"
                                color="clientText.secondary"
                              >
                                <a href={`tel:${encodeURIComponent(post.phone_number)}`} className="contact-phone-link">
                                  {post.phone_number}
                                </a>
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  }
                </Col>
              </Row>
            </div>
          </article>
        </main>
      );
    }
  }
}

