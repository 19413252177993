const defaultState = {
  app: '',
  macos: '',
  outlook: '',
  training: '',
  windows: '',
};

export const contentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_CONTENT':
      return action.payload;

    default:
      return state;
  }
};