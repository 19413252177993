import React, { useState, useCallback, useRef, useEffect } from "react";

// Modules
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { NestedMenuItem } from "mui-nested-menu";
import { useSelector, useDispatch } from "react-redux";

// App
import { getOnlineNode } from "../../../core/getNode";
import CreatePresentation from "./createPresentation";
import { decodeHtmlEntities } from "../../../utils/helpers";
import { favoritesEnabled } from "../../../config";

// UI
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Autocomplete,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Input,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Stack,
  Switch,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { SearchOutlined, ChevronLeft } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as ItemCount } from "../../../assets/item-count.svg";
import BookmarkIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkedIcon from "@mui/icons-material/Bookmark";

const input_font_size = 14;
const input_variant = "outlined";

export default function PresentationFilters(props) {
  /** redux */
  const filtersReducer = useSelector((state) => state).filtersReducer;
  const dispatch = useDispatch();
  const currentLocation = useSelector(
    (state) => state.locationReducer.currentLocation
  );

  const statuses = [
    {
      value: "published",
      label: "Published",
    },
    {
      value: "modified",
      label: "Modified",
    },
    {
      value: "unpublished",
      label: "Draft",
    },
  ];

  const libraries = [
    {
      value: 0,
      label: "All Libraries",
    },
    {
      value: "user_library",
      label: "My Library",
    },
    {
      value: "shared_library",
      label: "Shared Library",
    },
    {
      value: "core_library",
      label: "Corporate Library",
    },
  ];

  const [authors, setAuthors] = useState([]);
  const [title, setTitle] = useState(
    props.redux
      ? filtersReducer.title
      : props.filters?.title
      ? props.filters.title
      : ""
  );
  const [language, setLanguage] = useState(
    props.redux
      ? filtersReducer.language
      : props.filters?.language
      ? props.filters.language
      : 0
  );
  const [category, setCategory] = useState(
    props.redux
      ? filtersReducer.category
      : props.filters?.category
      ? props.filters.category
      : 0
  );
  const [theme, setTheme] = useState(
    props.redux
      ? filtersReducer.theme
      : props.filters?.theme
      ? props.filters.theme
      : 0
  );

  const [idSita, setIdSita] = useState(
    props.redux
      ? filtersReducer.idSita
      : props.filters?.idSita
      ? props.filters.idSita
      : ""
  );
  const [author, setAuthor] = useState(
    props.redux
      ? filtersReducer.author
      : props.filters?.author
      ? props.filters.author
      : {
          label: "",
          value: "",
        }
  );
  const [library, setLibrary] = useState(
    props.redux
      ? filtersReducer.library
      : props.filters?.library
      ? props.filters.library
      : 0
  );
  const [status, setStatus] = useState(
    props.redux
      ? filtersReducer.status
      : props.filters?.status
      ? props.filters.status
      : [1, 2, 3]
  );

  // refs
  // making sure debounce callback always uses the latest vars
  const titleRef = useRef(title);
  titleRef.current = title;

  const categoryRef = useRef(category);
  categoryRef.current = category;

  const languageRef = useRef(language);
  languageRef.current = language;

  const themeRef = useRef(theme);
  themeRef.current = theme;

  const idSitaRef = useRef(idSita);
  idSitaRef.current = idSita;

  const authorRef = useRef(author);
  authorRef.current = author;

  const libraryRef = useRef(library);
  libraryRef.current = library;

  const statusRef = useRef(status);
  statusRef.current = status;

  // category select
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const handleTitle = (event) => {
    setTitle(event.target.value);
    debouncedOnApply();
  };

  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleCategory = (value) => {
    categoryRef.current = value;

    setCategory(value);
    setOpen(false);
    onApply();
  };

  const handleTheme = (event) => {
    setTheme(event.target.value);
  };

  const handleStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnApply = useCallback(
    debounce(() => onApply(), 1500, true),
    []
  );

  const getUsers = () => {
    getOnlineNode("users")
      .then((response) => {
        let users = [];
        response.data.forEach((item) => {
          users.push({
            value: item.name,
            label: item.name,
          });
        });

        setAuthors(users);
      })
      .catch(console.error);
  };

  const onApply = () => {
    const filters = {
      title: titleRef.current,
      language: languageRef.current,
      category: categoryRef.current,
      author: authorRef.current,
      idSita: idSitaRef.current,
      status: statusRef.current,
      library: libraryRef.current,
      theme: themeRef.current,
      currentPage: 1,
    };

    if (props.redux) {
      dispatch({ type: "UPDATE_FILTERS", payload: filters });
    }

    if (props.onApply) {
      props.onApply(filters);
    }
  };

  const onReset = () => {
    setTitle("");
    setLanguage(0);
    setCategory(0);
    setTheme(0);
    setIdSita("");
    setAuthor("");
    setLibrary(0);
    setStatus(["published", "modified", "unpublished"]);

    const filters = {
      title: "",
      language: 0,
      category: 0,
      author: "",
      idSita: "",
      status: ["published", "modified", "unpublished"],
      library: 0,
      theme: 0,
      currentPage: 1,
    };

    if (props.redux) {
      dispatch({ type: "RESET_FILTERS" });
    }

    if (props.onApply) {
      props.onApply(filters);
    }
  };

  const hasChildren = (id) => {
    if (props.categories) {
      const nested = props.categories.filter(
        (item) => item.parent_target_id === id
      );

      if (nested.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getChildItems = (arr, id) => {
    return arr.filter((item) => item.parent_target_id === id);
  };

  const menuProps = {
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    PaperProps: { style: { pointerEvents: "auto" } },
  };

  const renderFavorites = () => {
    return (
      <Col
        xs={"auto"}
        className="d-flex align-items-center justify-content-end flex-grow-1 pe-1"
      >
        <Button
          className={`p-0 d-flex align-items-center`}
          // color={props.pageNameColor}
          disableFocusRipple={true}
          disableRipple={true}
          onClick={() => {
            if (props.getFavourites) {
              props.getFavourites();
            }
          }}
          endIcon={
            props.activeTabIndex === 1 ? (
              <BookmarkedIcon className="ms-1 me-0" sx={{ fontSize: "1rem" }} />
            ) : (
              <BookmarkIcon className="ms-1 me-0" sx={{ fontSize: "1rem" }} />
            )
          }
          sx={{
            "& .MuiButton-endIcon": {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
        >
          <Typography
            variant="overline"
            textTransform="none"
            weight="regular"
            owner="admin"
            lh="medium"
            sx={{
              letterSpacing: 0,
              color: "#16324f",
            }}
          >
            Favourites
          </Typography>
        </Button>
      </Col>
    );
  };

  return (
    <div className="ps-filters">
      <div className={`page-header ${props.selector ? "mb-3" : ""}`}>
        <Row>
          <Col sm={12} md={12} lg={8} className="title-container mb-3 mb-lg-0">
            <Row className="align-items-end">
              <Col xs="auto">
                {props.pageTitle && (
                  <Typography
                    variant="h1"
                    owner={props.switchStatus ? "admin" : "client"}
                    color="clientText.dark"
                    className="mb-0"
                  >
                    {props.pageTitle}
                  </Typography>
                )}
              </Col>
              <Col xs="auto">
                {props.toggleSwitch && (
                  <Link color="primary" to={props.switchLink}>
                    <FormControlLabel
                      className="presentations-edit-labels toggle-switch-control-root"
                      variant="primary"
                      label="Edit Mode"
                      control={
                        <Switch
                          id="edit-switch"
                          defaultChecked={props.switchStatus}
                        />
                      }
                    />
                  </Link>
                )}
              </Col>
              <Col xs="auto">
                {props.createPresentation && (
                  <CreatePresentation
                    user={props.user}
                    onUpdate={props.onUpdate}
                  />
                )}
              </Col>
              {/* {favoritesEnabled && renderFavorites()} */}
              {currentLocation !== "presentations" && renderFavorites()}
              {props.itemCounter && (
                <Col xs={"auto"} className="d-none d-lg-block">
                  <Row className="d-flex align-items-center item-counter-container">
                    <ItemCount className="item-counter-logo" />
                    <Typography className="item-counter">
                      {props.totalItems > props.pageSize
                        ? (props.currentPage - 1) * props.pageSize + 1
                        : 1}{" "}
                      -{" "}
                      {props.totalItems > props.pageSize
                        ? (props.currentPage - 1) * props.pageSize +
                          props.data.length
                        : props.data.length}{" "}
                      of {props.totalItems}
                    </Typography>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col sm={12} md={12} lg={4} className="general-filters">
            <Stack
              className="h-100"
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <FormControl fullWidth size="small">
                <TextField
                  id="title"
                  label="Search Title"
                  className="title-textfield"
                  variant="standard"
                  value={title}
                  size="small"
                  placeholder="Search title"
                  onChange={(event) => {
                    handleTitle(event);
                  }}
                  InputProps={{
                    style: { fontSize: input_font_size },
                    endAdornment: (
                      <InputAdornment position="end">
                        {title.length > 0 ? (
                          <IconButton
                            className="title-clear-btn"
                            onClick={() => {
                              setTitle("");
                              debouncedOnApply();
                            }}
                          >
                            <ClearIcon className="title-adornment-icon" />
                          </IconButton>
                        ) : (
                          <SearchOutlined className="title-adornment-icon search" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ style: { fontSize: input_font_size } }}
                />
              </FormControl>

              <FormControl variant="standard" fullWidth size="small">
                <InputLabel
                  sx={{ fontSize: input_font_size }}
                  size="small"
                  id="category-select-label"
                >
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category}
                  label="Category"
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  input={
                    <Input
                      sx={{
                        fontSize: input_font_size,
                        "&.MuiInput-underline:before": {
                          borderBottomColor: "#d2d0cf",
                        },
                      }}
                      label="Category"
                    />
                  }
                >
                  {props?.categories?.map((category, index) => {
                    const has_child = hasChildren(category.value);
                    const is_top_level = category.parent_target_id
                      ? false
                      : true;

                    if (is_top_level) {
                      if (has_child) {
                        const region_child_items = getChildItems(
                          props.categories,
                          category.value
                        );

                        return (
                          <NestedMenuItem
                            key={`category-${index}`}
                            leftIcon={<ChevronLeft />}
                            rightIcon={null}
                            parentMenuOpen={open}
                            label={category.label}
                            MenuProps={menuProps}
                            className="presentation-category-nested"
                          >
                            {region_child_items.map((region) => {
                              const countries = getChildItems(
                                props.categories,
                                region.value
                              );
                              let does_child_have_content = false;

                              countries.forEach((country) => {
                                if (country.has_content) {
                                  does_child_have_content = true;
                                }

                                countries.map((country) => {
                                  const cities = getChildItems(
                                    props.categories,
                                    country.value
                                  );
                                  cities.forEach((city) => {
                                    if (city.has_content) {
                                      does_child_have_content = true;
                                    }
                                  });
                                });
                              });

                              if (!does_child_have_content) {
                                return null;
                              }

                              return (
                                <NestedMenuItem
                                  key={`region-${region.value}`}
                                  leftIcon={<ChevronLeft />}
                                  rightIcon={null}
                                  parentMenuOpen={open}
                                  label={region.label}
                                  MenuProps={menuProps}
                                  className="presentation-category-nested"
                                  onClick={() => {
                                    handleCategory(region.value);
                                  }}
                                >
                                  {countries.map((country) => {
                                    const cities = getChildItems(
                                      props.categories,
                                      country.value
                                    );
                                    let does_child_have_content = false;

                                    cities.forEach((city) => {
                                      if (city.has_content) {
                                        does_child_have_content = true;
                                      }
                                    });

                                    if (!does_child_have_content) {
                                      return null;
                                    }

                                    return (
                                      <NestedMenuItem
                                        key={`country-${country.value}`}
                                        leftIcon={<ChevronLeft />}
                                        rightIcon={null}
                                        parentMenuOpen={open}
                                        label={country.label}
                                        MenuProps={menuProps}
                                        className="presentation-category-nested"
                                        onClick={() => {
                                          handleCategory(country.value);
                                        }}
                                      >
                                        {cities.map((city, cityIndex) => {
                                          if (!city.has_content) {
                                            return null;
                                          }

                                          return (
                                            <MenuItem
                                              key={`city-${city.value}`}
                                              value={city.value}
                                              sx={{ fontSize: input_font_size }}
                                              onClick={() => {
                                                handleCategory(city.value);
                                              }}
                                              className="presentation-category"
                                            >
                                              {decodeHtmlEntities(city.label)}
                                            </MenuItem>
                                          );
                                        })}
                                      </NestedMenuItem>
                                    );
                                  })}
                                </NestedMenuItem>
                              );
                            })}
                          </NestedMenuItem>
                        );
                      } else {
                        if (!category.has_content) {
                          return null;
                        }

                        return (
                          <MenuItem
                            key={`category-${category.value}`}
                            value={category.value}
                            sx={{ fontSize: input_font_size }}
                            onClick={() => {
                              handleCategory(category.value);
                            }}
                            className="presentation-category"
                          >
                            {decodeHtmlEntities(category.label)}
                          </MenuItem>
                        );
                      }
                    } else {
                      if (category.has_content) {
                        return (
                          <MenuItem
                            key={`category-${category.value}`}
                            value={category.value}
                            sx={{ display: "none" }}
                            onClick={() => {
                              handleCategory(category.value);
                            }}
                            className="presentation-category"
                          >
                            {decodeHtmlEntities(category.label)}
                          </MenuItem>
                        );
                      }
                    }
                  })}
                  {props?.categories?.map((category, index) => {
                    // only render items that have children to avoid out-of-range error and have better performance
                    const has_child = hasChildren(category.value);

                    if (has_child) {
                      return (
                        <MenuItem
                          key={`hidden-category-${index}`}
                          value={category.value}
                          sx={{ display: "none" }}
                          aria-hidden="true"
                        >
                          {category.label}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Col>
        </Row>
      </div>

      {props.advanced && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onApply();
          }}
        >
          <Row className="justify-content-center">
            <Col xs={12}>
              <Row className="filter-container w-100 align-items-end justify-content-center">
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3"
                >
                  <FormControl fullWidth size="small">
                    <TextField
                      id="id"
                      label="ID / SITA"
                      variant={input_variant}
                      value={idSita}
                      size="small"
                      placeholder="ID or SITA"
                      onChange={(event) => {
                        setIdSita(event.target.value);
                      }}
                      InputProps={{
                        style: { fontSize: input_font_size },
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchOutlined
                              sx={{
                                width: "20px",
                                height: "18px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ style: { fontSize: input_font_size } }}
                      owner="admin"
                      sub_variant="presentation-filter"
                    />
                  </FormControl>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3"
                >
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      ListboxProps={{
                        sx: { fontSize: input_font_size },
                      }}
                      options={authors}
                      getOptionLabel={(option) =>
                        option?.label ? option?.label : ""
                      }
                      value={author ? author : ""}
                      disableClearable={true}
                      size="small"
                      freeSolo
                      onChange={(event, value) => {
                        if (value !== null) {
                          setAuthor(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Author"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: input_font_size },
                            endAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlined
                                  sx={{
                                    width: "20px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            style: { fontSize: input_font_size },
                          }}
                          owner="admin"
                          sub_variant="presentation-filter"
                        />
                      )}
                    />
                  </FormControl>
                </Col>

                {props.hideStatus !== true && (
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={props.isAddLibrary ? 2 : ""}
                    className="filter-item mb-3"
                  >
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "primary.light" }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: input_font_size,
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                        size="small"
                        id="status-select-label"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={status}
                        multiple
                        label="Status"
                        onChange={handleStatus}
                        renderValue={(selected) => selected.join(", ")}
                        input={
                          input_variant === "outlined" ? (
                            <OutlinedInput
                              owner="admin"
                              sub_variant="presentation-filter"
                              sx={{
                                fontSize: input_font_size,
                              }}
                              label="Status"
                            />
                          ) : (
                            <Input
                              sx={{
                                fontSize: input_font_size,
                                color: "red",
                              }}
                              label="Status"
                            />
                          )
                        }
                      >
                        {statuses.map((state, index) => (
                          <MenuItem key={`status-${index}`} value={state.value}>
                            <Checkbox
                              size="small"
                              checked={status.indexOf(state.value) > -1}
                              sx={{ marginLeft: "-10px", padding: "5px" }}
                            />
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  style={{ fontSize: input_font_size }}
                                >
                                  {state.label}
                                </Typography>
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                )}

                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ backgroundColor: "primary.light" }}
                  >
                    <InputLabel
                      sx={{ fontSize: input_font_size, color: "primary.main" }}
                      size="small"
                      id="library-select-label"
                    >
                      Library
                    </InputLabel>
                    <Select
                      labelId="library-select-label"
                      id="library-select"
                      value={library}
                      label="Library"
                      onChange={(event) => {
                        setLibrary(event.target.value);
                      }}
                      input={
                        input_variant === "outlined" ? (
                          <OutlinedInput
                            owner="admin"
                            sub_variant="presentation-filter"
                            sx={{
                              fontSize: input_font_size,
                            }}
                            label="Library"
                          />
                        ) : (
                          <Input
                            sx={{ fontSize: input_font_size }}
                            label="Library"
                          />
                        )
                      }
                    >
                      {libraries.map((library, index) => (
                        <MenuItem
                          key={`library-${index}`}
                          value={library.value}
                          sx={{ fontSize: input_font_size }}
                        >
                          {library.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ backgroundColor: "primary.light" }}
                  >
                    <InputLabel
                      sx={{ fontSize: input_font_size, color: "primary.main" }}
                      size="small"
                      id="language-select-label"
                    >
                      Language
                    </InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={language}
                      label="Language"
                      variant={input_variant}
                      onChange={(event) => {
                        handleLanguage(event);
                      }}
                      input={
                        input_variant === "outlined" ? (
                          <OutlinedInput
                            owner="admin"
                            sub_variant="presentation-filter"
                            sx={{
                              fontSize: input_font_size,
                            }}
                            label="Language"
                          />
                        ) : (
                          <Input
                            sx={{ fontSize: input_font_size }}
                            label="Language"
                          />
                        )
                      }
                    >
                      {props?.languages?.map((language, index) => (
                        <MenuItem
                          key={`language-${index}`}
                          value={language.value}
                          sx={{ fontSize: input_font_size }}
                        >
                          {language.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ backgroundColor: "primary.light" }}
                  >
                    <InputLabel
                      sx={{ fontSize: input_font_size, color: "primary.main" }}
                      size="small"
                      id="theme-select-label"
                    >
                      Theme
                    </InputLabel>
                    <Select
                      labelId="theme-select-label"
                      id="theme-select"
                      value={theme}
                      label="Theme"
                      variant={input_variant}
                      onChange={(event) => {
                        handleTheme(event);
                      }}
                      input={
                        input_variant === "outlined" ? (
                          <OutlinedInput
                            owner="admin"
                            sub_variant="presentation-filter"
                            sx={{
                              fontSize: input_font_size,
                            }}
                            label="Theme"
                          />
                        ) : (
                          <Input
                            sx={{ fontSize: input_font_size }}
                            label="Theme"
                          />
                        )
                      }
                    >
                      {props?.themes?.map((theme, index) => (
                        <MenuItem
                          key={`theme-${index}`}
                          value={
                            theme.value
                              ? theme.value
                              : theme.tid
                              ? parseInt(theme.tid)
                              : 0
                          }
                          sx={{ fontSize: input_font_size }}
                        >
                          {decodeHtmlEntities(
                            theme.label ? theme.label : theme.title
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={props.isAddLibrary ? 2 : ""}
                  className="filter-item mb-3 align-self-end"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <FormControl className="btn" fullWidth>
                      <Button variant="outlined" onClick={onReset}>
                        <Typography
                          owner="admin"
                          variant="button"
                          className="lh"
                        >
                          Reset
                        </Typography>
                      </Button>
                    </FormControl>
                    <FormControl className="btn" fullWidth>
                      <Button
                        type="submit"
                        sx={{ fontSize: input_font_size }}
                        variant="contained"
                        onClick={onApply}
                      >
                        <Typography
                          owner="admin"
                          variant="button"
                          className="lh"
                        >
                          Apply
                        </Typography>
                      </Button>
                    </FormControl>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
}
