import React from "react"
import { Row, Col } from "react-bootstrap"
import { Typography, Checkbox } from "@mui/material"

export default function HotelCapacityOptions(props) {
  const { capacityTags, setCapacityTags, transparentFocus, transparentSettings } = props
  const capacityTagCheckbox = (value, label, stateKey) => {
    let checkCount = Object.values(capacityTags).filter((value) => value === true).length;
    return (
      <Col xs={"auto"} className="flex-fill d-flex flex-column align-items-center justify-content-between">
        <Row>
          <Typography
            className="text-center"
            owner="admin"
            variant="button"
            weight="regular"
            color="adminText.main"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Row>
        <Row>
          <Checkbox
            className="px-0 pb-0 pt-2"
            variant="postCheckBox"
            size="small"
            checked={value}
            onChange={() => setCapacityTags(stateKey)}
            disabled={(checkCount === 4 && !capacityTags[stateKey]) || (checkCount === 2 && capacityTags[stateKey])}
          />
        </Row>
      </Col>
    )
  }
  return (
    <>
      {capacityTags && (
        <Col xs={12}>
          <Row
            className={`mb-3 py-1 ${transparentFocus === "content-width"
              ? "transparent-focus"
              : transparentSettings
                ? "transparent-opacity"
                : ""
              }`}
          >
            <Col className="d-flex align-items-center" xs={2}>
              <Typography 
              className="pt-1"
              variant="form-group-label" 
              owner="admin"
              lh="wide"
              >
                Info bar options <br /> (Min 2, Max 4)
              </Typography>
            </Col>
            <Col xs={10}>
              <Row>
                {capacityTagCheckbox(capacityTags.guestRooms, 'Guest<br />rooms', 'guestRooms')}
                {capacityTagCheckbox(capacityTags.meetingRooms, 'Meeting<br />rooms', 'meetingRooms')}
                {capacityTagCheckbox(capacityTags.maxCap, 'Max<br />capacity', 'maxCap')}
                {capacityTagCheckbox(capacityTags.exhibitSpace, 'Exhibition<br />space', 'exhibitSpace')}
                {capacityTagCheckbox(capacityTags.suites, 'Suites', 'suites')}
                {capacityTagCheckbox(capacityTags.familyRooms, 'Family<br />rooms', 'familyRooms')}
                {capacityTagCheckbox(capacityTags.restaurants, 'Restaurants', 'restaurants')}
                {capacityTagCheckbox(capacityTags.bars, 'Bars', 'bars')}
                {capacityTagCheckbox(capacityTags.spa, 'Spa', 'spa')}
                {capacityTagCheckbox(capacityTags.gym, 'Gym', 'gym')}
                {capacityTagCheckbox(capacityTags.pool, 'Pool', 'pool')}
              </Row>
            </Col>
          </Row>
        </Col>)}
    </>
  )
}