import { UnlockIcon, LockIcon } from '../../../icons/icons';

export default class DragResizeTune {
  static get isTune() {
    return true;
  }

  constructor({ api, data, config, block }) {
    this.api = api;
    this.block = block;
    this.settings = config;
    this.data = data || {
      draggable: {
        absolute: true,
        width: 433,
        height: 200,
        top: 284,
        left: 433,
        locked: false
      }
    };
    this.locked = data?.draggable.locked || false;
    this.draggableWrapper = null;
  }

  wrap(blockContent) {
    const draggableWrapper = document.createElement('div');
    const controlWrapper = document.createElement('div');
    const draggableContent = document.createElement('div');

    if (this.locked) {
      draggableWrapper.classList.add('locked');
    }

    this.draggableWrapper = draggableWrapper;

    draggableWrapper.classList.add('draggable-wrapper');
    controlWrapper.classList.add('draggable-controls');
    draggableContent.classList.add('draggable-content');


    const dragElement = document.createElement('div');
    const rightWidthElement = document.createElement('div');
    const leftWidthElement = document.createElement('div');
    const bottomHeightElement = document.createElement('div');
    const topHeightElement = document.createElement('div');

    const topLeftElement = document.createElement('div');
    const topRightElement = document.createElement('div');
    const bottomRightElement = document.createElement('div');
    const bottomLeftElement = document.createElement('div');

    let isDragging = false;
    let isRightWidthResize = false;
    let isLeftWidthResize = false;
    let isBottomHeightResize = false;
    let isTopHeightResize = false;
    let startX, startY;
    let offsetX, offsetY;
    let height, width;
    let target;
    let absolute = false;
    let modified = false;
    let tuneTrigger = false;
    let snapToGrid = false;
    let dragTimer = null;
    let slide;
    let editToolbar;
    const gridSize = 8;

    dragElement.classList.add('block-drag-handle');
    leftWidthElement.classList.add('block-resize-width-handle-left', 'edge-handle');
    rightWidthElement.classList.add('block-resize-width-handle-right', 'edge-handle');
    topHeightElement.classList.add('block-resize-height-handle-top', 'edge-handle');
    bottomHeightElement.classList.add('block-resize-height-handle-bottom', 'edge-handle');

    topLeftElement.classList.add('block-resize-top-left-handle', 'corner-handle');
    topRightElement.classList.add('block-resize-top-right-handle', 'corner-handle');
    bottomRightElement.classList.add('block-resize-bottom-right-handle', 'corner-handle');
    bottomLeftElement.classList.add('block-resize-bottom-left-handle', 'corner-handle');

    dragElement.setAttribute("contenteditable", "false");
    leftWidthElement.setAttribute("contenteditable", "false");
    rightWidthElement.setAttribute("contenteditable", "false");
    topHeightElement.setAttribute("contenteditable", "false");
    bottomHeightElement.setAttribute("contenteditable", "false");
    topLeftElement.setAttribute("contenteditable", "false");
    topRightElement.setAttribute("contenteditable", "false");
    bottomRightElement.setAttribute("contenteditable", "false");
    bottomLeftElement.setAttribute("contenteditable", "false");

    dragElement.innerHTML = "<svg class=\"block-drag-handle-svg\" xmlns=\"http://www.w3.org/2000/svg\" height=\"24px\" viewBox=\"0 -960 960 960\" width=\"24px\" fill=\"#e8eaed\"><path d=\"M480-80 310-250l57-57 73 73v-206H235l73 72-58 58L80-480l169-169 57 57-72 72h206v-206l-73 73-57-57 170-170 170 170-57 57-73-73v206h205l-73-72 58-58 170 170-170 170-57-57 73-73H520v205l72-73 58 58L480-80Z\"/></svg>";


    draggableContent.setAttribute("tabindex", "0");


    //For external size modification
    const debounce = (func, timeout = 300) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }

    const debouncedFunction = debounce(() => {
      this.data.draggable = {
        absolute: absolute,
        width: target.offsetWidth,
        height: target.offsetHeight,
        top: target.style.top,
        left: target.style.left
      }
      this.block.dispatchChange();
    });

    const resizeObserver = new ResizeObserver(() => {
      if (!tuneTrigger) {
        debouncedFunction();
      }
    });

    // Set position and dimensions on load
    setTimeout(() => {
      target = draggableWrapper.closest('.ce-block');
      slide = draggableWrapper.closest('.slide');

      resizeObserver.observe(target);

      if (this.data.draggable) {
        if (target) {
          let style = { ...this.data.draggable };
          if (style.absolute) {
            absolute = true;
            target.style.position = "absolute";
            target.style.top = style.top;
            target.style.left = style.left;
          }
          if (style.width) {
            target.style.width = `${style.width}px`;
          }
          if (style.height) {
            target.style.height = `${style.height}px`;
          }
        }
      }
    }, 10);


    dragElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      isDragging = true;
      setDimensions();
      startX = e.clientX;
      startY = e.clientY;
      offsetX = e.clientX - target.offsetLeft;
      offsetY = e.clientY - target.offsetTop;
      addDocumentListeners();
    });

    rightWidthElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startX = e.clientX;
      isRightWidthResize = true;
      addDocumentListeners();
    });

    leftWidthElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startX = e.clientX;
      offsetX = e.clientX - target.offsetLeft;
      isLeftWidthResize = true;
      addDocumentListeners();
    });

    topHeightElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startY = e.clientY;
      offsetY = e.clientY - target.offsetTop;
      isTopHeightResize = true;
      addDocumentListeners();
    });

    bottomHeightElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startY = e.clientY;
      isBottomHeightResize = true;
      addDocumentListeners();
    });

    topLeftElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startX = e.clientX;
      startY = e.clientY;
      offsetX = e.clientX - target.offsetLeft;
      offsetY = e.clientY - target.offsetTop;
      isLeftWidthResize = true;
      isTopHeightResize = true;
      addDocumentListeners();
    });

    topRightElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startY = e.clientY;
      startX = e.clientX;
      offsetY = e.clientY - target.offsetTop;
      isTopHeightResize = true;
      isRightWidthResize = true;
      addDocumentListeners();
    });

    bottomRightElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startY = e.clientY;
      startX = e.clientX;
      isRightWidthResize = true;
      isBottomHeightResize = true;
      addDocumentListeners();
    });

    bottomLeftElement.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      setDimensions();
      startY = e.clientY;
      startX = e.clientX;
      offsetX = e.clientX - target.offsetLeft;
      isLeftWidthResize = true;
      isBottomHeightResize = true;
      addDocumentListeners();
    });


    // Mouse move operations
    const mouseMove = (e) => {
      modified = true;
      if (isDragging) {
        // dragTimer = setTimeout(() => {
        let leftValue = snapToGrid ? Math.round((e.clientX - offsetX) / gridSize) * gridSize : e.clientX - offsetX
        let topValue = snapToGrid ? Math.round((e.clientY - offsetY) / gridSize) * gridSize : e.clientY - offsetY
        absolute = true;
        target.style.position = "absolute";
        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
        target.style.left = `${leftValue}px`;
        target.style.top = `${topValue}px`;
        // }, 10);
      }
      if (isRightWidthResize) {
        let widthValue = snapToGrid ? (Math.round((width + (e.clientX - startX)) / gridSize) * gridSize) : width + (e.clientX - startX);
        target.style.width = `${widthValue}px`;
      }
      if (isLeftWidthResize) {
        let widthValue = snapToGrid ? (Math.round((width - (e.clientX - startX)) / gridSize) * gridSize) : width - (e.clientX - startX);
        let leftValue = snapToGrid ? Math.round((e.clientX - offsetX) / gridSize) * gridSize : e.clientX - offsetX
        absolute = true;
        target.style.position = "absolute";
        target.style.left = `${leftValue}px`;
        target.style.width = `${widthValue}px`
      }
      if (isBottomHeightResize) {
        let heightValue = snapToGrid ? Math.round((height + (e.clientY - startY)) / gridSize) * gridSize : height + (e.clientY - startY);
        target.style.height = `${heightValue}px`
      }
      if (isTopHeightResize) {
        let heightValue = snapToGrid ? Math.round((height - (e.clientY - startY)) / gridSize) * gridSize : height - (e.clientY - startY);
        let topValue = snapToGrid ? Math.round((e.clientY - offsetY) / gridSize) * gridSize : e.clientY - offsetY;
        absolute = true;
        target.style.position = "absolute";
        target.style.top = `${topValue}px`;
        target.style.height = `${heightValue}px`
      }
      initSave();
    }
    // Listener cleanup
    const mouseUp = (e) => {
      if (isDragging && checkDelta(e)) {
        dragHide();
      }
      clearTimeout(dragTimer);
      isDragging = false;
      isRightWidthResize = false;
      isLeftWidthResize = false;
      isBottomHeightResize = false;
      isTopHeightResize = false;
      snapToGrid = false;
      tuneTrigger = false;
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
      handleGrid(false);
      initSave();
    }

    const dragHide = () => {
      target = draggableWrapper.closest('.ce-block');
      isDragging = false;
      if (target) {
        dragElement.style.display = 'none';
        target.setAttribute('tabindex', '0');
        let contentEditableTag = draggableContent.querySelector('[contenteditable="true"');
        if (contentEditableTag) {
          contentEditableTag.focus();
        }
        else {
          target.focus();
        }

        target.addEventListener('focusout', handleFocusOut);
      }
    }

    const handleFocusOut = (event) => {
      if (!target.contains(event.relatedTarget)) {
        dragElement.style.display = 'flex';
        target.removeEventListener('focusout', handleFocusOut);
      }
    }

    const checkDelta = (e) => {
      const delta = 6;
      if (startX && startY && e) {
        const diffX = Math.abs(e.clientX - startX);
        const diffY = Math.abs(e.clientY - startY);
        if (diffX < delta && diffY < delta) {
          return true;
        }
        else return false;
      }
    }

    const setDimensions = () => {
      tuneTrigger = true;
      width = target.offsetWidth;
      height = target.offsetHeight;
    }


    const addDocumentListeners = () => {
      if (!this.locked) {
        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', mouseUp);
        document.addEventListener('keydown', keyDown);
        document.addEventListener('keyup', keyUp);
      }
    }

    const keyDown = (e) => {
      if (e.key === 'shift' || e.key === 'Shift') {
        if (!snapToGrid) {
          snapToGrid = true;
          handleGrid(true);
        }
      }
    }

    const keyUp = () => {
      if (snapToGrid) {
        snapToGrid = false;
        handleGrid(false);
      }
    }

    const handleGrid = (_snapToGrid) => {
      let innerContainer = draggableWrapper.closest('.inner-container');
      if (innerContainer) {
        if (_snapToGrid) {
          if (!innerContainer.classList.contains('grid')) {
            innerContainer.classList.add('grid');
          }
        } else {
          if (innerContainer.classList.contains('grid')) {
            innerContainer.classList.remove('grid');
          }
        }
      }
    }

    const initSave = () => {
      if (modified) {
        this.data.draggable = {
          absolute: absolute,
          width: target.offsetWidth,
          height: target.offsetHeight,
          top: target.style.top,
          left: target.style.left,
          locked: this.locked,
        }
        modified = false;
      }
      this.block.dispatchChange();
    }

    controlWrapper.appendChild(dragElement);
    controlWrapper.appendChild(leftWidthElement);
    controlWrapper.appendChild(rightWidthElement);
    controlWrapper.appendChild(topHeightElement);
    controlWrapper.appendChild(bottomHeightElement);
    controlWrapper.appendChild(topLeftElement);
    controlWrapper.appendChild(topRightElement);
    controlWrapper.appendChild(bottomRightElement);
    controlWrapper.appendChild(bottomLeftElement);
    draggableContent.append(blockContent);
    draggableWrapper.append(draggableContent);
    draggableWrapper.append(controlWrapper);

    return draggableWrapper;
  }

  lockSwitch(_locked) {
    if (_locked) {
      if (this.draggableWrapper) {
        if (!this.draggableWrapper.classList.contains('locked')) {
          this.draggableWrapper.classList.add('locked')
        }
      }
      return {
        icon: LockIcon,
        label: 'Unlock draggable'
      }
    } else {
      if (this.draggableWrapper) {
        if (this.draggableWrapper.classList.contains('locked')) {
          this.draggableWrapper.classList.remove('locked')
        }
      }
      return {
        icon: UnlockIcon,
        label: 'Lock draggable'
      }
    }
  }

  renderLockBtn() {
    const lockBtnContainer = document.createElement('button');
    const lockBtnIcon = document.createElement('div');
    const lockBtnLabel = document.createElement('div');

    lockBtnContainer.classList.add('cdx-draggable-lock-btn-container');
    lockBtnIcon.classList.add('cdx-draggable-lock-btn-icon');
    lockBtnLabel.classList.add('cdx-draggable-lock-btn-label');

    lockBtnIcon.innerHTML = this.lockSwitch(this.locked).icon;
    lockBtnLabel.innerHTML = this.lockSwitch(this.locked).label;

    lockBtnContainer.appendChild(lockBtnIcon);
    lockBtnContainer.appendChild(lockBtnLabel);

    lockBtnContainer.addEventListener('click', () => {
      this.locked = !this.locked;
      lockBtnIcon.innerHTML = this.lockSwitch(this.locked).icon;
      lockBtnLabel.innerHTML = this.lockSwitch(this.locked).label;
      this.data.draggable.locked = this.locked;
      this.block.dispatchChange();
    });

    return lockBtnContainer;
  }

  render() {
    const draggableSettings = document.createElement('div');
    const settingsLabel = document.createElement('label');
    draggableSettings.classList.add('cdx-animations-settings', 'editor-block-tune');
    settingsLabel.classList.add('editor-block-tune-title');
    settingsLabel.innerText = 'Draggable:';

    draggableSettings.appendChild(settingsLabel);
    draggableSettings.appendChild(this.renderLockBtn());
    console.log(this.data)

    return draggableSettings;
  }


  save() {
    return this.data;
  }
}