const defaultState = {
  pager: {},
  rows: [],
  settings: {}
}

export const noticeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_NOTIFICATIONS':
      return {
        pager: action.payload.pager,
        rows: action.payload.rows,
        settings: action.payload.settings,
      }
    case 'TOGGLE_READ':
      let tempRows = state.rows
      let updateIndex = tempRows.findIndex((e) => {
        return e.id === action.payload.id
      })

      tempRows[updateIndex].isRead = action.payload.status

      return {
        ...state,
        rows: tempRows
      }

    case 'MARK_ALL_NOTICES':
      let allNotices = state.rows
      allNotices.forEach((e, i) => {
        allNotices[i].isRead = true
      })

      return {
        ...state,
        rows: {}
      }

      case 'MODIFY_ROWS':
        return{
          ...state,
          rows: action.payload.rows
        }
      
      case 'FORCE_TIME':
        return{
          ...state,
          settings: action.payload.settings
        }

    default:
      return state;
  }
}