import { PopupIcon, CloseIcon, CopyIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Popup {

  static get toolbox() {
    return {
      icon: PopupIcon,
      title: 'Popup',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Popup heading';
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Popup body';
  }

  static get DEFAULT_LIST_PLACEHOLDER() {
    return 'Popup list';
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-popup-container',
      wrapper: 'cdx-popup',
      newButton: 'cdx-popup-modal-btn',

      modalContainer: 'cdx-popup-modal-container',
      modal: 'cdx-popup-modal',
      modalHeadingContainer: 'cdx-popup-modal-heading-container',
      modalHeadingLabel: 'cdx-popup-modal-heading-label',
      modalHeadingInner: 'cdx-popup-modal-heading-inner',
      modalHeadingTop: 'cdx-popup-modal-heading-top',
      modalContentTypeContainer: 'cdx-popup-modal-content-type-container',
      modalContentTypeLabel: 'cdx-popup-modal-content-type-label',
      modalContentContainer: 'cdx-popup-modal-content-container',
      modalTextBtn: 'cdx-text-btn',
      modalOrderedListBtn: 'cdx-ordered-list-btn',
      modalUnorderedListBtn: 'cdx-text-btn',
      modalIdContainer: 'cdx-popup-modal-id-container',
      modalIdLabel: 'cdx-popup-modal-id-label',
      modalIdInput: 'cdx-popup-modal-id-input',
      modalIdCopyContainer: 'cdx-popup-modal-id-copy-container',
      modalIdCopyLabel: 'cdx-popup-modal-id-copy-label',
      modalIdCopyBtn: 'cdx-popup-modal-id-copy-btn',
      modalIdCopyAlert: 'cdx-popup-modal-id-copy-alert',
      popupHeading: 'cdx-popup-heading',
      popupBody: 'cdx-popup-body',
      popupList: 'cdx-popup-list',
      popupInput: 'cdx-popup-input',
      popupListItem: 'cdx-popup-list-item',
      close: 'cdx-popup-close',
      modalBtn: 'cdx-popup-modal-btn',
      blockBtnToggle: 'block-btn-toggle-group',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      editorBlockModalBackdrop: 'editor-block-modal-backdrop',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      adminFont: 'admin-font'
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      modalId: data.modalId || '',
      heading: data.heading || '',
      contentType: data.contentType || 'text',
      body: data.body || '',
      list: data.list || [],
      listType: data.listType || 'ordered'
    };

    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : Popup.DEFAULT_HEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : Popup.DEFAULT_BODY_PLACEHOLDER;
    this._listPlaceholder = config.listPlaceholder ? config.listPlaceholder : Popup.DEFAULT_LIST_PLACEHOLDER;

    this.container = document.createElement('div');
    this._alert = this.getAlertElement();
    this._modalId = this.getModalIdElement();
    this._heading = this.getHeadingElement();
    this._body = this.getBodyElement();
    this._list = this.getListElement();
    this._modal = this.getModalElement();
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  getModalIdElement() {
    const css = this._CSS

    const container = document.createElement('div');
    const label = document.createElement('label');
    const idLabel = document.createElement('label');
    const copyContainer = document.createElement('div');
    const copyLabel = document.createElement('div');
    const button = document.createElement('button');

    container.classList.add(css.modalIdContainer);
    label.classList.add(css.modalIdLabel, css.editorBlockModalLabel, css.adminFont);
    idLabel.classList.add(css.editorBlockModalText, css.adminFont);
    copyContainer.classList.add(css.modalIdCopyContainer);
    copyLabel.classList.add(css.modalIdCopyLabel, css.editorBlockModalText, css.adminFont);
    button.classList.add(css.blockBtn, css.modalIdCopyBtn, css.blockBtnSmall, css.xxlIcon);

    label.innerText = 'Pop up ID: '
    if (this._data.modalId === '') {
      this._data.modalId = this.createId();
    }
    idLabel.innerText = `modal-${this?._settings?.slideId}-${this._data.modalId}`
    copyLabel.innerText = 'Copy to clipboard';
    button.innerHTML = CopyIcon;

    button.addEventListener('click', () => this.copyModalId(`modal-${this?._settings?.slideId}-${this._data.modalId}`));

    copyContainer.appendChild(copyLabel);
    copyContainer.appendChild(button);
    copyContainer.appendChild(this._alert);

    container.appendChild(label);
    container.appendChild(idLabel);
    container.appendChild(copyContainer);

    return container;
  }

  getHeadingElement() {
    let tag = document.createElement('h4');

    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.popupHeading, this._CSS.editorBlockModalInput);
    tag.classList.add(this._CSS.popupInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._heading);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getBodyElement() {
    let tag = document.createElement('p');

    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.popupBody);
    tag.classList.add(this._CSS.popupInput, this._CSS.editorBlockModalInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._body);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getListElement() {
    let tag
    if (this._data.listType === 'ordered') {
      tag = document.createElement('ol');
    }
    else {
      tag = document.createElement('ul')
    }

    if (this._data.list.length > 0) {
      this._data.list.forEach((e) => {
        let listItem = this.listItemElement(e);
        tag.appendChild(listItem);
      })
    }
    else {
      let listItem = this.listItemElement();
      tag.appendChild(listItem);
    }

    tag.classList.add(this._CSS.popupList, this._CSS.popupInput, this._CSS.editorBlockModalInput);

    return tag
  }

  listItemElement(content) {
    let tag = document.createElement('li');
    tag.classList.add(this._CSS.popupListItem);
    tag.contentEditable = this.readOnly ? false : true;

    if (!!content) {
      tag.innerHTML = content;
    }

    tag.addEventListener('keydown', (e) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        let listItem = this.listItemElement();
        this._list.appendChild(listItem);
        listItem.focus();
      }

      if (e.code === 'Backspace') {
        if (tag.textContent.trim() === '') {
          const previousItem = tag.previousElementSibling;
          if (previousItem) {
            tag.remove();
            previousItem.focus();
            var range = document.createRange();
            range.selectNodeContents(previousItem);
            range.collapse(false);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }
    })


    return tag
  }

  getModalElement() {
    const css = this._CSS
    const modalContainer = document.createElement('div');
    const backdrop = document.createElement('div');
    const modal = document.createElement('div');
    const modalHeadingContainer = document.createElement('div');
    const modalInner = document.createElement('div');
    const modalTop = document.createElement('div');
    const modalHeadingLabel = document.createElement('label');
    const modalContentTypeContainer = document.createElement('div');
    const modalContentTypeLabel = document.createElement('label');
    const modalContentContainer = document.createElement('div');
    const textBtn = document.createElement('button');
    const toggleGroup = document.createElement('div');
    const orderedListBtn = document.createElement('button');
    const unorderedListBtn = document.createElement('button');
    const close = document.createElement('button');

    modalContainer.classList.add(css.modalContainer);
    backdrop.classList.add(css.editorBlockModalBackdrop);
    modal.classList.add(css.modal, css.editorBlockModal);
    modalHeadingContainer.classList.add(css.modalHeadingContainer);
    modalInner.classList.add(css.modalHeadingInner);
    modalTop.classList.add(css.modalHeadingTop);
    modalHeadingLabel.classList.add(css.modalHeadingLabel, css.editorBlockModalLabel, css.adminFont);
    modalContentTypeContainer.classList.add(css.modalContentTypeContainer);
    modalContentTypeLabel.classList.add(css.modalContentTypeLabel, css.editorBlockModalLabel, css.adminFont);
    modalContentContainer.classList.add(css.modalContentContainer);

    close.innerHTML = CloseIcon;
    close.classList.add(css.close);
    close.classList.add(css.blockBtn, css.blockBtnSmall, css.clear);

    textBtn.classList.add(css.blockBtn, css.adminFont);
    toggleGroup.classList.add(css.blockBtnToggle);
    orderedListBtn.classList.add(css.blockBtn, css.adminFont);
    unorderedListBtn.classList.add(css.blockBtn, css.adminFont);

    modalHeadingLabel.innerText = 'Title';
    modalContentTypeLabel.innerText = 'Body';
    textBtn.innerText = 'Text';
    orderedListBtn.innerText = 'Ordered list';
    unorderedListBtn.innerText = 'Unordered list';

    if (this._data.contentType === 'text') {
      textBtn.classList.add('active');
    }
    else if (this._data.contentType === 'list') {
      if (this._data.listType === 'ordered') {
        orderedListBtn.classList.add('active');
      }
      else if (this._data.listType === 'unordered') {
        unorderedListBtn.classList.add('active');
      }
    }

    textBtn.addEventListener('click', (e) => {
      e.preventDefault();
      if (this._data.contentType === 'list') {
        modal.setAttribute('content-type', 'text');
        this._data.contentType = 'text';
        if (!textBtn.classList.contains('active')) {
          textBtn.classList.add('active');
          orderedListBtn.classList.remove('active');
          unorderedListBtn.classList.remove('active');
        }
      }
    });

    orderedListBtn.addEventListener('click', (e) => {
      e.preventDefault();
      if (this._data.contentType === 'text') {
        modal.setAttribute('content-type', 'list');
        this._data.contentType = 'list';
      }
      if (this._data.listType === 'unordered') {
        modal.setAttribute('list-type', 'ordered');
        this._data.listType = 'ordered';
        this.toggleListType();
      }
      if (!orderedListBtn.classList.contains('active')) {
        textBtn.classList.remove('active');
        orderedListBtn.classList.add('active');
        unorderedListBtn.classList.remove('active');
      }
    });

    unorderedListBtn.addEventListener('click', (e) => {
      e.preventDefault();
      if (this._data.contentType === 'text') {
        modal.setAttribute('content-type', 'list');
        this._data.contentType = 'list';
      }
      if (this._data.listType === 'ordered') {
        modal.setAttribute('list-type', 'unordered');
        this._data.listType = 'unordered';
        this.toggleListType();
      }
      if (!unorderedListBtn.classList.contains('active')) {
        textBtn.classList.remove('active');
        orderedListBtn.classList.remove('active');
        unorderedListBtn.classList.add('active');
      }
    });

    close.addEventListener('click', function (event) {
      event.preventDefault();
      modalContainer.classList.remove('show');
    });

    modal.setAttribute('content-type', this._data.contentType);
    modal.setAttribute('list-type', this._data.listType);

    modalContentTypeContainer.appendChild(modalContentTypeLabel);
    modalContentTypeContainer.appendChild(textBtn);
    toggleGroup.appendChild(orderedListBtn);
    toggleGroup.appendChild(unorderedListBtn);
    modalContentTypeContainer.appendChild(toggleGroup);

    modalTop.appendChild(modalHeadingLabel);
    modalTop.appendChild(this._heading);

    modalInner.appendChild(modalTop);
    modalInner.appendChild(modalContentTypeContainer);

    modalHeadingContainer.appendChild(modalInner);
    modalHeadingContainer.appendChild(this._modalId);
    modalHeadingContainer.appendChild(close);


    modalContentContainer.appendChild(this._body);
    modalContentContainer.appendChild(this._list);

    modal.appendChild(modalHeadingContainer);
    modal.appendChild(modalContentContainer);

    modalContainer.appendChild(backdrop);
    modalContainer.appendChild(modal);

    return modalContainer;
  }

  getAlertElement() {
    let tag = document.createElement('div');
    tag.classList.add(this._CSS.modalIdCopyAlert, this._CSS.adminFont, this._CSS.editorBlockModalText);
    return tag;
  }

  showAlert(text) {
    this._alert.innerText = `Copied "${text}"`;
    this._alert.classList.add('show');
    setTimeout(() => {
      this._alert.classList.remove('show');
    }, 1500);
  }

  showEditor() {
    this._modal.classList.add('show');
  }

  createId() {
    const timestamp = Date.now().toString(36);

    return timestamp;
  }

  render() {
    let btn = document.createElement('button');
    BtnTooltip(btn, EdjsTooltips.popup.modal);

    btn.classList.add(this._CSS.modalBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon);
    btn.innerHTML = PopupIcon

    btn.onclick = () => {
      this.showEditor();
    };

    this.container.classList.add(this._CSS.container);

    this.container.appendChild(this._modal);

    this.container.appendChild(btn);
    return this.container;
  }


  copyModalId(str) {
    var tempTextArea = document.createElement("textarea");

    tempTextArea.value = str;

    document.body.appendChild(tempTextArea);

    tempTextArea.select();

    document.execCommand("copy");

    document.body.removeChild(tempTextArea);

    this.showAlert(str);
  }

  toggleListType() {
    let newList = this.getListElement();
    this._list.remove();
    this._list = newList;

    let modalContent = this._modal.querySelector(`.${this._CSS.modalContentContainer}`);
    modalContent.appendChild(this._list);
  }

  getListData() {
    let listItems = this._list.querySelectorAll('li');
    let listItemContents = [];
    listItems.forEach((e) => {
      if (e.innerHTML !== '') {
        listItemContents.push(e.innerHTML);
      }
    });


    return listItemContents;
  }

  save() {
    this._data.list = this.getListData();
    let data;

    if (typeof this._modal !== 'undefined') {
      data = {
        modalId: this._data.modalId,
        heading: this._heading.innerHTML,
        contentType: this._data.contentType,
        body: this._body.innerHTML,
        list: this._data.list,
        listType: this._data.listType
      }
    }

    return data;
  }

  static get sanitize() {
    return {
      br: true,
      i: true,
      a: true,
      sub: true,
      sup: true,
      b: true,
    };
  }
}