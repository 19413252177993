import React, { useState } from "react";

// Modules

// App
import MediaLibrary from "../mediaLibrary";

// UI components
import { Container, Row, Col } from "react-bootstrap";
import { Button, Typography } from "@mui/material";


export default function BespokeThumbnail(props) {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [media, setMedia] = useState(props.media ? props.media : null);
  const title = props.title ? props.title : "Add bespoke media"
  const hint = props.hint ? props.hint : "To attach a media, please select or upload an image file via the media library";

  return (
    <div className="bespoke-slide-thumbnail">
      <Row>
        <Typography
          owner="admin"
          variant="h6"
          color="adminText.dark"
          className="mb-2"
        >
          {title}
        </Typography>
      </Row>
      <Container className={`adv-slide-settings-sub-container ${media?.mid ? "img" : ""}`}>
        {media?.mid ?
          <>
            <Col>
              <Row>
                <img alt="" className="thumbnail-container" src={media?.src} />
              </Row>
              <Row>
                <Row className="mb-2">
                  <Col xs={4} className="pe-0">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                    >
                      File Name:
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      color="adminText.dark"
                      weight="light"
                    >
                      {media?.name}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} className="pe-0">
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                    >
                      MID:
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      color="adminText.dark"
                      weight="light"
                    >
                      {media?.mid}
                    </Typography>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Row className="btn-grp mt-3">
              <Col xs={6} className="d-flex justify-content-center">
                <Button
                  className="change-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  Change image
                </Button>
              </Col>
              <Col xs={6} className="d-flex justify-content-center">
                <Button
                  className="remove-btn"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setMedia({});
                    props.onMediaInsert({});
                  }}
                >
                  Remove image
                </Button>
              </Col>
            </Row>
          </> :
          <>
            <Row className="hint">
              <Typography
                variant="body2"
                owner="admin"
              >
                {hint}
              </Typography>
            </Row>
            <Row className="d-flex justify-content-center media-lib-btn-container">
              <Col xs={"auto"}>
                <Button
                  className="media-lib-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  <Typography
                    variant="button"
                    owner="admin"
                    className="lh"
                  >
                    Media library
                  </Typography>
                </Button>
              </Col>
            </Row>
          </>
        }
      </Container>

      <MediaLibrary
        user={props.user}
        mediaType="image"
        setShowMediaLibrary={(condition => {
          setShowMediaLibrary(condition);
        })}
        showMediaLibrary={showMediaLibrary}
        onMediaInsert={(media) => {
          setMedia(media);
          props.onMediaInsert(media);
        }}
        selectedMid={media?.mid}
        selectedListingId={media?.listingId}
        selectedListingName={media?.listingName}
        selectedAssetId={media?.assetId}
        selectedFileId={media?.fileId}
        selectedIceMedia={media?.iceMedia}
        files={props.files}
      />
    </div>
  );
};
