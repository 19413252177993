const defaultState = {
  currentLocation: 'home',
};

export const locationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        currentLocation: action.payload,
      };

    default:
      return state;
  }
};