// Dependencies
import React, { useState, useRef, useEffect } from "react";

// Modules
import { useSelector } from "react-redux";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import PostImage from "../postImage";
import PostBody from "../postBody";
import SinglesHeader from "../singlesHeader";

// UI components
import { CircularProgress, Hidden } from "@mui/material";
import SkeletonSingleScreen from "../skeleton-screens/skeletonSingleScreen";

export default function Contribute(props) {
  const [iframeLoading, setIframeLoading] = useState(true);
  const iframeRef = useRef(null);
  const user = useSelector((state) => state).authReducer.user;

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  useEffect(() => {
    getOnlineNode(apiEndpoints.contribute, user.access_token)
      .then((response) => {
        if (response.data.rows[0]) {
          setData(response.data.rows[0]);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    return (
      <main className="contribute single">
        <article className="article">
          <SinglesHeader
            title={data?.title ? data.title : ""}
            hideBack={true}
            backBtnText={""}
          />
  
          {data.uri && (
            <PostImage
              imgSrc={data.uri}
              nid={data.nid}
            />
          )}
  
          <div className="article__body">
            <PostBody body={data?.body ? data.body : ''} />
          </div>
  
          <div className="article__body pt-5">
            <div className="ratio ratio-4x3" style={{ height: "100vh" }}>
              {data?.webform && (
                <>
                  {iframeLoading && (
                    <div className="w-100 h-100 text-center">
                      <CircularProgress />
                    </div>
                  )}
                  <iframe
                    className={`${iframeLoading && "invisible"}`}
                    src={`${serverUrl}/node/${data.webform}?app=true`}
                    ref={iframeRef}
                    style={{ width: "100%", height: "1080px", border: "none" }}
                    title="Iframe Content"
                    onLoad={(iframe) => {
                      setIframeLoading(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </article>
      </main>
    );
  }
}
