import React, { useState } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

export default function HomeSkeletonPublicScreen(props) {

  const [backgroundColor, setBackgroundColor] = useState('#f3f3f3');
  const [foregroundColor, setForegroundColor] = useState('#ecebeb');

  const renderHomeItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (props.numberOfItems) {
      numberOfItems = props.numberOfItems;
    } else {
      numberOfItems = 1;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} key={'item-' + i} className="mb-4">
          <div className={itemClassList} style={{ paddingBottom: 0, border: 'none' }}>
            <ContentLoader
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 1140 334"
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
            >
              <rect x="585.642" y="142.67" width="209.676" height="16" rx="8" ry="8" />
              <rect x="585.642" y="117.866" width="522.532" height="16" rx="8" ry="8" />
              <rect x="585.642" y="76.77" width="285.816" height="25" rx="12.5" ry="12.5" />
              <rect x="585.642" y="45.022" width="554.358" height="25" rx="12.5" ry="12.5" />
              <rect x="612.304" y="0" width="109.924" height="20" rx="10" ry="10" />
              <rect x="585.642" y="0" width="18.133" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="554.802" height="312.076" rx="5" ry="5" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  const renderHomeSocialItems = () => {

    let items = [];
    let numberOfItems;

    if (props.numberOfItems) {
      numberOfItems = props.numberOfItems;
    } else {
      numberOfItems = 2;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} md={6} key={'social-' + i} className="mb-4">
          <div>
            <ContentLoader
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 555 536"
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
            >
              <rect x="0" y="33.235" width="554.802" height="502.572" rx="5" ry="5" />
              <rect x="29.521" y="0" width="61.366" height="20" rx="10" ry="10" />
              <rect x="102.017" y="0" width="160.339" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="22.584" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  const itemClass = "home-item ";
  const itemClassList = `${itemClass}  item`;

  return (
    <main className="home  screen">

      {/* Title */}
      <div className="welcome">
        <ContentLoader
          speed={2}
          width={'100%'}
          height={'100%'}
          viewBox="0 0 1140 117"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="0" y="0" width="236.444" height="50" rx="10" ry="10" />
          <rect x="0" y="0" width="355.948" height="50" rx="10" ry="10" />
          <rect x="0" y="66.63" width="293.893" height="50" rx="10" ry="10" />
        </ContentLoader>
      </div>

      {/* Home items */}
      <Row className="mt-5">
        {renderHomeItems(itemClassList)}
      </Row>

      {/* Home social items */}
      <Row className="mt-5">
        {renderHomeSocialItems()}
      </Row>

    </main>
  );
}
