import React from "react";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../../config";
import GridItem from "../../partials/gridItem";

export default function FormItem(props) {
  const navigate = useNavigate();

  return (
    <GridItem
      xs={12} sm={6} md={3}
      titleFamily="secondary"
      titleVariant="subtitle1"
      titleWeight="medium"
      itemClass="form"
      nid={props.item?.nid}
      cardAction={true}
      hasImg={true}
      imgSrc={`${serverUrl}/${props.item.field_featured_image}`}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item?.category_labels}
      created={props.item?.created}
      // cardActionCallBack={() => props.showForm(props.item?.nid, props.item?.title)}
      cardActionCallBack={() => navigate(`/forms/${props.item?.nid}`)}
      contentType={props.contentType}
    />
  )
}