import { TouchSensor } from '@dnd-kit/core';
import { CloseIcon, ImageIcon, PinIcon, DeleteIcon, SearchIcon, AccordionArrow, CountryMapIcon } from '../../../icons/icons';
import { Make as make, Label as label, Input as input } from './utils'
import { subdomain } from '../../../../config';
import BtnTooltip from '../../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../../tooltips/edjsTooltip';
const tooltips = require('./labelTooltip.json');
const labels = require('./labels.json');

/**
 * Build styles
 */
require('./index.css').toString();

export default class CountryMap {

  static get toolbox() {
    return {
      icon: CountryMapIcon,
      title: 'Country map',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'country-map-container',
      wrapper: 'map',
      newButton: 'country-map-btn',
      replaceButton: 'country-map-replace-btn',

      modal: 'country-map-modal',
      modalInner: 'country-map-modal-inner',
      input: 'country-map-input',
      modalImgContainer: 'fallback-modal-img-container',
      modalImg: 'fallback-modal-img',
      fallbackButton: 'country-map-fallback-btn',
      codeEditor: 'country-map-editor',
      close: 'country-map-close',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      mapData: data.mapData || {},
      mapId: data.mapId || '',
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      mapObject: data.mapObject || '',
    };

    this._element = this.getElement();
    this._toggleElement = this.toggleElement();
    this._modalFallbackElement = this.modalFallbackElement();
    this._modal = this.modalElement();
    this._elementId = `slide-${this._settings.slide.id}-editor`;
  }

  updateMap() {
    let mapElement = document.querySelector(`#${this._elementId} #country-map-frame-${this._settings.slide.id}`);

    mapElement.contentWindow.postMessage({ mapData: this._data.mapData }, '*');
  }

  getElement() {
    const tag = document.createElement('div');
    tag.classList.add(this._CSS.wrapper);

    let html;

    const apiKey = this._settings.googleApiKey;

    if (!apiKey) {
      html = '<div class="d-flex h-100 justify-content-center align-items-center"><b>Map can\'t be displayed - API key error</b></div>';
      tag.innerHTML = html
    } else {
      let mapIframe = this.getIframe(apiKey);

      mapIframe.id = `country-map-frame-${this._settings.slide.id}`;

      // if (this._data.mapId) {
      //   mapIframe.id = `map-frame-${this._settings.slide.id}-block-${this._data.mapId}`;
      // } else {
      //   let mapId = `map-${this.createId()}`
      //   mapIframe.id = `map-frame-${this._settings.slide.id}-block-${this.createId()}`;
      //   this._data.mapId = mapId;
      // }
      tag.append(mapIframe)
    }

    // tag.innerHTML = html;

    return tag;
  }

  getIframe(apiKey) {
    const tag = document.createElement('iframe');

    const pinSrc = `${this._settings.themeDirectory}/dist/images/icons/${this._settings.pin}`;
    const label = encodeURIComponent(this._data.label);

    const params = `key=${apiKey}&pin=${pinSrc}&mode=edit&presentation=${this._settings.presentationId}&viewMode=preview&sub=${subdomain}`;
    const src = `/country-map.html?${params}`;
    tag.src = src;

    return tag;
  }


  modalFallbackElement() {
    const imgContainer = document.createElement('div');
    const btn = document.createElement('button');

    imgContainer.classList.add(this._CSS.modalImgContainer);
    btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon, 'fallback-replace-img-btn');
    btn.innerHTML = `${ImageIcon}`;
    btn.onclick = () => {
      const blockIndex = this.api.blocks.getCurrentBlockIndex();
      const block = this.api.blocks.getBlockByIndex(blockIndex);
      this._settings.showMediaLibrary({
        details: block,
        data: this._data,
        bundle: 'image',
      });
    };

    if (this._data.url) {
      const fallbackImg = document.createElement('img');
      fallbackImg.setAttribute('src', this._data.url);
      fallbackImg.setAttribute('data-mid', this._data.mid);
      fallbackImg.setAttribute('data-x', this._data.x);
      fallbackImg.setAttribute('data-y', this._data.y);
      fallbackImg.setAttribute('data-width', this._data.width);
      fallbackImg.setAttribute('data-height', this._data.height);
      fallbackImg.setAttribute('data-maxwidth', this._data.maxWidth);
      fallbackImg.setAttribute('data-maxheight', this._data.maxHeight);

      fallbackImg.classList.add(this._CSS.modalImg);
      imgContainer.append(fallbackImg);
    }

    imgContainer.append(btn);

    return imgContainer;
  }



  modalElement(itemData) {
    const modal = document.createElement('div');
    const modalInner = document.createElement('div');
    const modalToolBar = make('div', 'modal-toolbar');

    let csvBtn = this.importCSVbtn();
    let filterInput = this.filterItem();

    let countriesContent = make('div', 'countries-accordion-content-wrapper');
    let citiesContent = make('div', 'cities-accordion-content-wrapper');

    let countriesAccordion = this.accordion(labels.country.countrySection, 'country', countriesContent);
    let citiesAccordion = this.accordion(labels.cities.citiesSection, 'city', citiesContent);

    let mapCenterInputContainer = this.mapCenterInputContainer();
    let mapInputMain
    const subRegionContainer = this.subRegionContainer();
    const addSubRegion = this.addSubRegion(subRegionContainer);
    const citiesInputContainer = this.citiesInputContainer();
    const addCity = this.addCity(citiesInputContainer);
    let mapTitle = input('map-title');
    let mapStyleLabel = label('map-style', labels.mapStyle, tooltips.mapStyle);
    let mapStyle = this.mapStyle(this._data.mapData);
    let mapTitleLabel = label('map-title', labels.title, tooltips.title);
    let mapTitleContainer = make('div', 'map-title-container');

    const fallbackButton = make('button', [this._CSS.fallbackButton, 'block-btn']);
    let fallback = this.fallbackModal(this._modalFallbackElement);
    const close = document.createElement('button');
    close.innerHTML = `${CloseIcon}`;
    close.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);
    close.classList.add(this._CSS.close);

    const that = this;

    fallbackButton.innerText = 'Fallback image';
    BtnTooltip(fallbackButton, EdjsTooltips.countryMap.fallback);

    fallbackButton.addEventListener('click', () => {
      if (!fallback.classList.contains('show-fallback')) {
        fallback.classList.add('show-fallback')
      }
    })

    close.addEventListener('click', function (event) {
      event.preventDefault();
      modal.classList.remove('show');
      that.updateMap();
    });


    modal.classList.add(this._CSS.modal);
    modal.setAttribute('data-mutation-free', true);
    modalInner.classList.add(this._CSS.modalInner);


    if (this._data?.mapData || itemData) {
      let mapData

      if (this._data.mapData.mapTitle) {
        mapTitle.value = this._data.mapData.mapTitle;
      }

      if (itemData) {
        mapData = itemData;
      }
      else {
        mapData = this._data?.mapData;
      }

      if (this._data.mapData?.cities) {
        let cityKeys = Object.keys(mapData.cities)
        cityKeys.forEach((e) => {
          let cityItem = this.cityItem(e, mapData.cities[e])
          citiesInputContainer.append(cityItem);
        })
      }
      if (mapData.mapCenter) {
        mapInputMain = this.mapInputMain('main-map-center', mapData.mapCenter);
        let subRegionKeys = Object.keys(mapData.mapCenter?.subRegions);

        if (subRegionKeys && subRegionKeys.length > 0) {
          let subRegions = mapData.mapCenter?.subRegions;
          subRegionKeys.forEach((subRegionId) => {
            let subRegionData = subRegions[subRegionId];
            let subRegionItem = this.mapInputMain(subRegionId, subRegionData, true);
            subRegionContainer.append(subRegionItem);
          })
        }
      }
      else {
        mapInputMain = this.mapInputMain('main-map-center');
      }
    }
    else {
      mapInputMain = this.mapInputMain('main-map-center');
    }

    mapTitleContainer.append(mapTitleLabel);
    mapTitleContainer.append(mapTitle);

    mapCenterInputContainer.append(mapInputMain);

    countriesContent.append(mapCenterInputContainer);
    countriesContent.append(subRegionContainer);
    countriesContent.append(addSubRegion);

    citiesContent.append(citiesInputContainer);
    citiesContent.append(addCity);

    modalToolBar.append(mapTitleContainer);
    modalToolBar.append(mapStyleLabel);
    modalToolBar.append(mapStyle)
    modalToolBar.append(fallbackButton);
    modalToolBar.append(close);
    modalInner.append(fallback);
    modalInner.append(modalToolBar);
    modalInner.append(countriesAccordion);
    modalInner.append(citiesAccordion);

    modal.append(modalInner);

    return modal;
  }

  mapStyle(itemData) {
    let tag = make('select', 'map-style-select');
    const optionsArray = ["Light", "Dark"];
    optionsArray.forEach((e, i) => {
      const option = make('option', 'select-option');
      option.text = e;
      option.value = i;

      tag.appendChild(option);
    })


    tag.onchange = () => {
      // console.log('selected', tag.options[tag.selectedIndex].value);
      tag.setAttribute('data-map-style', tag.options[tag.selectedIndex].text);
    }
    if (itemData && itemData?.mapStyle) {
      tag.selectedIndex = optionsArray.indexOf(itemData.mapStyle);
      tag.setAttribute('data-map-style', itemData?.mapStyle);
    }
    else {
      tag.selectedIndex = 0;
    }

    return tag
  }

  fallbackModal(fallbackElement) {
    const modalContainer = make('div', 'fallback-modal-container');
    const backdrop = make('div', 'fallback-modal-backdrop');
    const modal = make('div', 'fallback-modal-main');
    const closeBtn = make('button', ['fallback-modal-close', this._CSS.blockBtn, this._CSS.clear]);

    closeBtn.innerHTML = CloseIcon;

    closeBtn.addEventListener('click', () => {
      modalContainer.classList.remove('show-fallback');
    })

    modal.append(closeBtn);
    if (fallbackElement) {
      modal.append(fallbackElement);
    }
    modalContainer.append(backdrop);
    modalContainer.append(modal);

    return modalContainer;
  }

  showEditor() {
    this._modal.classList.add('show');
  }

  toggleElement() {
    const tag = document.createElement('button');
    tag.classList.add('sidebar-btn');

    const leftIcon = document.createElement('span');
    const rightIcon = document.createElement('span');

    leftIcon.classList.add('material-icons', 'left');
    rightIcon.classList.add('material-icons', 'right');

    leftIcon.innerHTML = 'chevron_left';
    rightIcon.innerHTML = 'chevron_right'

    tag.addEventListener('click', function (event) {
      event.preventDefault();

      this.classList.toggle('active');
    });

    tag.append(leftIcon);
    tag.append(rightIcon);

    return tag;
  }

  addItem(section, item, itemType) {
    let element = item;
    if (itemType) {
      let itemId = `${itemType}-${this.createId()}`
      element.id = itemId;
    }
    section.append(element);
  }

  deleteButton(type, item) {
    let deleteBtnContainer = make('div', 'delete-btn-container');
    let deleteBtn = make('button', 'delete-btn');
    deleteBtn.innerHTML = DeleteIcon

    deleteBtn.onclick = () => {
      const alertPopup = this.alertPopup('Are you sure you want to delete this item? All items nested inside it will also be deleted.',
        () => {
          if (type === 'city') {
            let cityId = item.id
            item.remove();
            delete this._data.mapData.cities[cityId];
            alertPopup.classList.remove('show-alert');
            alertPopup.remove();
          }
          if (type === 'hotel') {
            if (item.classList.contains('sub-level-hotel-item')) {
              let hotelId = item.id;
              let subLevelId = item.closest('.city-sub-level-item-container').id;
              let cityId = item.closest('.city-item-container').id;

              alertPopup.classList.remove('show-alert');
              alertPopup.remove();
              item.remove();
              delete this._mapData.cities[cityId].subLevels[subLevelId].hotels[hotelId];
            }
            else {
              let hotelId = item;
              let cityId = item.closest('.city-item-container').id;

              alertPopup.classList.remove('show-alert');
              alertPopup.remove();
              item.remove();
              delete this._data.mapData.cities[cityId].hotels[hotelId];
            }
          }
          if (type === 'slide') {
            if (item.parentNode.parentNode.parentNode.classList.contains('sub-level-hotel-item')) {
              let slideId = item.id;
              let hotelId = item.closest('.sub-level-hotel-item').id;
              let subLevelId = item.closest('.city-sub-level-item-container').id;
              let cityId = item.closest('.city-item-container').id

              alertPopup.classList.remove('show-alert');
              alertPopup.remove();
              item.remove();
              delete this._data.mapData.cities[cityId].subLevels[subLevelId].hotels[hotelId].slideIds[slideId];
            }
            else {
              let slideId = item.id;
              let hotelId = item.closest('.hotel-item-container').id;
              let cityId = item.closest('.city-item-container').id

              alertPopup.classList.remove('show-alert');
              alertPopup.remove();
              item.remove();
              delete this._data.mapData.cities[cityId].hotels[hotelId].slideIds[slideId];
            }
          }
          if (type === 'subRegion') {
            let regionId = item.id

            alertPopup.classList.remove('show-alert');
            alertPopup.remove();
            item.remove();
            delete this._data.mapData.mapCenter.subRegions[regionId];
          }
          if (type === 'subLevel') {
            let subLevelId = item.id;
            let cityId = item.closest('.city-item-container').id

            alertPopup.classList.remove('show-alert');
            alertPopup.remove();
            item.remove();
            delete this._data.mapData.cities[cityId].subLevels[subLevelId];
          }
        },
        () => {
          alertPopup.classList.remove('show-alert');
          alertPopup.remove();
        }
      )
      this.highlightItem(item, true);
      alertPopup.classList.add('show-alert');
      item.appendChild(alertPopup);
    }

    deleteBtnContainer.append(deleteBtn);

    return deleteBtnContainer;
  }

  createId() {
    const timestamp = Date.now().toString(36);
    const randomStr = Math.random().toString(36).substring(2, 5);

    return timestamp + randomStr;
  }

  mapCenterInputContainer() {
    let mapCenterInputContainer = make('div', 'map-center-input-container');
    return mapCenterInputContainer;
  }

  mapInputMain(itemId, itemData, subRegion) {
    let centerItemContainer = make('div', 'center-item-container');
    let centerItemRegion = input('center-item-region');
    let centerItemLatLong = input('center-item-lat-long');
    let centerItemZoom = input('center-item-zoom');

    let centerItemRegionLabel = label('center-item-region', labels.country.countryName, tooltips.country.countryName);
    let centerItemLatLongLabel = label('center-item-lat', labels.country.latLong, tooltips.country.latLong);
    let centerItemZoomLabel = label('center-item-zoom', labels.country.zoom, tooltips.country.zoom);

    centerItemContainer.id = itemId;


    if (itemId && itemData) {
      centerItemRegion.value = itemData.label;
      centerItemLatLong.value = itemData.lat && itemData.long ? `${itemData.lat}, ${itemData.long}` : '';
      centerItemZoom.value = itemData.zoom;
    }

    centerItemContainer.append(centerItemRegionLabel);
    centerItemContainer.append(centerItemRegion);
    centerItemContainer.append(centerItemLatLongLabel);
    centerItemContainer.append(centerItemLatLong);
    centerItemContainer.append(centerItemZoomLabel);
    centerItemContainer.append(centerItemZoom);
    if (subRegion) {
      let deleteBtn = this.deleteButton('subRegion', centerItemContainer);
      centerItemContainer.append(deleteBtn);
    }

    return centerItemContainer;
  }

  subRegionContainer(item) {
    let subRegionContainer = make('div', 'sub-region-container');
    return subRegionContainer;
  }

  addSubRegion(parent) {
    let subRegionBtnContainer = make('div', 'sub-region-btn-container');
    let subRegionBtn = make('button', 'sub-region-btn');
    subRegionBtn.innerText = 'Add Country';
    subRegionBtn.onclick = () => {
      this.addItem(parent, this.mapInputMain(null, null, true), 'region');
    }
    subRegionBtnContainer.append(subRegionBtn);
    return subRegionBtnContainer;
  }

  citiesInputContainer() {
    let citiesContainer = make('div', 'cities-container');
    return citiesContainer;
  }

  cityItem(itemId, itemData, subLevel) {
    let cityItemContainer = make('div');
    let cityName = input('city-name');
    let cityLatLong = input('city-lat-long');
    let cityZoom = input('city-zoom');
    let pinLocation = this.pinLocation(cityItemContainer, itemData);

    let cityNameLabel;
    if (!subLevel) {
      cityNameLabel = label('city-name', labels.cities.cityName, tooltips.cities.cityName);
    }
    else {
      cityNameLabel = label('city-name', labels.district.districtName, tooltips.district.districtName);
    }
    let cityLatLongLabel = label('city-lat-long', subLevel ? labels.district.latLong : labels.cities.latLong, subLevel ? tooltips.district.latLong : tooltips.cities.latLong);
    let cityZoomLabel = label('city-zoom', subLevel ? labels.district.zoom : labels.cities.zoom, subLevel ? tooltips.district.zoom : tooltips.cities.zoom);
    let cityPinLabel = label('city-pin', subLevel ? labels.district.pos : labels.cities.pos, subLevel ? tooltips.district.pos : tooltips.cities.pos);


    let deleteBtn

    let hotelsInputContainer = this.hotelsInputContainer();
    let addHotel = this.addHotel(hotelsInputContainer);
    let hotelsContent = make('div', 'hotels-accordion-content-wrapper');

    hotelsContent.append(hotelsInputContainer);
    hotelsContent.append(addHotel);

    let hotelsAccordion = this.accordion(labels.hotels.hotelsSection, 'hotel', hotelsContent)

    let subLevelsContainer = this.subLevelsInputContainer()
    let addSubLevel = this.addSubLevel(subLevelsContainer);
    let subLevelsContent = make('div', 'sub-levels-accordion-content-wrapper');

    subLevelsContent.append(subLevelsContainer);
    subLevelsContent.append(addSubLevel);

    if (subLevel) {
      cityItemContainer.classList.add('city-sub-level-item-container');
      hotelsInputContainer.classList.add('city-sub-level-hotels');
      deleteBtn = this.deleteButton('subLevel', cityItemContainer);
    } else {
      cityItemContainer.classList.add('city-item-container');
      deleteBtn = this.deleteButton('city', cityItemContainer);
    }

    if (itemId && itemData) {
      let hotelKeys = Object.keys(itemData.hotels);

      cityItemContainer.id = itemId;
      cityName.value = itemData.city;
      cityLatLong.value = itemData.lat && itemData.long ? `${itemData.lat}, ${itemData.long}` : '';
      cityZoom.value = itemData.zoom;
      if (!subLevel) {
        if (itemData.subLevels) {
          let subLevelKeys = Object.keys(itemData.subLevels)
          if (subLevelKeys.length > 0) {
            subLevelKeys.forEach((e) => {
              let subLevelItem = this.cityItem(e, itemData.subLevels[e], true);
              subLevelsContainer.append(subLevelItem);
            })
          }
        }

      }
      if (hotelKeys.length > 0) {
        hotelKeys.forEach((e) => {
          let hotelItem = this.hotelItem(e, itemData.hotels[e]);
          if (subLevel) {
            hotelItem.classList.add('sub-level-hotel-item');
          }
          hotelsInputContainer.append(hotelItem)
        })
      }
    }


    let cityAccordBtn = make('button', 'city-accord-btn');

    if (!subLevel) {
      cityAccordBtn.innerHTML = AccordionArrow
      cityItemContainer.append(cityAccordBtn);
    }


    cityItemContainer.append(cityNameLabel);
    cityItemContainer.append(cityName);
    cityItemContainer.append(cityPinLabel);
    cityItemContainer.append(pinLocation);
    cityItemContainer.append(cityLatLongLabel);
    cityItemContainer.append(cityLatLong);
    cityItemContainer.append(cityZoomLabel);
    cityItemContainer.append(cityZoom);
    cityItemContainer.append(deleteBtn);
    if (!subLevel) {
      let subLevelsAccordion = this.accordion(labels.district.districtsSection, 'sub-level', subLevelsContent);
      let cityItemContent = make('div', 'city-item-content');

      cityAccordBtn.onclick = () => {
        cityItemContainer.classList.toggle('city-item-expand');
        // if (cityItemContainer.classList.contains('city-item-expand')) {
        //   cityItemContent.style.maxHeight = cityItemContent.scrollHeight + "px";
        //   setTimeout(() => {
        //     cityItemContent.style.maxHeight = "unset"
        //   }, 500)
        // }
        // else {
        //   cityItemContent.style.maxHeight = cityItemContent.scrollHeight + "px";
        //   setTimeout(() => {
        //     cityItemContent.style.maxHeight = "0px"
        //   }, 50)
        // }
      }
      cityItemContent.append(subLevelsAccordion);
      cityItemContent.append(hotelsAccordion);
      cityItemContainer.append(cityItemContent);
    }
    else {
      cityItemContainer.append(hotelsAccordion);
    }

    return cityItemContainer;
  }

  addCity(parent) {
    let addBtnContainer = make('div', 'add-btn-container');
    let addCityBtn = make('button', 'add-city-btn');
    addCityBtn.innerText = "Add City";
    addCityBtn.onclick = () => {
      this.addItem(parent, this.cityItem(), 'city');
    }
    addBtnContainer.append(addCityBtn);
    return addBtnContainer;
  }

  subLevelsInputContainer() {
    let subLevelsContainer = make('div', 'city-sub-levels-container');
    return subLevelsContainer;
  }

  addSubLevel(parent) {
    let addBtnContainer = make('div', 'add-btn-container');
    let addSubLevelBtn = make('button', 'add-subLevel-btn');
    addSubLevelBtn.innerText = "Add District";
    addSubLevelBtn.onclick = () => {
      this.addItem(parent, this.cityItem(null, null, true), 'sub-level');
    }
    addBtnContainer.append(addSubLevelBtn);
    return addSubLevelBtn;
  }

  hotelsInputContainer() {
    let hotelsContainer = make('div', 'hotels-container');
    return hotelsContainer;
  }

  hotelItem(itemId, itemData, firstAdd) {
    let hotelItemContainer = make('div', 'hotel-item-container');
    let slideSectionContainer = make('div', 'slides-section');
    let hotelNameInput = input('hotel-name');
    let hotelLatLongInput = input('hotel-lat-long');
    let extPresentationIdContainer = make('div', 'external-presentation-id-container');
    let extPresentationIdInput = input('external-presentation-id');

    let hotelNameInputLabel = label('hotel-name', labels.hotels.hotelName, tooltips.hotels.hotelName);
    let hotelLatLongInputLabel = label('hotel-lat-long', labels.hotels.latLong, tooltips.hotels.latLong);
    let hotelPinLabel = label('hotel-pin', labels.hotels.pos, tooltips.hotels.pos);
    let extPresentationIdLabel = label('external-presentation-id', labels.presentationId, tooltips.presentationId);
    let slideIdsLabel = label('slide-id', labels.slideID, tooltips.slideID);

    let slidesInputContainer = this.slidesInputContainer();
    let addSlide = this.addSlide(slidesInputContainer);

    extPresentationIdContainer.append(extPresentationIdLabel);
    extPresentationIdContainer.append(extPresentationIdInput);

    let deleteBtn = this.deleteButton('hotel', hotelItemContainer);

    slideSectionContainer.append(extPresentationIdContainer);
    slideSectionContainer.append(slideIdsLabel);
    slideSectionContainer.append(slidesInputContainer);
    slideSectionContainer.append(addSlide);

    if (itemId && itemData) {
      let slideIdKeys = Object.keys(itemData.slideIds);

      hotelItemContainer.id = itemId;
      hotelNameInput.value = itemData.hotelName;
      hotelLatLongInput.value = itemData.lat && itemData.long ? `${itemData.lat}, ${itemData.long}` : '';
      extPresentationIdInput.value = itemData?.extPresentationId ? itemData?.extPresentationId : '';

      if (slideIdKeys.length > 0) {
        slideIdKeys.forEach((e) => {
          let slideItem = this.slideItem(e, itemData.slideIds[e]);
          slidesInputContainer.append(slideItem);
        })
      }
    }

    if(firstAdd){
      this.addItem(slidesInputContainer, this.slideItem(), 'slide');
    }

    let pinLocation = this.pinLocation(hotelItemContainer, itemData);

    hotelItemContainer.append(hotelNameInputLabel);
    hotelItemContainer.append(hotelNameInput);
    hotelItemContainer.append(hotelPinLabel);
    hotelItemContainer.append(pinLocation);
    hotelItemContainer.append(hotelLatLongInputLabel);
    hotelItemContainer.append(hotelLatLongInput);
    hotelItemContainer.append(deleteBtn);
    hotelItemContainer.append(slideSectionContainer);

    return hotelItemContainer;
  }

  addHotel(parent) {
    let addBtnContainer = make('div', 'add-btn-container');
    let addHotelBtn = make('button');
    addHotelBtn.innerText = "Add Hotel";
    addHotelBtn.classList.add('add-hotel-btn')
    addHotelBtn.onclick = () => {
      this.addItem(parent, this.hotelItem(null, null, true), 'hotel');
    }
    addBtnContainer.append(addHotelBtn);
    return addBtnContainer;
  }

  slidesInputContainer() {
    let slidesContainer = make('div', 'slides-container');
    return slidesContainer;
  }

  slideItem(itemId, itemData) {
    let slideItemContainer = make('div', 'slide-item-container');
    let slideItemInput = input('slide-item');
    let deleteBtn = this.deleteButton('slide', slideItemContainer);

    if (itemId) {
      slideItemContainer.id = itemId;
    }

    if (itemData) {
      slideItemInput.value = itemData;
    }

    slideItemContainer.append(deleteBtn);
    slideItemContainer.append(slideItemInput);

    return slideItemContainer;
  }

  addSlide(parent) {
    let addSlideBtn = make('button');
    addSlideBtn.innerText = "+";
    addSlideBtn.classList.add('add-slide-btn')
    addSlideBtn.onclick = () => {
      this.addItem(parent, this.slideItem(), 'slide');
    }
    return addSlideBtn;
  }

  accordion(name, className, content) {
    let mainAccordion = make('div', [`${className}-accordion`, 'accordion']);
    let accordionSummary = make('div', [`${className}-accordion-summary`, 'accordion-summary']);
    let accordionToggle = make('button', 'accordion-toggle');
    let accordionTitle = make('div', 'accordion-title');
    let accordionContent = make('div', [`${className}-accordion-content`, 'accordion-content']);

    accordionTitle.innerText = name;
    if (content) {
      accordionContent.append(content);
    }

    accordionToggle.innerHTML = AccordionArrow;
    accordionSummary.append(accordionToggle);
    accordionSummary.append(accordionTitle);
    mainAccordion.append(accordionSummary);
    mainAccordion.append(accordionContent);

    accordionSummary.onclick = () => {
      mainAccordion.classList.toggle('expand');
      // if (mainAccordion.classList.contains('expand')) {
      //   accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
      //   setTimeout(() => {
      //     accordionContent.style.maxHeight = "unset"
      //   }, 500)
      // }
      // else {
      //   accordionContent.style.transition = "all .5s ease-in-out"
      //   accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
      //   setTimeout(() => {
      //     accordionContent.style.maxHeight = "0px"
      //   }, 50)
      // }
    }

    accordionSummary.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === 'Backspace' || e.key === 'Tab') {
        e.preventDefault()
        e.stopPropagation();
      }
    })

    return mainAccordion
  }

  filterItem() {
    let filterInputContainer = make('div', 'filter-city-container');
    let filterInput = input('filter-city');
    let filterBtn = make('button', 'filter-btn');

    filterInput.setAttribute("placeholder", "Search by name");
    filterBtn.innerHTML = SearchIcon;

    // Define the debounced function for filterInputChange
    const debouncedFilterInputChange = this.debounce((value) => {
      this.filterInputChange(value);
    }, 1000); // Adjust the delay as needed

    filterInput.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.filterInputChange(e.target.value.trim());
      }
    });
    filterInput.addEventListener('keyup', (e) => {
      debouncedFilterInputChange(e.target.value.trim());
    })
    filterBtn.addEventListener('click', () => {
      debouncedFilterInputChange(filterInput.value.trim());
    })

    filterInputContainer.append(filterInput);
    filterInputContainer.append(filterBtn);
    return filterInputContainer;
  }

  filterInputChange(value) {
    if (!value) {
      this.resetSearch();
    }
    else {
      const result = this.findItemByName(value.toLowerCase());
      if (result) {
        let itemId = result.itemId;
        if (itemId) {
          const item = document.querySelector(`#${this._elementId} #${itemId}`);
          if (item) {
            const cityItems = document.querySelectorAll(`#${this._elementId} .city-item-container`);
            const cityItem = document.querySelector(`#${this._elementId} #${result.cityId}`);
            cityItems.forEach((e) => {
              e.classList.add('hide');
            })
            cityItem.classList.remove('hide');
            item.scrollIntoView({ block: 'center', behavior: 'smooth' })
            this.highlightItem(item);
          }
        }
      }
      else {
        this.resetSearch();
      }
    }
  }

  resetSearch() {
    const cityItems = document.querySelectorAll(`#${this._elementId} .city-item-container`);
    cityItems.forEach((e) => {
      e.classList.remove('hide');
    })
  }

  highlightItem(item, error) {
    if (error) {
      item.classList.add('highlight-error');
      setTimeout(() => {
        item.classList.remove('highlight-error');
      }, 2000)
    }
    else {
      item.classList.add('highlight');
      setTimeout(() => {
        item.classList.remove('highlight');
      }, 2000)
    }
  }

  debounce(func, delay) {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }


  render() {
    let container = document.createElement('div');
    let tag = this._element;
    let btn = document.createElement('button');
    container.classList.add(this._CSS.container);
    BtnTooltip(btn, EdjsTooltips.countryMap.modal);

    if (this._data.label || this._data.latitude || this._data.longitude) {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${CountryMap.toolbox.icon}`;
    } else {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.classList.add(this._CSS.newButton);
      btn.innerHTML = `${CountryMap.toolbox.icon}`;
    }

    btn.onclick = () => {
      this.showEditor();
    }

    container.append(tag);
    // container.append(this._toggleElement);
    container.append(this._modal);

    setTimeout(() => {
      this.updateMap()
    }, 1000);

    container.append(btn);
    return container;
  }

  processInputs() {
    let mapData = {}
    mapData = {
      mapCenter: this.getMapCenter(),
      cities: this.getCities(),
      mapTitle: this.getMapTitle(),
      mapStyle: this.getMapStyle()
    }
    return mapData
  }

  processLatLong(coords) {
    let coordArr = coords.split(',');
    if (coordArr.length > 1) {
      let coordObj = { lat: coordArr[0].trim(), long: coordArr[1].trim() }
      return coordObj;
    }
    else {
      return { lat: "", long: "" }
    }
  }

  getMapTitle() {
    let mapTitle = document.querySelector(`#${this._elementId} .map-title-input`).value;
    return mapTitle;
  }

  getMapStyle() {
    let mapStyle = document.querySelector(`#${this._elementId} .map-style-select`);
    return mapStyle.options[mapStyle.selectedIndex].text
  }

  getMapCenter() {
    let mainMapCenter = document.querySelector(`#${this._elementId} #main-map-center`);
    let mapCenter = {}
    let coords = this.processLatLong(mainMapCenter.querySelector(`.center-item-lat-long-input`)?.value)
    mapCenter = {
      label: mainMapCenter.querySelector(`.center-item-region-input`)?.value,
      lat: coords.lat,
      long: coords.long,
      zoom: mainMapCenter.querySelector(`.center-item-zoom-input`)?.value
    }
    mapCenter.subRegions = this.getSubRegions();

    return mapCenter
  }

  getSubRegions() {
    let subRegionsContainer = document.querySelector(`#${this._elementId} .sub-region-container`);
    let subRegionItems = subRegionsContainer.querySelectorAll(`.center-item-container`);
    let subRegionsData = {}
    if (subRegionItems.length > 0) {
      subRegionItems.forEach((e) => {
        let coords = this.processLatLong(e.querySelector(`.center-item-lat-long-input`)?.value)
        subRegionsData[e.id] = {
          label: e.querySelector(`.center-item-region-input`)?.value,
          lat: coords.lat,
          long: coords.long,
          zoom: e.querySelector(`.center-item-zoom-input`)?.value,
        }
      });
    }

    return subRegionsData;
  }

  getCities() {
    let citiesContainer = document.querySelector(`#${this._elementId} .cities-container`);
    let cityItems = citiesContainer.querySelectorAll(`.city-item-container`);
    let citiesData = {}
    if (cityItems.length > 0) {
      cityItems.forEach((e) => {
        let coords = this.processLatLong(e.querySelector(`.city-lat-long-input`)?.value);
        citiesData[e.id] = {
          city: e.querySelector(`.city-name-input`)?.value,
          lat: coords.lat,
          long: coords.long,
          zoom: e.querySelector(`.city-zoom-input`)?.value,
          labelPosition: e.getAttribute('data-pin-location'),
          subLevels: this.getSubLevels(e),
          hotels: this.getHotels(e)
        }
      })
    }
    return citiesData;
  }

  getSubLevels(city) {
    let subLevelsContainer = city.querySelector(`.city-sub-levels-container`);
    let subLevelItems = subLevelsContainer.querySelectorAll(`.city-sub-level-item-container`);
    let subLevelData = {}
    if (subLevelItems.length > 0) {
      subLevelItems.forEach((e) => {
        let coords = this.processLatLong(e.querySelector(`.city-lat-long-input`)?.value);
        subLevelData[e.id] = {
          city: e.querySelector(`.city-name-input`)?.value,
          lat: coords.lat,
          long: coords.long,
          zoom: e.querySelector(`.city-zoom-input`)?.value,
          labelPosition: e.getAttribute('data-pin-location'),
          hotels: this.getHotels(e, true)
        }
      })
    }
    return subLevelData;
  }

  getHotels(city, subLevel) {
    let hotelsContainer

    if (subLevel) {
      hotelsContainer = city.querySelector(`.hotels-container.city-sub-level-hotels`);
    } else {
      hotelsContainer = city.querySelector(`.hotels-container:not(.city-sub-level-hotels)`);
    }

    let hotelItems = hotelsContainer.querySelectorAll(`.hotel-item-container`);
    let hotelsData = {}
    if (hotelItems.length > 0) {
      hotelItems.forEach((e) => {
        let coords = this.processLatLong(e.querySelector(`.hotel-lat-long-input`)?.value)
        hotelsData[e.id] = {
          hotelName: e.querySelector(`.hotel-name-input`)?.value,
          lat: coords.lat,
          long: coords.long,
          labelPosition: e.getAttribute('data-pin-location'),
          slideIds: this.getSlides(e),
          extPresentationId: e.querySelector('.external-presentation-id-input').value
        }
        this.getSlides(e)
      })
    }

    return hotelsData;
  }

  getSlides(hotel) {
    let slidesContainer = hotel.querySelector(`.slides-container`);
    let slideItems = slidesContainer.querySelectorAll(`.slide-item-container`);
    let slidesData = {}
    if (slideItems.length > 0) {
      slideItems.forEach((e) => {
        slidesData[e.id] = e.querySelector(`.slide-item-input`)?.value
      })
    }

    return slidesData;
  }

  importCSVbtn() {
    let csvBtnContainer = make('div', 'import-csv-btn-container');
    let csvBtn = make('label', ['import-csv-btn', 'block-btn'])
    let csvInput = make('input', 'import-csv-input');

    csvInput.id = 'import-csv-input';
    csvInput.type = 'file';
    csvInput.setAttribute('accept', '.csv');

    csvInput.addEventListener('change', (event) => {
      this.csvFileHandler(event);
    })

    csvBtn.innerText = 'Import csv';
    csvBtn.append(csvInput);
    csvBtnContainer.append(csvBtn);

    return csvBtnContainer;
  }

  pinLocation(item, itemData) {
    let tag = make('select', 'pin-location-select');
    const optionsArray = ["Bottom", "Top", "Left", "Right"];
    optionsArray.forEach((e, i) => {
      const option = make('option', 'select-option');
      option.text = e;
      option.value = i;

      tag.appendChild(option);
    })

    if (item && itemData && itemData.labelPosition) {
      tag.selectedIndex = optionsArray.indexOf(itemData.labelPosition);
      item.setAttribute('data-pin-location', tag.options[tag.selectedIndex].text)
    }
    else {
      tag.selectedIndex = 0;
      item.setAttribute('data-pin-location', tag.options[0].text)
    }


    tag.onchange = () => {
      // console.log('selected', tag.options[tag.selectedIndex].value);
      item.setAttribute('data-pin-location', tag.options[tag.selectedIndex].text)
    }

    return tag
  }

  csvFileHandler(event) {
    event.preventDefault();

    let file = event.target.files[0];
    const that = this;

    if (file) {
      let reader = new FileReader();
      let mapData = { mapCenter: {}, cities: {} }
      reader.readAsText(file, "UTF-8");
      reader.onload = function (e) {

        const csvFileContent = e.target.result;
        const delimiter = ',';

        let rows = csvFileContent.split("\n");
        rows.forEach((e, i) => {
          let rowArr = e.split(',');
          if (rowArr[0] === "" || rowArr[0] === "\r") {
            return;
          }
          if (i === 0) {
            mapData.mapCenter = {
              label: rowArr[1],
              lat: rowArr[3],
              long: rowArr[5],
              zoom: "5",
              subRegions: {}
            }
          }
          if (i >= 2) {
            let cityId
            if (that.findCityIdByName(mapData, rowArr[0])) {
              cityId = that.findCityIdByName(mapData, rowArr[0])
            }
            else {
              cityId = `city-${that.createId()}`
              mapData.cities[cityId] = {
                city: rowArr[0],
                lat: rowArr[1],
                long: rowArr[2],
                zoom: "12",
                hotels: {}
              }
            }
            let hotelId = `hotel-${that.createId()}`
            mapData.cities[cityId].hotels[hotelId] = {
              hotelName: rowArr[3],
              lat: rowArr[4],
              long: rowArr[5],
              slideIds: {}
            }
            for (let j = 6; j < rowArr.length; j++) {
              if (rowArr[j] === "" || rowArr[j] === "\r") {
                continue;
              }
              else {
                let slideData = rowArr[j].replace(/\r/g, '');
                let slideId = `slide-${that.createId()}`
                mapData.cities[cityId].hotels[hotelId].slideIds[slideId] = slideData;
              }
            }
          }
        })



        let newModal = that.modalElement(mapData);
        let oldModal = document.querySelector(`#${this._elementId} .country-map-modal`);

        newModal.classList.add('show')

        that._data.mapData = mapData;
        that._modal = newModal
        oldModal.replaceWith(that._modal);

      }
      reader.onerror = function (e) {
        console.log('ERROR READING CSV FILE');
      }
    }


  }

  findCityIdByName(map, cityName) {
    for (const cityId in map.cities) {
      if (map.cities.hasOwnProperty(cityId)) {
        if (map.cities[cityId].city === cityName) {
          return cityId;
        }
      }
    }
    return null; // Return null if the city with the given name is not found
  }

  findItemByName(itemName) {
    let map = this._data.mapData;

    for (const cityId in map.cities) {
      if (map.cities.hasOwnProperty(cityId)) {
        if (map.cities[cityId].city.toLowerCase() === itemName.toLowerCase() || map.cities[cityId].city.toLowerCase().includes(itemName.toLowerCase())) {

          return { cityId: cityId, itemId: cityId };
        }
        for (const subLevelId in map.cities[cityId].subLevels) {
          if (map.cities[cityId].subLevels.hasOwnProperty(subLevelId)) {
            if (map.cities[cityId].subLevels[subLevelId].city.toLowerCase() === itemName.toLowerCase() || map.cities[cityId].subLevels[subLevelId].city.toLowerCase().includes(itemName.toLowerCase())) {
              return { cityId: cityId, itemId: subLevelId };
            }
            else {
              for (const hotelId in map.cities[cityId].subLevels[subLevelId].hotels) {
                if (map.cities[cityId].subLevels[subLevelId].hotels.hasOwnProperty(hotelId)) {
                  if (map.cities[cityId].subLevels[subLevelId].hotels[hotelId].hotelName.toLowerCase() === itemName.toLowerCase() || map.cities[cityId].subLevels[subLevelId].hotels[hotelId].hotelName.toLowerCase().includes(itemName.toLowerCase())) {
                    return { cityId: cityId, itemId: hotelId };
                  }
                }
              }
            }
          }
        }
        for (const hotelId in map.cities[cityId].hotels) {
          if (map.cities[cityId].hotels.hasOwnProperty(hotelId)) {
            if (map.cities[cityId].hotels[hotelId].hotelName.toLowerCase() === itemName.toLowerCase() || map.cities[cityId].hotels[hotelId].hotelName.toLowerCase().includes(itemName.toLowerCase())) {
              return { cityId: cityId, itemId: hotelId };
            }
          }
        }
      }
    }
    return null; // Return null if the city with the given name is not found
  }

  alertPopup(prompt, callBack, cancelCallBack) {
    let alertModalContainer = make('div', 'alert-modal-container');
    let alertBackDrop = make('div', 'alert-modal-backdrop');
    let alertModal = make('div', 'alert-modal');
    let modalTitle = make('div', 'alert-modal-title-section');
    let modalContent = make('div', 'alert-modal-content-section');
    let modalPrompt = make('div', 'alert-modal-prompt');
    let modalConfirmBtn = make('button', 'alert-modal-confirm-btn');
    let modalCancelBtn = make('button', 'alert-modal-cancel-btn');

    modalTitle.innerText = 'Warning';
    modalPrompt.innerText = prompt;
    modalConfirmBtn.innerText = 'Confirm';
    modalCancelBtn.innerText = 'Cancel';
    if (callBack) {
      modalConfirmBtn.addEventListener('click', () => callBack());
    }
    modalCancelBtn.addEventListener('click', () => cancelCallBack());

    modalContent.append(modalPrompt);
    modalContent.append(modalConfirmBtn);
    modalContent.append(modalCancelBtn);

    alertModal.append(modalTitle);
    alertModal.append(modalContent);
    alertModalContainer.append(alertBackDrop);
    alertModalContainer.append(alertModal);

    return alertModalContainer;
  }

  save() {
    this._data = {
      mapData: this.processInputs(),
      mapId: this._data.mapId,
      url: this._data.url || '',
      mid: this._data.mid || '',
      x: this._data.x || '',
      y: this._data.y || '',
      width: this._data.width || '',
      height: this._data.height || '',
      maxWidth: this._data.maxWidth || '',
      maxHeight: this._data.maxHeight || '',
    }

    this.processInputs();

    return this._data;
  }
}