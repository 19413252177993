import React from "react";

import UserImage from "../../partials/userImage";

import { Row, Col } from "react-bootstrap";
import { Typography, Badge, Button, Box } from "@mui/material";
import ForumCommentReply from "./forumCommentReply";
import { Link as ScrollLink } from 'react-scroll'

export default function ForumComment(props) {


  const parseHtml = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.innerText
  }

  const formatTime = (unixTime) => {

    const dateCreated = new Date(parseInt(unixTime) * 1000)
    const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" });
    const month = dateCreated.toLocaleString("en-GB", { month: "2-digit" });
    const year = dateCreated.toLocaleString("en-GB", { year: "2-digit" });
    const hour = dateCreated.toLocaleString("en-GB", { hour: "2-digit" });
    const minute = dateCreated.toLocaleString("en-GB", { minute: "2-digit" });
    const timestamp = `${day}.${month}.${year} (${hour}:${minute})`;

    return timestamp;
  }

  return (
    <Row id={`topic-comment-${props.item?.cid}`} className="forum-comment">
      <Col xs={3} className="forum-comment-author">
        <Row className="mb-2">
          <Badge className="forum-comment-author-badge">
            <UserImage
              name={`${props.item?.field_first_name} ${props.item?.field_last_name}`}
              image={props.item?.user_picture}
            />
          </Badge>
        </Row>
        <Row>
          <Typography
            owner="client"
            variant="body2"
            weight="light"
            lh="medium"
            color="clientPrimary.main"
          >
            {`${props.item?.field_first_name} ${props.item?.field_last_name}`}
          </Typography>
        </Row>
        {props.author &&
          <Row>
            <Typography
              owner="client"
              variant="body2"
              weight="light"
              lh="medium"
              color="clientPrimary.main"
            >
              (Author)
            </Typography>
          </Row>
        }
      </Col>
      <Col xs={9} className="forum-comment-content">
        <Row>
          <Col xs="auto">
            <Typography
              owner="client"
              variant="body2"
              weight="light"
              lh="medium"
              color="clientText.main"
            >
              {formatTime(props.item?.created)}
            </Typography>
          </Col>
          <Col xs="auto"></Col>
        </Row>
        {props.replyItem &&
          <Row className="mt-2">
            <Col xs={12}>
              <ForumCommentReply item={props.replyItem} read />
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12}>
            <Typography
              className="mt-2"
              owner="client"
              variant="subtitle1"
              weight="light"
              lh="wide"
            >
              {parseHtml(props.item?.comment_body)}
            </Typography>
          </Col>
        </Row>
        {props?.index > 0 &&
          <Row className="mt-3">
            <Col xs="auto">
              <Box
                className="forum-comment-reply-btn"
              >
                <Typography
                  owner="client"
                  variant="subtitle2"
                  color="clientPrimary.main"
                >
                  <ScrollLink
                    to={'add-comment'}
                    smooth={true}
                    duration={500}
                    hashSpy={true}
                    offset={-74}
                    onClick={() => {
                      props.reply(props.item)
                    }}
                  >
                    Reply
                  </ScrollLink>
                </Typography>
              </Box>
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )
}