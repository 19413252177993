import React, { useEffect, useState } from 'react';

// Modules
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// App
import { serverUrl, oAuthConfig } from '../../config';
import PageHeader from '../../partials/pageHeader';
import isLoggedIn from '../../core/isLoggedIn';
import { getOnlineNode } from '../../core//getNode';

// UI
import { Alert, Typography, CircularProgress } from '@mui/material';
import { Row, Col } from 'react-bootstrap';

export default function OAuthCallback(props) {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    isLoggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn) {
          navigate('/login');
        } else {
          login();
        }
      });
  }, []);

  const login = () => {
    const code = queryParams.get('token');
    const state = queryParams.get('state');

    if (code && state) {
      codeExchange(code, state);
    } else {
      setLoading(false);
      setErrorMessage('Token mismatch');
      setError(true);
    }


    // if (error_response) {
    //   setErrorMessage(error_description ? error_description : 'Unknown error');
    //   setError(true);
    // } else {
    //   const path = `/openid_connect_rest_auth/authenticate/adfs?code=${code}&state=${state}&debug&redirect_uri=${window.location.origin}/login/callback`;

    //   getOnlineNode(path)
    //   .then(response => {
    //     dispatch({ type: 'SAVE_SESSION', payload: response.data });
    //     setLoading(false);
    //     navigate('/login');
    //   })
    //   .catch((error) => {
    //     const errorMessage = error?.response?.data?.message;

    //     if (errorMessage === 'User already logged in') {
    //       handleUser();
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       setErrorMessage(errorMessage ? errorMessage : 'Unknown error');
    //       setError(true);
    //     }
    //   });
    // }
  }

  const codeExchange = async (code, state) => {
    const path = `${serverUrl}/oauth/token`;

    const formData = new FormData();
    formData.append('client_id', oAuthConfig.client_id);
    formData.append('client_secret', oAuthConfig.client_secret);
    formData.append('grant_type', 'authorization_code');
    formData.append('code', encodeURIComponent(code));
    formData.append('redirect_uri', `${window.location.origin}/login/callback`);

    try {
      const response = await axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle the response data, which should include the access token
      handleUser(response.data);

      console.log('handle user?');
    } catch (_error) {
      console.log('error: ', _error);

      // setErrorMessage(_error.response.status);
      setError(true);
      setLoading(false);
    }
  };

  const handleUser = async (tokenData) => {
    getOnlineNode('/current_user', tokenData.access_token)
      .then(response => {
        // const userData = {
        //   access_token: tokenData.access_token,
        //   refresh_token: tokenData.refresh_token,
        //   current_user: response.data[0],
        // };

        // dispatch({type: 'SAVE_SESSION', payload: userData});
      })
      .catch(() => {
        setErrorMessage('Failed to fetch user details. Please try again.');
        setError(true);
        setLoading(false);
      });
  }

  const Loading = () => {
    return (
      <Row>
        <Col className='text-center'>
          <CircularProgress size={20} />
        </Col>
      </Row>
    );
  }

  return (
    <main className={`login screen d-flex flex-column align-items-center`}>
      <Row className={`g-0 ${props.type === 'modal' ? "" : "w-50 mb-3"}`}>
        <PageHeader pageName='Log in' filters={false} />
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <Row className={`g-0 w-50 mt-5`}>
          <Col xs={12}>
            {error && (
              <>
                <Col xs={12} className="mb-2">
                  <Alert variant="filled" severity="error">
                    {`Login failed: ${errorMessage ? errorMessage : 'Unknown error'}`}
                  </Alert>
                </Col>

                <Col xs={12}>
                  <Typography fontSize={16}>Click <a href='/login'>here</a> to go back to login screen.</Typography>
                </Col>
              </>
            )}
          </Col>
        </Row>
      )}
    </main>
  );
}