import React, { useState } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

export default function AccountSkeletonScreen(props) {

  const [backgroundColor, setBackgroundColor] = useState("#f3f3f3");
  const [foregroundColor, setForegroundColor] = useState("#ecebeb");

  return (
    <main className="account  screen">
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 1140 447"
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        style={{ marginTop: '3rem' }}
      >
        <rect x="375.418" y="426.903" width="389.165" height="20" rx="10" ry="10" />
        <rect x="539.941" y="316.694" width="90" height="20" rx="10" ry="10" />
        <rect x="509.162" y="314.694" width="24" height="24" rx="12" ry="12" />
        <rect x="495.202" y="223.838" width="150" height="20" rx="10" ry="10" />
        <rect x="502.417" y="173.387" width="135.045" height="30" rx="15" ry="15" />
        <circle cx="570.202" cy="75" r="75" />
      </ContentLoader>
    </main>
  );
}
