import { useEffect, useState } from "react";
import { serverUrl } from "../config";
import NoImage from "./noImage";

export default function PostImage(props) {

  const [imageError, setImageError] = useState(false);

  return (
    <div className={`${props.className ?? 'article__image'}`}>
      <img src={imageError ? NoImage() : `${serverUrl}${props.imgSrc}`} onError={() => setImageError(true)} alt={'img-' + props.nid} />
    </div>
  );
}