import { ClientIcon, DeleteIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Client {

  static get toolbox() {
    return {
      icon: ClientIcon,
      title: 'Client',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      wrapper: 'cdx-client',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      text: data.text || '',
    };

    this._element = this.getElement();
    this._imageElement = this.imageEl();
    this._textElement = this.textEl();
  }

  /**
   * Check if text content is empty and set empty string to inner html.
   * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
   *
   * @param {KeyboardEvent} e - key up event
   */
  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = this._textElement;

    if (textContent === '') {
      this._textElement.innerHTML = '';
    }
  }

  getElement() {
    let div = document.createElement('div');
    div.classList.add(this._CSS.wrapper);

    div.addEventListener('click', function (event) {
      if (event.target?.nodeName === 'DIV' || event.target?.nodeName === 'IMG') {
        this.classList.toggle('flipped');
      }
    });

    return div;
  }

  imageEl() {
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxWidth);
    }

    return img;
  }

  textEl() {
    let div = document.createElement('P');

    div.contentEditable = false;
    div.dataset.placeholder = this.api.i18n.t(this._placeholder) || 'Description';

    div.innerHTML = this._data.text || '';

    if (!this.readOnly) {
      div.contentEditable = true;
      div.addEventListener('keyup', this.onKeyUp);
    } else {
      div.contentEditable = false;
    }

    return div;
  }

  render() {
    let container = this._element;
    let img = this._imageElement;
    let text = this._textElement;

    let front = document.createElement('div');
    let back = document.createElement('div');
  
    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btn.innerHTML = `${Client.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, this._CSS.xlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
        front.classList.add('has-img')
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btn.innerHTML = `${Client.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height ='';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';
        this._data.text = ''
        
        this._imageElement.remove();
        btnRemove.remove();
      }

      if (typeof img !== 'undefined') {
        front.appendChild(img);
        front.appendChild(btn);
        front.classList.add('has-img')
        front.appendChild(btnRemove);
      } else {
        front.appendChild(btn);
      }

      back.appendChild(text);
    } else {
      if (typeof img !== 'undefined') {
        front.classList.add('has-img')
        front.appendChild(img);
      }

      back.appendChild(text);
    }

    front.classList.add('front');
    back.classList.add('back');

    container.appendChild(front);
    container.appendChild(back);

    return container;
  }

  static get sanitize() {
    return {
        br: true,
    };
  }

  save() {

    return this._data;
  }
}