import React, { useRef, useState, useEffect, useLayoutEffect } from "react";

// Modules
import { useDraggable } from "@dnd-kit/core";
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

// App
import { filesystem_root, subdomain } from "../../../config";
import { jsonParser, getParamFromURL } from "../../../utils/helpers";
import logo from "../../../assets/ca-logo-info.png";
import AudioPlayer from "../../../partials/audioPlayer";

// UI
import {
  Box,
  Button,
  Typography,
  Divider,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  ImageList,
  ImageListItem,
  Tooltip,
  Popover,
  Slider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HomeIcon from "@mui/icons-material/Home";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { ReactComponent as MusicNoteIcon } from "../../../assets/music-notes.svg";
import { ReactComponent as AutoplayIcon } from "../../../assets/slide-autoplay.svg";
import { Row, Col } from "react-bootstrap";

const getChapters = (presentation) => {
  let markers = [];

  presentation?.slides?.forEach((slide, index) => {
    const json = jsonParser(slide.slide_json);

    if (json?.reveal?.slideChapterMarker) {
      markers.push({
        text: json?.reveal?.slideChapterMarker,
        index: `` + index,
        slide_id: slide.id,
      });
    }
  });

  return markers;
};

export default function RevealControls(props) {
  const [isSpeaker, setSpeaker] = useState(false);
  const [isCurrent, setCurrent] = useState(false);
  const [isUpcoming, setUpcoming] = useState(false);
  const [disableDraggable, setDisableDraggable] = useState(false);

  const [scrollToItemId, setScrollToItemId] = useState(null);
  const scrollToItemRef = useRef(null);

  useEffect(() => {
    const receiver = getParamFromURL(
      window.location.search,
      "postMessageEvents"
    );
    const controls = getParamFromURL(window.location.search, "controls");

    if (receiver) {
      setSpeaker(true);
    }

    if (controls === "false") {
      setUpcoming(true);
    } else {
      setCurrent(true);
    }
  }, []);

  const reveal = props.reveal;
  const controlContainer = useRef(null);
  const imageList = useRef(null);
  const isLive =
    props.mode === "view" || props.mode === "preview" ? true : false;

  const presentation_json = jsonParser(props.presentation.json);

  // info
  const [infoDialog, setInfoDialog] = useState(false);

  // thumbnails
  const [openThumbnails, setOpenThumbnails] = useState(false);
  const markers = getChapters(props.presentation)
    ? getChapters(props.presentation)
    : [];

  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl ? true : false;
  const [autoplayMenu, setAutoplayMenu] = useState(false);
  const [autoplayTime, setAutoplayTime] = useState(5);
  const [autoPlay, setAutoplay] = useState(false);
  const [autoplayLoop, setAutoplayLoop] = useState(false);
  const [showPresentationAudioControls, setShowPresentationAudioControls] =
    useState(false);

  // fullscreen
  const [isFullscreen, setIsFullscreen] = useState(false);

  // draggable
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "controls",
    modifiers: [restrictToWindowEdges],
  });
  const style = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }
    : {};

  const handleClickOpen = () => {
    setOpenThumbnails(true);
  };

  const handleClose = () => {
    setOpenThumbnails(false);
  };

  // detect fullscreen change
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    getElementDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indexh]);

  useEffect(() => {
    if (!props.showControls) {
      setAnchorEl(null);
      setAutoplayMenu(false);
      props.showSlideAudioControls(0);
      props.showSlideAudioControls(3);
      props.showPresentationAudioControls(false);
      setShowPresentationAudioControls(false);
    }
  }, [props.showControls]);

  // autoPlay function
  useEffect(() => {
    if (reveal) {
      if (autoPlay) {
        reveal.configure({
          autoSlide: autoplayTime * 1000,
        });
      }

      if (!autoPlay) {
        reveal.configure({
          autoSlide: 0,
        });
      }

      reveal.configure({
        loop: autoplayLoop,
      });
    }
  }, [autoPlay, autoplayTime, autoplayLoop]);

  // useEffect(() => {
  // if (props.setDimensions && controlContainer?.current) {
  //   props.setDimensions({
  //     width: controlContainer.current.clientWidth,
  //     height: controlContainer.current.clientHeight
  //   });
  // }
  // })

  // layout effect
  useLayoutEffect(() => {
    if (controlContainer.current) {
      setTimeout(() => {
        getElementDimensions();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getElementDimensions = () => {
    if (props.setDimensions) {
      props.setDimensions({
        // width: controlContainer.current.clientWidth,
        width: 147,
        height: controlContainer?.current
          ? controlContainer.current.clientHeight
          : 50,
      });
    }
  };

  const handleFullscreenChange = () => {
    const isFs =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    setIsFullscreen(isFs ? true : false);
  };

  const exitFullscreen = () => {
    document.exitFullscreen() ||
      document.webkitExitFullscreen() ||
      document.mozCancelFullScreen() ||
      document.msExitFullscreen();
  };

  const enterFullscreen = () => {
    const element = reveal?.getViewportElement() || document.documentElement;

    element.requestFullscreen() ||
      element.webkitRequestFullscreen() ||
      element.mozRequestFullScreen() ||
      element.msRequestFullscreen();
  };

  const slideAudioActiveIcon = () => {
    return (
      <svg
        id="wave"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 38.05"
      >
        <path
          id="Line_1"
          data-name="Line 1"
          d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
        />
        <path
          id="Line_2"
          data-name="Line 2"
          d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
        />
        <path
          id="Line_3"
          data-name="Line 3"
          d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
        />
        <path
          id="Line_4"
          data-name="Line 4"
          d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
        />
        <path
          id="Line_5"
          data-name="Line 5"
          d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
        />
        <path
          id="Line_6"
          data-name="Line 6"
          d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
        />
        <path
          id="Line_7"
          data-name="Line 7"
          d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
        />
        <path
          id="Line_8"
          data-name="Line 8"
          d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
        />
        <path
          id="Line_9"
          data-name="Line 9"
          d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
        />
      </svg>
    );
  };

  // Scroll to specific item
  useEffect(() => {
    if (scrollToItemId && scrollToItemRef.current) {
      setTimeout(() => {
        scrollToItemRef.current.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }, 50);
    }
  }, [scrollToItemId]);

  // Function to scroll to a specific SortableItem
  const scrollToItem = (itemId) => {
    setScrollToItemId(itemId);
  };

  useEffect(() => {
    const slide = props.presentation?.slides[props.indexh];

    if (slide && slide.id) {
      scrollToItem(`thumb-${slide.id}`);
    }
  }, [props.indexh, props.presentation?.slides, openThumbnails]);

  const tooltipProps = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -10],
        },
      },
    ],
  };

  const offsetTop = autoplayMenu
    ? autoplayMenu
      ? document.querySelector(".viewer-controls-container").offsetTop + 150
      : 200
    : 0;
  const offsetLeft = autoplayMenu
    ? document.querySelector(".viewer-controls-container").offsetLeft
    : 0;

  const getAudioUrl = () => {
    let src = props.presentationAudioObject?.field_media_audio;

    if (props.mode === 'view') {
      src = `${filesystem_root}/${subdomain}/presentations/${props.presentation.id}/${props.presentation.id}/${props.presentationAudioObject?.name}`;
    }

    return src;
  }

  const conditionalListeners = disableDraggable ? {} : listeners;
  const conditionalAttributes = disableDraggable ? {} : attributes;

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        className="thumbnail-control"
        maxWidth="xxl"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiPaper-root": {
            margin: "0",
            width: 1,
          },
          visibility: openThumbnails ? "visible" : "hidden",
          opacity: openThumbnails ? 1 : 0,
        }}
      >
        <DialogContent
          sx={{
            background: "#dadada",
            padding: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              background: "#242424",
              padding: "10px 0",
            }}
            className="title"
          >
            <Typography
              variant="caption"
              sx={{
                paddingLeft: "38px",
                color: "#fff",
              }}
            >
              {props?.presentation?.title}
            </Typography>
          </Box>
          {markers.length > 0 && (
            <Box
              sx={{
                width: "100%",
                overflow: "auto",
                whiteSpace: "nowrap",
                display: "flex",
                background: "#2a2a2a",
              }}
              className="chapters"
            >
              {/* UI HACK */}
              {/* <IconButton sx={{ visibility: 'hidden', 'opacity': 0 }}><NavigateBeforeIcon /></IconButton> */}

              {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                {props?.title}
              </Box> */}

              {markers.map((marker, index) => {
                return (
                  <Box
                    key={`marker-${index}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: 14,
                        textTransform: "none",
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        const pos = marker.index;
                        if (pos) {
                          // why getElementById?
                          // the slides can be updated constantly... keeping a track of an array of refs could be a nightmare

                          const el = document.getElementById(`thumb-${pos}`);
                          if (el) {
                            el.scrollIntoView({
                              behavior: "smooth",
                              inline: "start",
                            });
                          }
                        }
                      }}
                    >
                      {marker.text}
                    </Button>
                    {index !== markers.length - 1 && (
                      <Divider
                        sx={{
                          height: "30px !important",
                          color: "#777777",
                          alignSelf: "center",
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                        orientation="vertical"
                        flexItem
                      />
                    )}
                  </Box>
                );
              })}

              {/* UI HACK */}
              {/* <IconButton sx={{ visibility: 'hidden', 'opacity': 0 }}><NavigateBeforeIcon /></IconButton> */}
            </Box>
          )}

          {props.presentation?.slides?.length > 0 && (
            <Box
              className="thumbnails"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0) !important",
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
                padding: "0",
              }}
            >
              <IconButton
                className="lft-btn"
                aria-label="scroll-left"
                size="small"
                onClick={() => {
                  imageList.current.scrollLeft += -400;
                }}
              >
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <ImageList
                className="image-list-thumb-container"
                ref={imageList}
                gap={10}
                sx={{
                  gridAutoFlow: "column",
                  marginBottom: 0,
                  scrollBehavior: "smooth",
                }}
              >
                {props.presentation?.slides.map((slide, index) => (
                  <ImageListItem
                    key={slide.id}
                    sx={{ padding: "18px 8px" }}
                    id={`thumb-${index}`}
                    ref={index === props.indexh ? scrollToItemRef : null}
                  >
                    <Button
                      sx={{
                        boxShadow: 1,
                        margin: 0,
                        padding: 0,
                        position: "relative",
                        width: 185,
                        height: (185 / 16) * 9,
                      }}
                      onClick={() => {
                        reveal.slide(index);
                        handleClose();
                      }}
                    >
                      <Slide
                        slide={slide}
                        // isLive={props.mode === "preview" ? false : true}
                        isLive={props.mode === 'preview' ? false : true}
                        presentation_id={props.presentation.id}
                      />
                      <Chip
                        sx={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          backgroundColor: "#fff",
                          fontWeight: 300,
                          fontSize: "12px",
                          width: "25px",
                          height: "25px",
                          "& .MuiChip-label": {
                            padding: 0,
                          },
                        }}
                        size="small"
                        label={index + 1}
                      />
                    </Button>
                  </ImageListItem>
                ))}
              </ImageList>
              <IconButton
                className="rt-btn"
                aria-label="scroll-right"
                size="small"
                onClick={() => {
                  imageList.current.scrollLeft += 400;
                }}
              >
                <NavigateNextRoundedIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={infoDialog}
        onClose={() => setInfoDialog(false)}
        maxWidth="xs"
        className="info"
      >
        <DialogContent>
          <Box>
            <Typography variant="body">
              Companyapp is a powerful, flexible & secure sales presentation app
              & software suite developed by ©{" "}
              <a href="https://companyapp.co.uk" target="_blank">
                Companyapp
              </a>
              .
            </Typography>
            <hr />
          </Box>
          <Box>
            <img src={logo} alt={"Companyapp logo"} />
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        className={`viewer-controls-container d-flex justify-content-end ${props.showControls || openThumbnails ? "visible" : ""
          } ${isSpeaker ? "always-visible" : ""} ${isUpcoming ? "always-hide" : ""
          }`}
        id="viewer-controls-container"
        ref={setNodeRef}
        style={{ ...style, ...props.styles }}
        {...conditionalListeners}
        {...conditionalAttributes}
        sx={{
          zIndex: openThumbnails ? 99999 : "",
          width: 149,
        }}
      >
        <Box
          id="controls-container"
          ref={controlContainer}
          className="d-flex align-items-center"
        >
          {!isLive && (
            <IconButton
              className="help"
              size="small"
              aria-label="help"
              onClick={() => {
                props.onHelp();
              }}
            >
              <QuestionMarkIcon />
            </IconButton>
          )}

          {props.hasSlideAudio && (
            <Tooltip
              title={
                props.editMode
                  ? "Show slide audio controls"
                  : "Slide audio"
              }
              PopperProps={tooltipProps}
              placement="top"
            >
              <IconButton
                onMouseEnter={() => {
                  if (props.editMode === false) {
                    props.showSlideAudioControls(2);
                    setShowPresentationAudioControls(false);
                  }
                }}
                id="reveal-controls-slide-audio"
                className="home"
                sx={{ width: 32, height: 32 }}
                size="small"
                aria-label="play"
                onClick={() => {
                  if (props.editMode) {
                    props.showSlideAudioControls(1);
                    setShowPresentationAudioControls(false);
                  } else {
                    props.playSlideAudio();
                  }
                }}
              >
                {props.slideAudioActive ? (
                  slideAudioActiveIcon()
                ) : (
                  <RecordVoiceOverIcon />
                )}
              </IconButton>
            </Tooltip>
          )}

          {presentation_json.homeSlide &&
            presentation_json.homeSlide !== "" && (
              <Tooltip title="Go to Home" placement="top" PopperProps={tooltipProps}>
                <IconButton
                  className="home"
                  size="small"
                  aria-label="home"
                  onClick={() => {
                    reveal.slide(parseInt(presentation_json.homeSlide) - 1);
                  }}
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            )}

          <Box className="viewer-controls">
            {/* <IconButton size='small' aria-label='first' disabled={reveal?.isFirstSlide() ? true : false} onClick={() => { reveal?.slide(0); }}><FirstPageIcon /></IconButton> */}
            {props.hasPresentationAudio && (
              <Box
                className={`viewer-controls-sub-container audio 
                  ${props.editMode ? "edit" : ""} 
                  ${showPresentationAudioControls ? "show" : ""}
                  `}
              >
                <Tooltip
                  title={
                    props.editMode
                      ? "Show presentation audio controls"
                      : "Presentation audio"
                  }
                  placement="top"
                  PopperProps={tooltipProps}
                >
                  <IconButton
                    id="reveal-controls-presentation-audio"
                    sx={{
                      width: 32,
                      height: 32,
                      padding: "3px 0px",
                    }}
                    onMouseEnter={
                      props.editMode
                        ? () => { return }
                        : () => props.showSlideAudioControls(3)
                    }
                    className="reveal-controls-presentation-audio"
                    aria-label="play"
                    onClick={() => {
                      if (props.editMode) {
                        if (showPresentationAudioControls) {
                          setShowPresentationAudioControls(false);
                        } else {
                          setShowPresentationAudioControls(true);
                          props.showSlideAudioControls(3);
                        }
                      } else {
                        props.playPresentationAudio();
                      }
                    }}
                    size="small"
                  >
                    {props.presentationAudioActive ? (
                      slideAudioActiveIcon()
                    ) : (
                      <MusicNoteIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <AudioPlayer
                  audio={getAudioUrl()}
                  audioObject={props.presentationAudioObject}
                  isSlideAudio={false}
                  editMode={props.presentationAudioObject?.editMode}
                  autoPlay={props.presentationAudioObject?.autoPlay}
                  playOverride={props.presentationAudioObject?.playOverride}
                  audioPlaying={(value) =>
                    props.presentationAudioPlaying(value)
                  }
                  showControls={true}
                  hideSlideAudioControls={() => props.hideSlideAudioControls()}
                  promptVisible={props.promptVisible}
                  disableDraggable={(value) => {
                    setDisableDraggable(value);
                  }}
                />
              </Box>
            )}
            <Box className="viewer-controls-sub-container">
              {isLive && (
                <>
                  <Tooltip
                    title="Viewer options"
                    placement="top"
                    PopperProps={tooltipProps}
                  >
                    <IconButton
                      id="context"
                      aria-controls={open ? "more" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(event) => {
                        if (anchorEl) {
                          setAnchorEl(null);
                        } else {
                          props.showPresentationAudioControls(false);
                          props.showSlideAudioControls(3);
                          setAnchorEl(event.currentTarget);
                        }
                      }}
                      size="small"
                      className="more"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    anchorEl={anchorEl}
                    id="context-menu"
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "context",
                    }}
                    className="context-menu"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Slide menu" placement="right">
                        <IconButton
                          className="context-menu-icon-btn"
                          onClick={() => {
                            setAnchorEl(null);
                            handleClickOpen();
                          }}
                        >
                          <MenuIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Fullscreen" placement="right">
                        <IconButton
                          className="context-menu-icon-btn"
                          onClick={() => {
                            setAnchorEl(null);
                            if (isFullscreen) {
                              exitFullscreen();
                            } else {
                              enterFullscreen();
                            }
                          }}
                        >
                          {isFullscreen ? (
                            <CloseFullscreenIcon fontSize="small" />
                          ) : (
                            <OpenInFullIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Autoplay" placement="right">
                        <IconButton
                          className="context-menu-icon-btn"
                          sx={{
                            padding: "5px 6px 6px 6px",
                          }}
                          onClick={() => setAutoplayMenu(true)}
                        >
                          {/* <PlayArrowIcon /> */}
                          <AutoplayIcon className="autoplay-icon" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Info" placement="right">
                        <IconButton
                          className="context-menu-icon-btn"
                          onClick={() => setInfoDialog(true)}
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Popover>
                </>
              )}

              <IconButton
                className="prev"
                size="small"
                aria-label="previous"
                disabled={reveal?.isFirstSlide() ? true : false}
                onClick={() => {
                  reveal?.prev();
                }}
              >
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <IconButton
                className="pager"
                size="small"
                aria-label={props.indexh + 1}
                disabled={!isLive ? true : false}
                onClick={() => {
                  if (isLive) {
                    handleClickOpen();
                  }
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  {props.indexh + 1} / {reveal?.getTotalSlides()}
                </Typography>
              </IconButton>
              <IconButton
                className="next"
                size="small"
                aria-label="next"
                disabled={reveal?.isLastSlide() ? true : false}
                onClick={() => {
                  reveal?.next();
                }}
              >
                <NavigateNextRoundedIcon />
              </IconButton>
            </Box>
            {/* <IconButton size='small' aria-label='last' disabled={reveal?.isLastSlide() ? true : false} onClick={() => { reveal?.slide(reveal?.getTotalSlides()) }}><LastPageIcon /></IconButton> */}
          </Box>
        </Box>
      </Box>
      <Popover
        className="autoplay-menu-popover"
        open={autoplayMenu}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: offsetTop,
          left: offsetLeft,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => {
          setAutoplayMenu(false);
        }}
      >
        <Box className="autoplay-menu-container">
          <Row className="m-0 w-100 d-flex justify-content-center autoplay-play-btn-row">
            <Col xs={12}>
              <Tooltip placement="top" title="Autoplay presentation">
                <Button
                  className="autoPlay-play-btn"
                  endIcon={autoPlay ? <PauseIcon /> : <PlayArrowIcon />}
                  variant="text"
                  onClick={() => {
                    setAutoplay(!autoPlay);
                    setAutoplayMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  {autoPlay ? "Pause" : "Play"}
                </Button>
              </Tooltip>
            </Col>
            <Col xs={"auto"}></Col>
          </Row>
          <Row className="m-0 w-100 d-flex justify-content-center autoplay-slider-row">
            <Col xs={12}>
              <Tooltip placement="left" title="Autoplay speed">
                <Slider
                  className="autoplay-slider"
                  size="small"
                  defaultValue={5}
                  value={autoplayTime}
                  min={5}
                  max={20}
                  valueLabelFormat={`${autoplayTime}s`}
                  onChange={(event) => {
                    setAutoplayTime(event.target.value);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className="m-0 w-100 d-flex justify-content-space-between">
            <Col className="d-flex align-items-center">
              <Typography variant="subtitle1">{autoplayTime}s</Typography>
            </Col>
            <Col>
              <Tooltip placement="bottom" title="Loop presentation">
                <FormControlLabel
                  label="Loop"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={autoplayLoop}
                      onChange={(event) => {
                        setAutoplayLoop(event.target.checked);
                      }}
                      size="small"
                    />
                  }
                />
              </Tooltip>
            </Col>
          </Row>
        </Box>
      </Popover>
    </>
  );
}

const Slide = ({ slide, isLive, presentation_id }) => {
  const [isError, setError] = useState(false);
  const isLibrarySlide =
    slide.initial_presentation === presentation_id ? false : true;

  let thumbnail = `${filesystem_root}/${subdomain}/slides/${slide.id}/slide_${slide.id}_thumbnail.jpg`;

  if (isLive) {
    thumbnail = `${filesystem_root}/${subdomain}/presentations/${presentation_id}/${presentation_id}/thumbnails/slide_${slide.id}_thumbnail.jpg`;
  }

  if (isLibrarySlide) {
    thumbnail = `${filesystem_root}/${subdomain}/presentations/${slide.initial_presentation}/${slide.initial_presentation}/thumbnails/slide_${slide.id}_thumbnail.jpg`;
  }

  if (slide.slide_json) {
    const json = jsonParser(slide.slide_json);

    // has bespoke
    if (json?.reveal?.slideThumbnail?.field_media_image) {
      thumbnail = json.reveal.slideThumbnail?.field_media_image;
    }
  }

  if (isError) {
    return (
      <Box sx={{ width: "100%", height: "100%" }} data-thumb={thumbnail} />
    );
  }

  return (
    <img
      src={thumbnail}
      alt={`${slide.id}_thumbnail.jpg`}
      loading="lazy"
      onError={() => setError(true)}
    />
  );
};
