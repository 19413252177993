// Dependencies
import React from 'react';

// Modules
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// App
import { serverUrl } from '../../config';
import { getExternal } from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import CommentSection from '../../partials/comments/commentSection';
import { formatTime } from '../../utils/helpers';
import { logCustomEvent } from "../../core/analytics";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { MessageSquare, Clock, Tag } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as faThumbsUpSolid, faHeart as faHeartSolid, faSurprise as faSurpriseSurpriseSolid } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpRegular, faHeart as faHeartRegular, faSurprise as faSurpriseSurpriseRegular } from '@fortawesome/free-regular-svg-icons';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import SinglesHeader from '../../partials/singlesHeader';

export default function BlogSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);
  
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams()

  useEffect(() => {
    loadNode()
  }, []);
  
  /**
   * @function setData
   * @description Updates the state of the component upon data retrieval
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const id = params.id;
  
    if (id) {
      getContent(id);
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (id) => {
    let path = `https://companyapp.co.uk/wp-json/wp/v2/posts?include[]=${id}&_embed`;

    getExternal(path)
      .then((response) => {
        if (response.data.length > 0) {
          setData(response.data[0]);
          logCustomEvent('read', {
            nid: response.data[0].id,
            title: response.data[0].title.rendered,
          }, 'Blog');
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        setError(true, 200, 'Unknown error');
      });
  };

  const renderPostImage = (post) => {
    let hasImage = post?._embedded["wp:featuredmedia"].length > 0 ? true : false;

    if (hasImage) {
      return (
        <div className="article__image">
          <img src={`${post?._embedded['wp:featuredmedia'][0]['source_url']}`} alt={'img-' + post.id} />
        </div>
      );
    }
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {
      const timestamp = moment.unix(moment(props.date).format("X"));
      const formattedDate = moment(timestamp).format("Do MMMM YYYY");
  
      let category = 'Uncategorised';

      if (post?._embedded['wp:term'][0][0].name) {
        category = post?._embedded['wp:term'][0][0].name;
      }

      return (
        <main className="blogs single">
          <article className="article">
            {/* <header className="article__header">
              <div className="article__meta">
                <Row className='justify-content-between'>
                  <Col xs={"auto"}>
                    <Link to={"/blogs"} className="back-button_link">
                      <div className="d-flex align-items-center">
                        <ArrowBack />
                        Companyapp Blogs
                      </div>
                    </Link>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      <Col xs={"auto"} className="pe-0">
                        <div className="category align-items-center">
                          <Chip className='category-chip' label={category} />
                        </div>
                      </Col>
                      <Col xs={"auto"}>
                        <div className="timestamp align-items-center">
                          <span className="timestamp__label  label">{formattedDate}</span>
                        </div>

                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <PostTitle title={post.title.rendered} headingLevel={'h1'} />
            </header> */}
            <SinglesHeader 
              route={"/blogs"}
              backBtnText={"Companyapp Blogs"}
              category={category}
              timestamp={props?.date}
              title={post?.title.rendered}
            />
            {renderPostImage(post)}
            <div className="article__body">
              {/* <div dangerouslySetInnerHTML={{__html: post.body}} /> */}
              <PostBody body={post?.content.rendered} />
            </div>
          </article>
        </main>
      );
    }
  }
}
