import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// App
import PageHeader from "../../partials/pageHeader";
import Error from "../../partials/error";
import ClientTheme from "../../core/clientTheme";

// UI components
import Row from "react-bootstrap/Row";
import CompanyappLoader from "../../partials/companyappLoader";

export default function Fiori(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const user = useSelector((state) => state).authReducer.user;
  const clientTheme = { ...ClientTheme() };

  const url = `https://fioridev.equans.co.uk`;

  // const _setError = (_isError, _errorStatus, _errorMessage) => {
  //   setLoading(false);
  //   setIsError(_isError);
  //   setErrorStatus(_errorStatus);
  //   setErrorMessage(_errorMessage);
  // };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="fiori screen">
          <PageHeader
            pageName="My HR Services"
            filters={true}
            owner="client"
            pageNameColor="clientHeadingScreens"
            svgLogo={clientTheme.pageLogos.svg}
            pageLogo={clientTheme.pageLogos.news}
          />

          <iframe
            className={`w-100 ${isLoading && "invisible"}`}
            style={{'minHeight': 500}}
            src={url}
            title={`My HR Services`}
            frameBorder="0"
            onLoad={(iframe) => {
              if (iframe.target.contentWindow) {
                // iframe.target.style.height =
                //   iframe.target.contentWindow.document.body.scrollHeight +
                //   "px";
              }

              setLoading(false);
            }}
          />
        </main>
      )}
    </>
  );
}
