import React, { useState } from "react";

// Modules

// App
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import InstagramEmbed from 'react-instagram-embed';
import Error from "../../../partials/error";
import HomeSkeletonScreen from "../homeSkeletonScreen";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Twitter } from "react-feather";

export default function Home(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(null);
  const [news, setNews] = useState(null);
  const [noticeboard, setNoticeboard] = useState(null);
  const [document, setDocument] = useState(null);
  const [video, setVideo] = useState(null);
  const [event, setEvent] = useState(null);
  const [course, setCourse] = useState(null);


  const renderTwitterTimeline = () => {
    return (
      <Col xs={12} sm={8} className="mb-5 mb-md-0">
        <div className="twitter-container">
          <div className="twitter-container__header">
            <div className="twitter-brand">
              <div className="logo">
                <Twitter />
              </div>
              <span>Twitter</span>
            </div>
            <h6 className="twitter-handle">@RadissonHotels</h6>
          </div>
          <div className="twitter-container__timeline">
            <TwitterTimelineEmbed
              sourceType='profile'
              screenName='RadissonHotels'
            />
          </div>
        </div>
      </Col>
    );
  };

  if (isError) {
    return (
      <Error
        status={errorStatus}
        message={errorMessage}
      />
    );
  } else {
    if (isLoading) {
      return <HomeSkeletonScreen />;
    } else {
      return (
        <main className='home screen'>
          <Row className='mt-5 justify-content-center'>
            {renderTwitterTimeline()}
          </Row>
        </main>
      );
    }
  }
}