export const defaultAnimations = () => {
  return [
    {
      class: '',
      label: 'none',
    },
    {
      class: 'fade-out',
      label: 'fade-out',
    },
    {
      class: 'fade-up',
      label: 'fade-up',
    },
    {
      class: 'fade-down',
      label: 'fade-down',
    },
    {
      class: 'fade-left',
      label: 'fade-left',
    },
    {
      class: 'fade-right',
      label: 'fade-right',
    },
    {
      class: 'fade-in-then-out',
      label: 'fade-in-then-out',
    },
    {
      class: 'fade-in-then-semi-out',
      label: 'fade-in-then-semi-out',
    },
    {
      class: 'grow',
      label: 'grow',
    },
    {
      class: 'semi-fade-out',
      label: 'semi-fade-out',
    },
    {
      class: 'shrink',
      label: 'shrink',
    },
    {
      class: 'strike',
      label: 'strike',
    },
    {
      class: 'highlight-red',
      label: 'hl-red',
    },
    {
      class: 'highlight-green',
      label: 'hl-green',
    },
    {
      class: 'highlight-blue',
      label: 'hl-blue',
    },
    {
      class: 'highlight-current-red',
      label: 'hl-current-red',
    },
    {
      class: 'highlight-current-green',
      label: 'hl-current-green',
    },
    {
      class: 'highlight-current-blue',
      label: 'hl-current-blue',
    },
  ];
}