import { api } from "../config";
import { get_csrf_token } from "./auth";
import { useStore, updateStore } from '../hooks/useStore';

export const customPost = (endpoint, data, headers) => {
  return api({
    method: "post",
    url: endpoint,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const postNode = async (endpoint, node, csrf_token) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let user = useStore();

  let postUri = "node?_format=hal_json";

  if (endpoint) {
    postUri = endpoint;
  }

  try {
    // Attempt the original post request
    return await api({
      method: "post",
      url: postUri + "?_format=hal_json",
      data: JSON.stringify(node),
      headers: {
        Accept: "application/hal+json",
        "Content-Type": "application/hal+json",
        "X-CSRF-Token": csrf_token,
      },
    });
  } catch (error) {
    // Check if the error is due to an invalid CSRF token
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.message.includes(
        "X-CSRF-Token request header is invalid"
      )
    ) {
      // Request a new CSRF token
      const newCsrfToken = await get_csrf_token();

      if (newCsrfToken.data) {
        user.csrf_token = newCsrfToken.data
        updateStore(user);

        // Retry the post request with the new CSRF token
        return api({
          method: "post",
          url: postUri + "?_format=hal_json",
          data: JSON.stringify(node),
          headers: {
            Accept: "application/hal+json",
            "Content-Type": "application/hal+json",
            "X-CSRF-Token": newCsrfToken.data,
          },
        });
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  }
};

export const patchNode = async (endpoint, node, csrf_token, customEndpoint) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let user = useStore();

  let url = endpoint;
  if (!customEndpoint) {
    url = endpoint + "?_format=hal_json";
  }

  try {
    // Attempt the original patch request
    return await api({
      method: "patch",
      url: url,
      data: JSON.stringify(node),
      headers: {
        Accept: "application/hal+json",
        "Content-Type": "application/hal+json",
        "X-CSRF-Token": csrf_token,
      },
    });
  } catch (error) {
    // Check if the error is due to an invalid CSRF token
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.message.includes(
        "X-CSRF-Token request header is invalid"
      )
    ) {
      // Request a new CSRF token
      const newCsrfToken = await get_csrf_token();

      if (newCsrfToken.data) {
        user.csrf_token = newCsrfToken.data
        updateStore(user);

        // Retry the patch request with the new CSRF token
        return api({
          method: "patch",
          url: url,
          data: JSON.stringify(node),
          headers: {
            Accept: "application/hal+json",
            "Content-Type": "application/hal+json",
            "X-CSRF-Token": newCsrfToken.data,
          },
        });
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  }
};

// export const postNode = (endpoint, node, csrf_token) => {
//   let postUri = "node?_format=hal_json";

//   if (endpoint) {
//     postUri = endpoint;
//   }

//   return api({
//     method: "post",
//     url: postUri + "?_format=hal_json",
//     data: JSON.stringify(node),
//     headers: {
//       Accept: "application/hal+json",
//       "Content-Type": "application/hal+json",
//       "X-CSRF-Token": csrf_token,
//     },
//   });
// };

// export const patchNode = (endpoint, node, csrf_token, customEndpoint) => {
//   let url = endpoint;
//   if (!customEndpoint) {
//     url = endpoint + "?_format=hal_json";
//   }

//   return api({
//     method: "patch",
//     url: url,
//     data: JSON.stringify(node),
//     headers: {
//       Accept: "application/hal+json",
//       "Content-Type": "application/hal+json",
//       // 'X-CSRF-Token': csrf_token,
//     },
//   });
// };
