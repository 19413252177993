/**
 * Build styles
 */
require('./index.css').toString();
const { make } = require('./util');

export default class OverlayTool {
  /**
   * Default colour
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_COLOR() {
    return '';
  }

  static get isTune() {
    return true;
  }

  getColor() {
    if (!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)) {
      return this.settings.blocks[this.block.name]
    }
    if (!!this.settings?.default) {
      return this.settings.default
    }
    return OverlayTool.DEFAULT_COLOR
  }

  constructor({ api, data, config, block }) {
    this.api = api;
    this.block = block;
    this.settings = config;
    this.data =
    {
      color: data?.color || this.getColor(),
      opacity: data?.opacity || 1
    };
    this.colors = ["#000000", ...config.options];
    this._CSS = {
      wrapper: 'cdx-color-picker-settings',
      button: 'cdx-color-picker-button',
      span: 'cdx-color-picker',
      slider: 'cdx-opacity-slider',
      sliderLabel: 'cdx-opacity-slider-label',
      overlay: 'overlay-color',
      tuneWrapper: 'editor-block-tune',
      tuneTitle: 'editor-block-tune-title',
      tuneText: 'editor-block-tune-text',
      tuneInput: 'editor-block-tune-input',
      tuneContent: 'editor-block-tune-content-container'
    };
  }

  wrap(blockContent) {
    this.wrapper = make("div");
    this.wrapper.style.width = "100%";
    this.wrapper.style.height = "100%";
    this.wrapper.append(blockContent);

    const target = this.wrapper.querySelector(".cdx-media-container");
    const sliderImgTarget = this.wrapper.querySelector(".cdx-media-wrapper");
    target.style.backgroundColor = this.data.color;
    sliderImgTarget.style.opacity = this.data.opacity;


    return this.wrapper;
  }

  render() {
    const wrapper = make("div", [this._CSS.wrapper, this._CSS.overlay, this._CSS.tuneWrapper]);
    const colorLabel = make("label", [this._CSS.tuneTitle]);
    const content = make("div", [this._CSS.tuneContent]);
    const slider = make("input", [this._CSS.slider, this._CSS.tuneInput]);
    const sliderLabel = make("label", [this._CSS.sliderLabel, this._CSS.tuneTitle]);

    colorLabel.innerText = 'Image background colour:'
    slider.type = 'range';
    sliderLabel.innerText = `Image Opacity: ${Math.floor(this.data.opacity * 100)}%`;
    const sliderImgTarget = this.wrapper.querySelector(".cdx-media-wrapper");

    // Set other attributes (optional)
    slider.id = 'myRange';
    slider.name = 'myRange';
    slider.min = '0';
    slider.max = '1';
    slider.step = '.01';
    slider.value = this.data?.opacity;

    this.colors.map(color => {
      const button = document.createElement('button');
      const span = document.createElement('span');

      button.classList.add(this._CSS.button)
      //  button.style.backgroundColor = color
      button.type = 'button';
      span.classList.add(this._CSS.span);

      span.style.boxShadow = `${color} 0px 0px 0px 15px inset`;
      button.appendChild(span);
      button.classList.toggle('colorCircleActive', color === this.data.color);
      content.appendChild(button);

      return button
    }).forEach((element, index, elements) => {
      element.addEventListener('click', () => {
        this.data = {
          color: this.colors[index]
        }
        elements.forEach((el, i) => {
          el.classList.toggle('colorCircleActive', this.colors[i] === this.data.color);
          const target = this.wrapper.querySelector(".cdx-media-container");
          target.style.backgroundColor = this.data.color;
          this.save();
        });
      });
    });

    slider.addEventListener('input', (e) => {
      sliderImgTarget.style.opacity = e.target.value;
      sliderLabel.innerText = `Image Opacity: ${Math.floor(e.target.value * 100)}%`
      this.data.opacity = e.target.value;
      this.save();
    });

    wrapper.appendChild(colorLabel);
    wrapper.appendChild(content);
    wrapper.appendChild(sliderLabel);
    wrapper.appendChild(slider);

    return wrapper;
  }

  save() {
    return this.data;
  }

  destroy(){
    this.block=null
    
  }
}
