import equansDefault from "../assets/clients/equans/equans-default-image.jpg"
import { client } from "../config"

export default function NoImage(props) {
  switch (client) {
    case "radisson":
      return ''
    case "companyapp":
      return ''
    case "equans":
      return equansDefault
  }
}