import React from 'react';

// Modules

// App
import { alignment, animation, color } from '../../../core/editorOptions';
import { decodeHtmlEntities } from '../../../utils/helpers';
import ImageBlock from './bespokeGalleryImageBlock';

function Title({ block }) {
  const blockColor = color(block, true);
  let styles = {};

  if (blockColor && blockColor.length > 0) {
    styles = {
      color: blockColor
    };
  }

  const text = decodeHtmlEntities(block?.data?.text);

  return <h1 className={`slide-title ${animation(block)} ${alignment(block)}`} style={styles}>{text}</h1>;
}

export default function BespokeImageGallery(props) {
  const images = props.images;
  const title = props.title;

  return (
    <>
      {title && (
        <Title block={title} />
      )}
      <div className='artotel-image-gallery-container'>
        {images.map((e, i) => {
          return (
            <ImageBlock
              image={e}
              index={i + 1}
              live={props.live}
              presentation_id={props.presentation_id}
            />
          )
        })}
      </div>
    </>
  );
};
