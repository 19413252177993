import { ButtonIcon, CloseIcon, LinkIcon, AddButtonIcon, DeleteIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class ButtonGroup {

  static get toolbox() {
    return {
      icon: ButtonIcon,
      title: 'Button group',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }



  constructor({ data, config, api, readOnly, block }) {
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      btnGrpContainer: 'cdx-btn-group-container',
      addBtn: 'cdx-btn-group-add-btn',
      btnItemContainer: 'cdx-btn-item-container',
      btnItem: 'cdx-btn-item',
      urlBtn: 'cdx-btn-item-url-btn',
      buttonSettings: 'cdx-btn-settings-container',
      btnWidthContainer: 'cdx-btn-width-container',
      btnWidthLabel: 'cdx-btn-width-label',
      btnInputContainer: 'cdx-btn-input-container',
      btnWidthInput: 'cdx-btn-width-input',
      btnNameInput: 'cdx-btn-name-input',
      urlInput: 'cdx-btn-item-url-input',
      urlSaveBtn: 'cdx-btn-item-url-save-btn',
      removeBtn: 'cdx-btn-item-remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      tuneWrapper: 'editor-block-tune',
      tuneTitle: 'editor-block-tune-title',
      tuneText: 'editor-block-tune-text',
      tuneInput: 'editor-block-tune-input',
      editorFont: 'editor-font',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      adminFont: 'admin-font'
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      buttonData: data.buttonData || [{ text: 'Button text', url: '' }],
      buttonWidth: data.buttonWidth || '200'
    };
    this._container = ''
    this._btnGrp = document.createElement('div');
  }

  buttonElement(text, url) {
    const css = this._CSS

    const container = document.createElement('div');
    const button = document.createElement('button');
    const urlBtn = document.createElement('button');
    const removeBtn = document.createElement('button');
    const btnSettings = (this.buttonSettings(text, url, button));
    BtnTooltip(urlBtn, EdjsTooltips.buttonGroup.url);
    BtnTooltip(removeBtn, EdjsTooltips.buttonGroup.remove);


    container.classList.add(css.btnItemContainer);
    urlBtn.classList.add(css.urlBtn, css.blockBtn, css.blockBtnSmall, css.xxlIcon);
    button.classList.add(css.btnItem);
    removeBtn.classList.add(css.removeBtn, css.blockBtn, css.blockBtnSmall, css.clear, css.xlIcon);

    button.style.width = `${this._data.buttonWidth}px`;

    urlBtn.innerHTML = LinkIcon;
    removeBtn.innerHTML = DeleteIcon;

    removeBtn.addEventListener('click', () => {
      container.remove();
    });

    urlBtn.addEventListener('click', () => {
      if (!btnSettings.classList.contains('show')) {
        this.hideAllSettings();
        btnSettings.classList.add('show');
      }
    });

    button.innerText = text || 'Button text';

    if (!this.readOnly) {
      container.appendChild(removeBtn);
      container.appendChild(urlBtn);
      container.appendChild(btnSettings);
    }

    container.appendChild(button);

    return container;
  }

  buttonSettings(text, url, btnElement) {
    const css = this._CSS;

    const main = document.createElement('div');
    const inputContainer = document.createElement('div');
    const btnNameInput = document.createElement('input');
    const urlInput = document.createElement('input');
    const saveBtn = document.createElement('button');

    main.classList.add(css.buttonSettings, css.editorBlockModal);
    inputContainer.classList.add(css.btnInputContainer);
    btnNameInput.classList.add(css.btnNameInput, css.editorFont, css.editorBlockModalInput);
    urlInput.classList.add(css.urlInput, css.editorFont, css.editorBlockModalInput);
    saveBtn.classList.add(css.urlSaveBtn, css.blockBtn, css.blockBtnSmall, css.clear);
    btnNameInput.value = text;
    btnNameInput.placeholder = 'Button text';
    urlInput.value = url;
    urlInput.placeholder = 'Button URL';
    saveBtn.innerHTML = CloseIcon;

    saveBtn.addEventListener('click', () => {
      let _url = this.setUrl(urlInput.value);
      urlInput.value = _url;
      btnElement.innerText = btnNameInput.value;
      main.classList.remove('show');
    });

    inputContainer.appendChild(btnNameInput);
    inputContainer.appendChild(urlInput);
    main.appendChild(inputContainer);
    main.appendChild(saveBtn);

    return main;
  }

  renderSettings() {
    const css = this._CSS;
    const btnWidthContainer = document.createElement('div');
    const btnWidthLabel = document.createElement('label');
    const btnWidthInput = document.createElement('input');

    btnWidthContainer.classList.add(css.btnWidthContainer, css.tuneWrapper);
    btnWidthLabel.classList.add(css.btnWidthLabel, css.tuneTitle);
    btnWidthInput.classList.add(css.btnWidthInput, css.tuneInput);

    btnWidthLabel.innerText = 'Button width:';
    btnWidthInput.type = 'range';
    btnWidthInput.min = '120';
    btnWidthInput.max = '250';
    btnWidthInput.value = this._data.buttonWidth;

    btnWidthInput.addEventListener('input', (e) => {
      this.modifyButtonWidth(e.target.value);
    });

    btnWidthContainer.appendChild(btnWidthLabel);
    btnWidthContainer.appendChild(btnWidthInput);

    return btnWidthContainer;
  }

  modifyButtonWidth(value) {
    let buttons = this._btnGrp.querySelectorAll(`.${this._CSS.btnItem}`);
    let sliders = this._btnGrp.querySelectorAll(`.${this._CSS.btnWidthInput}`);
    buttons.forEach((e) => {
      e.style.width = `${value}px`;
    });
    sliders.forEach((e) => {
      e.value = value;
    });
    this._data.buttonWidth = value;
  }

  setUrl(value) {
    let httpPattern = /http:\/\/|https:\/\//;
    // eslint-disable-next-line no-useless-escape
    let urlPattern = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9:%@._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)/g;
    // eslint-disable-next-line no-useless-escape
    let emailPattern = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    // eslint-disable-next-line no-useless-escape
    let phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/g;
    // let slidePattern = /slide\s\d+/;
    let link = "";

    if (value.match(emailPattern)) {
      link = "mailto:" + value;
    }
    else if (value.match(urlPattern)) {
      if (!!(value.match(httpPattern))) {
        link = value;
      }
      else {
        link = "http://" + value;
      }
    }
    else if (value.match(phonePattern)) {
      link = "tel:" + value;
    };

    return link;
  }

  hideAllSettings() {
    let allSettings = this._btnGrp.querySelectorAll(`.${this._CSS.buttonSettings}`);
    allSettings.forEach((e) => {
      e.classList.remove('show');
    })
  }

  render() {
    this._btnGrp.classList.add(this._CSS.btnGrpContainer);
    const addBtn = document.createElement('button');
    BtnTooltip(addBtn, EdjsTooltips.buttonGroup.add);

    addBtn.classList.add(this._CSS.addBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
    addBtn.innerHTML = AddButtonIcon;
    addBtn.addEventListener('click', () => {
      let newBtn = this.buttonElement('Button text', '');
      this._btnGrp.insertBefore(newBtn, addBtn);
    })

    if (this._data.buttonData.length > 0) {
      this._data.buttonData.forEach((e) => {
        let btnItem = this.buttonElement(e.text, e.url);
        this._btnGrp.appendChild(btnItem);
      })
    }

    this._btnGrp.appendChild(addBtn);

    return this._btnGrp;
  }

  getButtonData() {
    let buttons = this._btnGrp.querySelectorAll(`.${this._CSS.btnItemContainer}`);
    let buttonData = [];
    buttons.forEach((e) => {
      let btnSetting = e.querySelector(`.${this._CSS.buttonSettings}`);
      let btnName = btnSetting.querySelector(`.${this._CSS.btnNameInput}`);
      let url = btnSetting.querySelector(`.${this._CSS.urlInput}`);

      buttonData.push({ text: btnName.value, url: url.value });
    });

    return buttonData;
  }

  save() {
    let data = {
      buttonData: this.getButtonData(),
      buttonWidth: this._data.buttonWidth,
    }

    return data;
  }
}