import { useEffect, useState } from "react";

// Modules
import { useSelector, useDispatch } from "react-redux";

// App
import { client } from "../config";
import { get_csrf_token } from "../core/auth";
import { patchNode } from "../core/postNode";
import { checkIfFavorite } from "../utils/helpers";

// UI
import { IconButton, Box, Button } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkedIcon from "@mui/icons-material/Bookmark";
import { Col } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";

export default function FavouriteBtn(props) {
  const user = useSelector((state) => state).authReducer.user;
  const favorites = useSelector((state) => state).favoritesReducer;
  const dispatch = useDispatch();

  const [token, setToken] = useState(user.csrf_token);
  const [isLoading, setLoading] = useState(false);
  const [isFavorite, setFavorite] = useState(checkIfFavorite(favorites, props.id, props.contentType));

  const path = client === 'equans' ? `api/favorites/${user.current_user.uid}` : `api/favorites`;

  const data = {
    id: props.id,
    contentType: props.contentType,
  };

  useEffect(() => {
    if (favorites) {
      setFavorite(checkIfFavorite(favorites, props.id, props.contentType));
    }
  }, [favorites, props.contentType, props.id]);

  useEffect(() => {
    setFavorite(checkIfFavorite(favorites, props.id, props.contentType));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [favorites, props.contentType, props.id]);

  const toggleFavorite = () => {
    setLoading(true);
    patchNode(path, data, token)
      .then((response) => {
        dispatch({ type: "UPDATE_FAVORITES", payload: response.data });
      })
      .catch((error) => {
        if (error.response.data.message === 'X-CSRF-Token request header is invalid') {
          get_csrf_token()
            .then((tokenData) => {
              let newUser = {
                ...user,
                csrf_token: tokenData.data,
              }
              
              dispatch({type: 'SAVE_SESSION', payload: newUser});
              setToken(tokenData.data);
              toggleFavorite();
            });
        }

        setLoading(false);
      });
  };

  const renderIcon = () => {
    if (isLoading) {
      return (
        <CircularProgress sx={{ margin: "2px" }} size={props.mode === "featured" ? "0.6rem" : 14} />
      )
    }
    else {
      if (isFavorite) {
        return (
          <BookmarkedIcon sx={{ fontSize: props.listItem ? "1.2rem" : props.mode === "featured" ? "0.8rem" : "0.9rem" }} />
        )
      }
      else {
        return (
          <BookmarkIcon sx={{ fontSize: props.listItem ? "1.2rem" : props.mode === "featured" ? "0.8rem" : "0.9rem" }} />
        )
      }
    }
  }

  return (
    <>
      <Col
        xs={props.xs ? props.xs : "auto"}
        className={`${props.className ? props.className : "ps-0"} d-flex`}
      >
        <Button
          variant={props.listItem ? "listItemBtn" : "screenItemBtn"}
          owner={props.owner ?? "client"}
          mode={props.mode === "featured" ? "featured" : "default"}
          disableRipple={!props.mode === "featured"}
          onClick={(event) => {
            event.stopPropagation();
            if (!isLoading) {
              toggleFavorite();
            }
          }}
        >
          {renderIcon()}
        </Button>
      </Col>
    </>
  );
}
