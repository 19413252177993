import React, { useEffect, useRef, useState } from "react";

// Modules

// App
import { filesystem_root, subdomain } from '../../../config';
import { jsonParser } from '../../../utils/helpers';

// UI components

export default function Thumbnail(props) {
  const [isError, setError] = useState(false);

  const isLibrarySlide = props.slide.initial_presentation === props.presentation.id ? false : true;
  const rootThumb = isLibrarySlide
    ? `${filesystem_root}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/thumbnails/slide_${props.slide.id}_thumbnail.jpg`
    : `${filesystem_root}/${subdomain}/slides/${props.slide.id}/slide_${props.slide.id}_thumbnail.jpg`;
  const [thumbnail, setThumbnail] = useState(`${rootThumb}?t=${Date.now()}`);
  let interval = useRef(null);
  const [isActive, setActive] = useState(isLibrarySlide ? false : props.isActive);

  useEffect(() => {
    if (props.isActive === true) {
      onItemClicked();
      setActive(true);
    } else {
      setActive(false);
      onItemNavigatedAway();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isActive]);

  useEffect(() => {
    if (isActive === true) {
      if (props.isSlide && props.slide) {
        const json = jsonParser(props.slide.slide_json);
  
        // has bespoke
        if (json?.reveal?.slideThumbnail?.field_media_image) {
          setThumbnail(json.reveal.slideThumbnail?.field_media_image);
        } else {
          interval.current = setInterval(() => {
            setThumbnail(`${rootThumb}?t=${Date.now()}`);
          }, 20000, true);
        }
      }
    } else {
      clearInterval(interval.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const onItemClicked = () => {
    if (props.isSlide && props.slide) {
      const json = jsonParser(props.slide.slide_json);

      // has bespoke
      if (json?.reveal?.slideThumbnail?.field_media_image) {
        setThumbnail(json.reveal.slideThumbnail?.field_media_image);
      } else {
        setThumbnail(`${rootThumb}?t=${Date.now()}`);
      }
    }
  };

  const onItemNavigatedAway = () => {
    setTimeout(() => {
      if (props.isSlide && props.slide) {
        const json = jsonParser(props.slide.slide_json);
  
        // has bespoke
        if (json?.reveal?.slideThumbnail?.field_media_image) {
          setThumbnail(json.reveal.slideThumbnail?.field_media_image);
        } else {
          setThumbnail(`${rootThumb}?t=${Date.now()}`);
        }
      }
    }, 30000);
  }
 
  return (
    <>
      {isError ? (
        <div className='no-thumb' data-thumb={thumbnail} />
      ) : (
        <img
          src={thumbnail}
          alt=''
          onError={() => setError(true)}
        />
      )}
    </>
  );
};
