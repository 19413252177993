// Dependencies
import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// App
import { getOnlineNode } from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import ForumCommentSection from './forumCommentSection';
import PageHeader from '../../partials/pageHeader';
import UserImage from '../../partials/userImage';
import { formatTime } from '../../utils/helpers';
import { logCustomEvent } from "../../core/analytics";

// UI components
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DiscussionIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { Typography, Badge } from '@mui/material';

// Dummy data

export default function ForumTopicSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(null);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_pages, setTotal_Pages] = useState(0);
  const [categoryName, setCategoryName] = useState(null);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams()


  useEffect(() => {
    loadNode()
  }, []);

  useEffect(() => {
    getCategory()
  }, []);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.nid;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = () => {
    let path = `api/forum/topic/${params.tid}/${params.nid}?status=1&promoted=1`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
          logCustomEvent('read', response.data.rows[0], 'Forum Topic');
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        }
        else if (_error.request) {
          setError(true, 0, alertMessages.requestError.message);
        } else {
          setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  const getCategory = () => {
    getOnlineNode(
      // `api/categories?vid=news`,
      `api/categories?tid=${params.tid}`,
      user.access_token,
    )
      .then((response) => {
        setCategoryName(response.data[0]?.title)
      })
      .catch((_error) => {
        if (_error.response) {
          setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  const parseHtml = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.innerText
  }

  const authorMeta = () => {
    return (
      <Container className='px-0 author-meta-container mb-5'>
        <Row className='align-items-center'>
          <Col
            xs={"auto"}
          >
            <Row className='gx-0 align-items-center'>
              <Col
                xs={"auto"}
                className='me-2'
              >
                <Badge
                  className='author-meta-badge'
                >
                  <UserImage
                    name={post.field_first_name && post.field_last_name ? `${post.field_first_name} ${post.field_last_name}` : post.name}
                    image={post.user_picture}
                  />
                </Badge>
              </Col>
              <Col
                xs={"auto"}
              >
                <Typography
                  className='author-meta-name'
                  owner="client"
                  variant='body2'
                  color="clientText.main"
                  weight="light"
                >
                  {post.field_first_name && post.field_last_name ? `${post.field_first_name} ${post.field_last_name}` : post.name}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col
            xs={"auto"}
          >
            <Typography
              className='author-meta-date'
              owner="client"
              variant='body2'
              color="clientText.main"
              weight="light"
            >
              {formatTime(post.created)}
            </Typography>
          </Col>
        </Row>
      </Container>
    )
  }

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {

      return (
        <main className="forums-single screen">
          <article className="article">
            <header className="article__header">
              <div className="article__meta">
                <Row className='justify-content-between'>
                  <Col xs={"auto"}>
                    <Link to={`/forums/${params.tid}`} className="back-button_link">
                      <div className="d-flex align-items-center">
                        <DiscussionIcon small className='me-2'
                          sx={{
                            color: "clientPrimary.main"
                          }}
                        />
                        <Typography
                          owner="client"
                          variant='h6'
                          weight="medium"
                          color="clientPrimary.main"
                          lh="medium"
                        >
                          {categoryName}
                        </Typography>
                      </div>
                    </Link>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      <Col xs={"auto"} className="pe-0">
                      </Col>
                      <Col xs={"auto"}>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* <PostTitle title={parseHtml(post.title)} headingLevel={'h1'} /> */}
            </header>
            <PageHeader
              pageName={parseHtml(post.title)}
              // filters={true}
              owner="client"
              pageNameColor="clientText.dark"
            />
            {authorMeta()}
            <div className="article__body">
              <ForumCommentSection nid={params.nid} subject={parseHtml(post.title)} authorUID={post.uid} />
            </div>
          </article>
        </main>
      );
    }
  }
}

