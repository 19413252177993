import React, { useState } from 'react';

// Modules
import { Link } from 'react-router-dom';

// App
import { filesystem_root, subdomain } from '../../../../../config';
import PostTitle from '../../../../../partials/postTitle';
import { jsonParser } from '../../../../../utils/helpers';

// UI components
import { Col } from 'react-bootstrap';
import { Box, Card, CardMedia, CardActionArea, CardContent, Skeleton, Typography } from '@mui/material';
import { PlayArrow, Check } from '@mui/icons-material';

export default function Presentation(props) {
  const [isHovered, setHovered] = useState(false);
  const [hasImage, setHasImage] = useState(props.item?.slides?.length > 0 ? true : false);

  const getThumbnail = () => {
    let thumb = null;
    const json = jsonParser(props?.item?.json);

    if (json?.thumbnail?.field_media_image) {
      thumb = json.thumbnail?.field_media_image;
    } else {
      if (props.item.slides.length > 0) {
        const slide_json = jsonParser(props?.item?.slides[0]?.slide_json);

        if (slide_json?.reveal?.slideThumbnail?.field_media_image) {
          thumb = slide_json?.reveal?.slideThumbnail?.field_media_image;
        } else {
          const isLibrarySlide = props.item.slides[0].initial_presentation === props.item.id ? false : true;

          if (isLibrarySlide) {
            thumb = `${filesystem_root}/${subdomain}/presentations/${props.item.slides[0].initial_presentation}/${props.item.slides[0].initial_presentation}/thumbnails/slide_${props.item.slides[0].id}_thumbnail.jpg`;
          } else {
            thumb = `${filesystem_root}/${subdomain}/presentations/${props.item.id}/${props.item.id}/thumbnails/slide_${props.item.slides[0].id}_thumbnail.jpg`;
          }
        }
      }
    }

    return thumb;
  };

  return (
    <Col
      key={`presentation-${props.item.id ? props.item.id : Date.now()}`}
      className={`pres-view-item d-flex mb-3 ${isHovered ? 'active' : 'in-active'}`}
      xs={12}
      sm={6}
      md={3}
      xl={props.selector ? 2 : 3}
      xxl={props.selector ? 2 : 3}
    >
      <Card
        className={`mui-card ${isHovered ? 'isHovered' : ''}`}
        variant='presentation-item'
        owner={props.owner ? props.owner : 'admin'}
        sx={{ width: '100%' }}
        onMouseEnter={() => {
          if (!props.selector) {
            setHovered(true)
          }
        }}
        onMouseLeave={() => {
          if (!props.selector) {
            setHovered(false)
          }
        }}
      >
        <CardActionArea
          className='ratio ratio-16x9 media'
          onClick={() => {
            if (props.selector) {
              props.onSelected();
            } else {
              window.open('/presentations/view/' + props.item.id, '_blank')
            }
          }}
        >
          {props.selector &&
            <Box
              className='presentation-id'
              sx={{
                zIndex: 99
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  backgroundColor: '#555',
                  fontSize: '10px',
                  fontWeight: 400,
                  padding: '0 5px',
                  width: 'fit-content',
                }}
              >
                {props.item.id}
              </Typography>
            </Box>
          }
          {props.selector ? (
            <Box className={`select-container ${props.isSelected ? 'selected' : ''}`}>
              <span className='selected-icon'>
                <span className='icon'>
                  <Check fontSize='16px' color="secondary.contrastText" />
                </span>
                <span className='tri' />
              </span>
            </Box>
          ) : (
            <Box
              className='play-container' style={{
                opacity: isHovered ? 1 : 0,
              }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                  zIndex: 99,
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Box className='button'>
                  <Box className='icon-container view'>
                    <PlayArrow fontSize='large' sx={{ fill: '#333' }} />
                  </Box>
                  View
                </Box>
              </Box>
            </Box>
          )}
          {hasImage ? (
            <CardMedia
              className='thumb'
              component='img'
              image={getThumbnail()}
              alt={''}
              onError={() => setHasImage(false)}
            />
          ) : (
            <Box className='skeleton d-flex' data-thumb={getThumbnail()}>
              <Box className='w-50'>
                <Box sx={{ margin: '0 5%', padding: props.selector ? '10px' : '' }}>
                  <Skeleton animation={false} width='30%' height={props.selector ? 13 : 20} />
                  <Skeleton animation={false} width='100%' height={props.selector ? 13 : 20} />
                  <Skeleton animation={false} width='100%' height={props.selector ? 13 : 20} />
                  <Skeleton animation={false} width='100%' height={props.selector ? 13 : 20} />
                  <Skeleton animation={false} width='100%' height={props.selector ? 13 : 20} />
                </Box>
              </Box>
              <Box className='w-50'>
                <Box className='w-100 h-100'>
                  <Skeleton className='right w-100 h-100' animation={false} variant='rectangular' />
                </Box>
              </Box>
            </Box>
          )}
          {!props.selector &&
            <Box className='id-container'>
              <span>
                <span className='id'>{props.item?.id}</span>
              </span>
            </Box>
          }
        </CardActionArea>
        <CardContent className='content'>
          {props.selector ? (
            <Box
              onClick={() => {
                props.onSelected();
              }}
            >
              {props.selector ?
                <Typography variant='subtitle' component='p' sx={{ fontWeight: '500', fontSize: '12px' }}>
                  {props.item.title}
                </Typography>
                :
                <PostTitle
                  title={props.item.title}
                  headingLevel={'h6'}
                  className={`mb-0`}
                  owner={props.owner}
                  titleColor='clientText.dark'
                />
              }
            </Box>
          ) : (
            <Link to={'/presentations/view/' + props.item.id} target='_blank'>
              <PostTitle
                title={props.item.title}
                headingLevel={'h6'}
                owner={props.owner}
                titleColor='clientText.dark'
              />
            </Link>
          )}
        </CardContent>
      </Card>
    </Col>
  );
};
