import React, { useEffect, useState } from 'react';

// Modules
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// App
import { getOnlineNode } from '../../core/getNode';
import CompanyappLoader from '../../partials/companyappLoader';
import PresentationFilters from './partials/filters';
import NoData from '../../partials/noData';
import Presentation from './partials/presentation/view/presentation';
import Pagination from '../../partials/pagination';

// UI components
import { Row, Col } from 'react-bootstrap';
import { Box, Alert } from '@mui/material';

const pageSize = 24;

export default function Presentations() {
  // redux
  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;

  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [isError, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const [filters, setFilters] = useState({
    title: '',
    category: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const [presentations, setPresentations] = useState([]);

  const navigate = useNavigate();

  // initial render
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    // navigate(`?page=${currentPage}`);

    /**
     * @todo Fetch pageNumber from url
     * something like below
     */

    // const params = new URLSearchParams(location.search);
    // const page = params.get('page') ? parseInt(params.get('page')) : 1;

    // if (page !== 1) {
    //   filterReducer.currentPage = page;
    //   dispatch({ type: 'UPDATE_FILTERS', payload: filterReducer });
    // } else {
    //   navigate(`?page=${filterReducer.currentPage}`);
    //   getContent();
    // }
  }, [currentPage, navigate]);

  // redux listener
  // fetch content when filters are updated
  // also runs on initial render
  useEffect(() => {
    setCurrentPage(1);
    getContent(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getCategories = () => {
    getOnlineNode('api/categories?vid=presentation_categories&used=1')
      .then((response) => {
        let newCategories = [{ value: 0, label: 'All Categories', has_content: true, has_view_permission: true, }];
        response.data.forEach(item => {
          if (item.has_view_permission === true) {
            if (item.vid === 'presentation_categories') {
              newCategories.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }
          }
        });

        setCategories(newCategories);
      })
      .catch((error) => {
        // failed to get taxonomies
        console.log('Failed to get taxonomies: ', error);
      });
  };

  const getContent = (page) => {
    setSearching(true);

    let path = `api/user_presentations?status=true&items_per_page=${pageSize}&page=${page ? page : 0}`;

    if (filters.category && filters.category !== 0) {
      path = path + '&category=' + filters.category;
    }

    if (filters.title && filters.title !== '') {
      path = path + '&id_or_title=' + filters.title;
    }

    if (presentations.length > 0) {
      const lastElement = presentations[presentations.length - 1];

      if (lastElement && lastElement.id) {
        path += `&lastId=${lastElement.id}`;
      }
    }

    getOnlineNode(path)
      .then((response) => {
        setLoading(false);
        setSearching(false);
        setPresentations(response.data.rows);
        setTotalItems(response.data.pager.total_items);
      })
      .catch((error) => {
        setError(true);

        if (error?.response?.status) {
          setErrorStatus(error.response.status);
        }

        if (error?.response?.statusText) {
          setErrorMessage(error.response.statusText);
        } else {
          setErrorMessage('Failed to fetch presentations. Unknown error!');
        }
      });
  };

  // const pagination = paginator(totalItems, currentPage, pageSize, 3);

  return (
    <>
      {isLoading ? (
        <div className='circular-container fs'>
          <CompanyappLoader />
        </div>
      ) : (
        <>
          {isError ? (
            <Row className='h-100 justify-content-center align-self-center'>
              <Col>
                <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
                  <Alert variant='filled' severity='error'>
                    {errorStatus ? `${errorStatus} - ` : ''}{errorMessage}</Alert>
                </Box>
              </Col>
            </Row>
          ) : (
            <main className='presentations viewer screen'>
              <PresentationFilters
                pageTitle='Presentations'
                toggleSwitch={
                  permissions?.access_presentation_creator_mode ===
                    true
                    ? true
                    : false
                }
                switchLink={'/presentations-creator'}
                switchStatus={false}
                categories={categories}
                createPresentation={false}
                user={user}
                filters={filters}
                onApply={(filters) => {
                  setFilters(filters);
                }}
                onUpdate={() => {
                  setCurrentPage(1);
                  getContent();
                }}
              />
              {isSearching ? (
                <div className='circular-container fs'>
                  <CompanyappLoader />
                </div>
              ) : (
                <>
                  {presentations.length > 0 ? (
                    <>
                      <Row className='presentation-items-container'>
                        {presentations.map((item, index) => (
                          <Presentation
                            key={`presentation-${item.id}-index-${index}`}
                            item={item}
                            user={user}
                          />
                        ))}
                      </Row>
                      <Pagination
                        owner="admin"
                        containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
                        previousDisabled={currentPage === 1 ? true : false}
                        nextDisabled={presentations.length < pageSize ? true : false}
                        currentPage={currentPage}
                        onPrevious={() => {
                          const current = currentPage - 1;
                          setCurrentPage(current);
                          getContent(current - 1);
                        }}
                        onNext={() => {
                          const current = currentPage + 1;
                          setCurrentPage(current);
                          getContent(current - 1);
                        }}
                      />
                    </>
                  ) : (
                    <NoData activeIndex={0} />
                  )}
                </>
              )}
            </main>
          )}
        </>
      )}
    </>
  );
}