// import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseEnabled } from "../config";

export const logScreenView = (screenName, screenClass, title, nid) => {
  if (firebaseEnabled) {
    // const analytics = getAnalytics();

    // let data = {
    //   screen_name: screenName,
    //   screen_class: screenClass,
    // };

    // if (title) {
    //   data.screen_name = title;
    // }

    // logEvent(analytics, "screen_view", data);
  }
};

export const logCustomEvent = (event_name, post, type) => {
  if (firebaseEnabled) {
    // const analytics = getAnalytics();

    // logEvent(analytics, event_name, {
    //   content_type: type,
    //   nid: parseInt(post.nid, 10),
    //   title: post.title,
    // });
  }
};
