import caLogo from "../assets/ca-logo-pwa.png"
import { Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap"
const version = require("../../package.json").version;

export default function Creator(props) {
  return (
    <div className="creator-banner mt-5">
      <Row>
        <Col className="mt-4 mb-2 d-flex justify-content-center">
          <a
            href="https://companyapp.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="ca-logo-link"
              src={caLogo}
              alt="companyapp.co.uk"
            />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex mb-2 justify-content-center">
            <Typography
              className="mb-0"
              owner="admin"
              variant="body2"
              weight="light"
            >
              Designed &amp; developed by{" "}
              <a
                href="https://companyapp.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                companyapp.co.uk
              </a>
            </Typography>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex mb-2 justify-content-center">

            <Typography
              className="mb-0"
              owner="admin"
              variant="body2"
              weight="light"
            >
              App version: {version}
            </Typography>
          </div>
        </Col>
      </Row>
    </div>
  )
}