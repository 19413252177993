import React, { useEffect, useState } from 'react';

// Modules
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// App
import { getOnlineNode } from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import isLoggedIn from '../../core/isLoggedIn';
import getUser from '../../core/getUser';

// UI
import { Alert, Typography, CircularProgress } from '@mui/material';
import { Row, Col } from 'react-bootstrap';

export default function AuthCallback(props) {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    isLoggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn) {
          navigate('/login');
        } else {
          login();
        }
      });
  }, []);

  const login = () => {
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    const error_response = queryParams.get('error');
    const error_description = queryParams.get('error_description');

    if (error_response) {
      setErrorMessage(error_description ? error_description : 'Unknown error');
      setError(true);
    } else {
      const path = `/openid_connect_rest_auth/authenticate/adfs?code=${code}&state=${state}&debug&redirect_uri=${window.location.origin}/login/callback`;

      getOnlineNode(path)
      .then(response => {
        dispatch({ type: 'SAVE_SESSION', payload: response.data });
        setLoading(false);
        navigate('/login');
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;

        if (errorMessage === 'User already logged in') {
          handleUser();
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(errorMessage ? errorMessage : 'Unknown error');
          setError(true);
        }
      });
    }
  }

  const handleUser = async () => {
    const user = await getUser();
    dispatch({ type: 'SAVE_SESSION', payload: user });
  }

  const Loading = () => {
    return (
      <Row>
        <Col className='text-center'>
          <CircularProgress size={20} />
        </Col>
      </Row>
    );
  }

  return (
    <main className={`login screen d-flex flex-column align-items-center`}>
      <Row className={`g-0 ${props.type === 'modal' ? "" : "w-50 mb-3"}`}>
        <PageHeader pageName='Log in' filters={false} />
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <Row className={`g-0 w-50 mt-5`}>
          <Col xs={12}>
            {error && (
              <>
                <Col xs={12} className="mb-2">
                  <Alert variant="filled" severity="error">
                    {`Login failed: ${errorMessage ? errorMessage : 'Unknown error'}`}
                  </Alert>
                </Col>

                <Col xs={12}>
                  <Typography fontSize={16}>Click <a href='/login'>here</a> to go back to login screen.</Typography>
                </Col>
              </>
            )}
          </Col>
        </Row>
      )}
    </main>
  );
}