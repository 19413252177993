export const alertMessages = {

  /**
   * ------------------------------------------------------------------------
   * AUTH
   * ------------------------------------------------------------------------
   */

  tokenError: {
    title: 'Error',
    message: 'Failed to get token. Please try again by re-starting the app.',
  },

  tokens: {
    title: 'Token Error',
    message:
      'Could not retrieve the required tokens. You may need to login again.',
  },

  noTokens: {
    title: 'Auth Error',
    message:
      'Failed to retrieve tokens from the server. Please try again.',
  },

  invalidUsername: {
    title: 'Invalid username',
    message: 'Please enter a valid username',
  },

  invalidPassword: {
    title: 'Invalid password',
    message: 'Please enter a valid password',
  },

  xscrf: {
    title: 'Error',
    message: 'No XCSRF token set. Please reload the app.',
  },

  loggedOut: 'Sorry, you have been logged out. Please login again.',

  directory: {
    title: 'Directory Error',
    message:
      'Failed to create required directories. You will not be able to store images offline.',
  },

  noData: 'No data available.',
  noMessages2: 'There are currently no Messages to display.',
  noNotice: 'There are currently no Notices to display.',

  fetchdata: {
    title: 'Node Error',
    message:
      'Sorry, unable to fetch the required data. Please try again later.',
  },

  connectionRequired: {
    title: 'Connection Error',
    message: 'Sorry, an internet connection is required for this action.',
  },

  logoutFailed: {
    title: 'Error',
    message: 'Sorry, we can\'t log you out. Please try again.'
  },

  /**
   * ------------------------------------------------------------------------
   * SERVER
   * ------------------------------------------------------------------------
   */

  serverNoResponse: {
    title: 'Server error',
    message: 'No response. Please try again.',
  },


  /**
   * ------------------------------------------------------------------------
   * COURSE
   * ------------------------------------------------------------------------
   */

  courseIncomplete: {
    title: 'Course incomplete',
    message: 'Please review and mark all items before submitting.',
  },

  courseSubmitted: {
    title: 'Success!',
    message: 'Thanks. Your course has been submitted.',
  },

  courseSubmissionFailed: {
    title: 'Submission Failed',
    message: 'Sorry, your course could not be submitted. Please try again.',
  },

  courseNoEntityError: {
    title: 'Error',
    message: 'Sorry, no URL string detected. Please try again.',
  },

  courseSignOff: 'Sign off',

  courseSignOffPrompt:
    'Please confirm that you have read and understood all the information in the course.',

  courseSignOffConfirmation: 'I confirm',

  courseSubmissionNotRequired:
    'This course is for infomation only and does not require a submission',

  courseSubmissionSavedText:
    'You have completed the course - but you can still view the resources below.',

  courseSubmittedAnotherDevice: {
    title: 'Error',
    message: 'Looks like you\'ve already submitted this course on another device.'
  },

  courseQuizSubmission: {
    title: 'Quiz not submitted',
    message: "Please make sure you've submitted the Quiz before confirming.",
  },

  courseFormSubmission: {
    title: 'Form not submitted',
    message: "Please make sure you've submitted the Form before confirming.",
  },

  courseViewItemFirst: {
    title: 'Item not viewed',
    message: 'Please view the item before confirming.',
  },


  /**
   * ------------------------------------------------------------------------
   * QUIZ
   * ------------------------------------------------------------------------
   */

  quizSubmissionWarning: {
    title: 'Submission Notice',
    message:
      'Please note: You can only submit your answers once. Would you like to proceed with the submission?',
  },

  quizSubmitted: {
    title: 'Success!',
    message: 'Thanks. Your quiz has been submitted.',
  },

  quizSubmissionFailed: {
    title: 'Submission Failed',
    message: 'Sorry, your quiz could not be submitted. Please try again.',
  },

  quizSubmissionNotice:
    'Please note: You need to answer all the questions before you are able to submit.',

  quizSubmissionSavedText:
    'You have submitted your answers. The correct answers are below.',


  /**
   * ------------------------------------------------------------------------
   * TIMESHEET SUBMISSION
   * ------------------------------------------------------------------------
   */

  timesheetSubmitted: {
    title: 'Success!',
    message: 'Thanks. Your timesheet has been submitted.',
  },

  timesheetSubmissionFailed: {
    title: 'Submission Failed',
    message: 'Sorry, your timesheet could not be submitted. Please try again.',
  },

  /**
   * ------------------------------------------------------------------------
   * BLOGS
   * ------------------------------------------------------------------------
   */

  blogAvailableOnline:
    'This content is not available offline. Please connect to the internet and try again.',


  /**
   * ------------------------------------------------------------------------
   * CONTACTS
   * ------------------------------------------------------------------------
   */

  contactMailError: {
    title: 'Error',
    message:
      'Sorry we cannot open the mail client. Try copying and pasting the mail address.',
  },
  contactPhoneError: {
    title: 'Error',
    message:
      'Sorry we cannot open the phone client. Try copying and pasting the contact number.',
  },
  contactSmsError: {
    title: 'Error',
    message:
      'Sorry we cannot open the messaging client. Try copying and pasting the contact number.',
  },


  /**
   * ------------------------------------------------------------------------
   * NOTICEBOARD
   * ------------------------------------------------------------------------
   */

  noticeboardTitle: {
    title: 'No title added!',
    message: 'Please enter a valid title. It must be at least 5 characters.',
  },

  noticeboardMessage: {
    title: 'No body text added!',
    message:
      'Please enter a valid body text. It must be at least 5 characters.',
  },

  noticeboadSubmission: {
    title: 'Success!',
    message:
      'Thanks. Your noticeboard is added and it is now awaiting moderation. It will go live once a modaretor has approved it.',
  },

  /**
   * ------------------------------------------------------------------------
   * NOTICE
   * ------------------------------------------------------------------------
   */

   noticeTitle: {
    title: 'No title added!',
    message: 'Please enter a valid title. It must be at least 5 characters.',
  },

  noticeBody: {
    title: 'No body text added!',
    message:
      'Please enter a valid body text. It must be at least 5 characters.',
  },

  noticeSubmission: {
    title: 'Success!',
    message:
      'Thanks. Your notice has been created. Would you like to notify the group members?',
  },

  noticeUpdate: {
    title: 'Success!',
    message:
      'Thanks. Your notice has been updated. Would you like to notify the group members?',
  },

  noticeUpdated: {
    title: 'Success!',
    message: 'Thanks. Your notice has been updated.',
  },

  noticeGroup: {
    title: 'No group selected!',
    message: 'Please select a group.',
  },

  noticeDeleteConfirm: {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this notice?',
  },

  noticeArchived: {
    title: 'Success!',
    message: 'Thanks. Your notice has been archived.',
  },


  /**
   * ------------------------------------------------------------------------
   * SEND MESSAGE
   * ------------------------------------------------------------------------
   */

  messageSubmission: {
    title: 'Success!',
    message: 'Thanks. Your message has been sent.',
  },
  messageFailed: {
    title: 'Error',
    message: 'Sorry, your message could not be sent. Please try again.',
  },


  /**
   * ------------------------------------------------------------------------
   * GALLERY
   * ------------------------------------------------------------------------
   */

  addTitle: 'Add title...',
  addMessage: 'Add message...',
  addAGallery: 'Add a Gallery',
  galleryImagesLabel: 'Gallery images',
  addCaption: 'Add caption...',
  // addRemoveItem: 'Add / Remove item',
  removeItem: 'Remove item',
  addItem: 'Add item',
  featuredImage: 'Featured image',

  imagesSaved: {
    title: 'Success!',
    message: 'Thanks. Your images are added successfully.',
  },

  imagesNotSaved: {
    title: 'Error',
    message: 'Sorry, we could not add your images. Please try again.',
  },

  noImages: {
    title: 'Error',
    message: 'Please attach some images first.',
  },

  galleryTitle: {
    title: 'No title added!',
    message: 'Please enter a valid title. It must be at least 5 characters.',
  },

  galleryMessage: {
    title: 'No body text added!',
    message:
      'Please enter a valid body text. It must be at least 5 characters.',
  },

  gallerySubmission: {
    title: 'Success!',
    message:
      'Thanks. Your gallery is added and it is now awaiting moderation. It will go live once a modaretor has approved it.',
  },


  /**
   * ------------------------------------------------------------------------
   * CROPPER
   * ------------------------------------------------------------------------
   */
  imageConvertError: {
    title: 'Error',
    message: 'Cannot convert image.',
  },
  imageSmallError: {
    title: 'Image too small',
    message: 'Sorry, the cropped image dimensions are too small.',
  },
  // cropper select box
  imageSelectorTitle: 'Select Image',
  takePhotoButtonTitle: 'Take Photo',
  chooseFromLibraryButtonTitle: 'Choose from Library',
  selectImageButton: 'Add image',
  replaceImageButton: 'Replace image',
  removeImageButton: 'Remove image',
  applyCropButton: 'Apply crop',


  /**
   * ------------------------------------------------------------------------
   * NODE ERRORS
   * ------------------------------------------------------------------------
   */

  // node submissions
  nodeSubmissionNetwork: {
    title: 'No connection',
    message: 'Please connect to the internet if you would like to submit a ', // + content type
  },
  // get node
  nodeEndpointFetch: {
    title: 'Error',
    message: 'Sorry, we cannot connect to the server for details.',
  },
  // node create failed
  postNodeFailed: {
    title: 'Error',
    message: 'Sorry, this content could not be created. Please try again.',
  },
  // node patch failed
  patchNodeFailed: {
    title: 'Error',
    message: 'Sorry, this content could not be edited. Please try again.',
  },

  /**
   * ------------------------------------------------------------------------
   * FORUM TOPIC SUBMISSION
   * ------------------------------------------------------------------------
   */
  subjectEmpty: {
    title: 'No Subject',
    message: 'Please enter a subject.',
  },
  bodyEmpty: {
    title: 'No Body',
    message: 'Please enter a body copy.'
  },
  topicPosted: {
    title: 'Success',
    message: 'Thanks. Your topic has been created.',
  },
  topicPostedFailure: {
    title: 'Error',
    message: 'Failed to create topic. Please try again.',
  },
  tidError: {
    title: 'No tid',
    message: 'No tid found. Please try again.'
  },


  /**
   * ------------------------------------------------------------------------
   * COMMENTS
   * ------------------------------------------------------------------------
   */

  commentPostFailure: {
    title: 'Error',
    message: 'Failed to post comment. Please try again.',
  },
  commentPosted: {
    title: 'Success',
    message: 'Thanks. Your comment has been posted and is awaiting moderation.',
  },
  commentEmpty: {
    title: 'No comment',
    message: 'Please enter your comment.',
  },

  /**
   * ------------------------------------------------------------------------
   * MESSAGES
   * ------------------------------------------------------------------------
   */
  messagePostFailure: {
    title: 'Error',
    message: 'Failed to post message. Please try again.',
  },
  messagePosted: {
    title: 'Success',
    message: 'Thanks. Your message has been sent.',
  },
  messageEmpty: {
    title: 'No message body.',
    message: 'Please enter your message.',
  },


  /**
   * ------------------------------------------------------------------------
   * DOCUMENTS
   * ------------------------------------------------------------------------
   */

  documentOpenError: 'Failed to open the document. Please try again.',
  documentDelete: 'Are you sure you want to delete the downloaded document ', // The name of the document
  documentOfflineError:
    'Failed to locate document. Please download it first or make sure you are connected to the internet.',

  /**
   * ------------------------------------------------------------------------
   * VIDEOS
   * ------------------------------------------------------------------------
   */

  videoPlaybackError: 'Failed to open the video. Please try again.',
  videoDelete: 'Are you sure you want to delete the downloaded video ', // The name of the video
  videoOfflineError:
    'Failed to locate video. Please download it first or make sure you are connected to the internet.',


  /**
   * ------------------------------------------------------------------------
   * COMPANY DIRECTORY
   * ------------------------------------------------------------------------
   */

  companyDirectoryMailError: {
    title: 'Error',
    message:
      'Sorry we cannot open the mail client. Try copying and pasting the mail address.',
  },
  companyDirectoryPhoneError: {
    title: 'Error',
    message:
      'Sorry we cannot open the phone client. Try copying and pasting the contact number.',
  },

  /**
   * ------------------------------------------------------------------------
   * MESSAGES
   * ------------------------------------------------------------------------
   */
  noMessages: 'It looks like you don\'t have any messages at the moment.',


  /**
   * ------------------------------------------------------------------------
   * EDIT PROFILE
   * ------------------------------------------------------------------------
   */
  
  editProfilePasswordTooShort: {
    title: 'Password too short',
    message: 'Your password must be at least 6 characters.',
  },

  editProfileUpdateFailed: {
    title: 'Error',
    message: 'Failed to update details.',
  },

  /**
   * ------------------------------------------------------------------------
   * COMMENTS & REACTIONS
   * ------------------------------------------------------------------------
   */
  cannotReactOwnComment: {
    title: 'Error',
    message: 'Sorry, you cannot like your own comment.'
  },
  cannotReact: {
    title: 'Error',
    message:' Cannot like this comment. Please try again later.'
  },

  /**
   * ------------------------------------------------------------------------
   * GROUPS
   * ------------------------------------------------------------------------
   */
   fetchError: {
    title: 'Error',
    message: 'Failed to fetch data. Please try again.'
  },
  noResults: {
    title: 'Nothing found',
    message: 'No users found. Please try a different search term.'
  },
  enterSearchTerm: {
    message: 'Please enter a search term.',
  },
  groupName: {
    title: 'No name given!',
    message: 'Please enter a group name.',
  },
  groupUsers: {
    title: 'No users added!',
    message: 'Please add some users to the group.',
  },
  groupSubmission: {
    title: 'Success!',
    message:
      'The group has been created. You can now create a notice from the previous screen.',
  },


  /**
   * ------------------------------------------------------------------------
   * MISCELLANEOUS
   * ------------------------------------------------------------------------
   */

  unkownError: {
    title: 'Error',
    message: 'Unknown error.',
  },

  noDataFound: 'No content found. Please go online and try again.',

  noConnection: {
    title: 'No connection',
    message: 'Please check your connection and try again.',
  },

  downloadError: {
    title: 'Download Error',
    message: 'Failed to download item. Please try again.',
  },

  dbError: {
    title: 'Database Error',
    message: 'Failed to save the item to the database. Please try again later.',
  },

  requestError: {
    title: 'Request Error',
    message: 'Sorry, unable to make the request. Please try again later.',
  },

  contentError: 'Failed to load content',

  /** USER REGISTRATION */
  register: 'Register',
  requiredField: 'Required field',
  firstNameRequired: 'First name is required.',
  lastNameRequired: 'Last name is required.',
  usernameRequired: 'Username is required.',
  emailRequired: 'Email is required.',
  passwordRequired: 'Password is required.',
  confirmPasswordRequired: {
    title: 'Confirm password',
    message: 'Confirm your password',
  },
  passwordMismatch: {
    title: 'Mismatch',
    message: "Your passwords doesn't match.",
  },
  success: {
    title: 'Account created',
    message:
      "You've succesfully signed up. Your registration is currently awaiting moderation approval and you will be notified via email once approved.",
  },

  firstName: 'First name',
  lastname: 'Last name',
  username: 'Username',
  email: 'Email',
  phoneNumber: 'Phone number',
  password: 'Password',
  temp_password: 'Temporary password',
  confirmPassword: 'Confirm password',
  request: 'Request',
  newPassword: 'New password',
  change_password: 'Change password',

  passwordEmailSent: {
    title: 'Email sent',
    message:
      "A temporary password has been sent to your email address. Please enter it (or copy & paste it) into the 'Temporary password' field below and also enter a new, more memorable password, in the 'New password' field.",
  },
  passwordChangedSuccess: {
    title: 'Password changed',
    message: 'Your password has been changed. You can now login.',
  },

  /**
   * ------------------------------------------------------------------------
   * Presentations
   * ------------------------------------------------------------------------
   */
  presentationSubmission: {
    title: 'Success!',
    message:
      'Your presentation has been created.',
  },
  
  presentationPublish: {
    title: 'Publish?',
    message: 'Are you sure you want to publish the changes of this presentation?',
  },

  presentationUnpublish: {
    title: 'Unpublish?',
    message: 'Any shared links will stop working. Are you sure you want to unpublish this presentation?',
  }
};
