import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// App
import { getOnlineNode } from '../../core/getNode';
import { paginator } from '../../core/paginator';
// import Pagination from '../../core/pagination';
import Pagination from '../../partials/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
// import { _checkContent } from '../../core/checkContent';
import NoData from '../../partials/noData';
import { decodeHtmlEntities } from '../../utils/helpers';
// import VideoItem from './videoItem';
import GalleryItem from './galleryItem';

// UI components
import Row from 'react-bootstrap/Row';
import { FormControl, InputLabel, Select, MenuItem, Input } from '@mui/material';
import CompanyappLoader from '../../partials/companyappLoader';

// Dummy assets
import galleryItem1 from './gallery_dummy_data/galleryItems/galleryItem1.jpg'
import galleryItem2 from './gallery_dummy_data/galleryItems/galleryItem2.jpg'
import galleryItem3 from './gallery_dummy_data/galleryItems/galleryItem3.jpg'


export default function Galleries(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0)
  const [categoryOpen, setCategoryOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;

  const galleryDummyData = [
    {
      nid: "123",
      title: "Gallery Item 1",
      summary: "Dummy description for gallery item 1",
      category_label: "Dummy category",
      created: 1705397698,
      uri: galleryItem1
    },
    {
      nid: "123",
      title: "Gallery Item 2",
      summary: "Dummy description for gallery item 1",
      category_label: "Dummy category",
      created: 1705397698,
      uri: galleryItem2
    },
    {
      nid: "123",
      title: "Gallery Item 3",
      summary: "Dummy description for gallery item 1",
      category_label: "Dummy category",
      created: 1705397698,
      uri: galleryItem3
    },

  ]


  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    getContent(1);
  }, [selectedCategoryItem])


  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };


  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path =
      'api/videos?status=1&promoted=1' +
      '&items_per_page=' +
      pageSize +
      '&page=' +
      (_currentPage - 1);

    const tid = selectedCategory;
    if (tid !== 0) {
      path =
        'api/videos/' +
        tid +
        '/all' +
        '?status=1&promote=1&' +
        'items_per_page=' +
        pageSize +
        '&page=' +
        (_currentPage - 1);
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);
        setData(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };


  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      // 'all_categories/news_categories',
      'api/categories?vid=galleries_categories',
      user.access_token,
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Galleries',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1)
          }}
          onNext={() => {
            onPaginate(currentPage + 1)
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id)
    return categoryName ? categoryName.label : ''
  }

  const dataComponent = () => {
    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {/* {data.map((item, index) => {
            let catName = getCategoryName(item.field_category)
            return (
              <VideoItem item={item} />
            );
          })} */}
          {
            galleryDummyData.map((item, index) => {
              return(
                <GalleryItem
                  item={item}
                />
              )
            })
          }
        </Row>
      );
    }

    else {
      return (
        <NoData activeIndex={activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {
        isError ?

          <Error
            status={errorStatus}
            message={errorMessage}
          />
          :
          <main className="galleries  screen">
            <PageHeader
              pageName="Galleries"
              filters={true}
              owner='client'
              pageNameColor='clientText.dark'
              categorySelector={
                <FormControl variant="standard" fullWidth size='small' >
                  <InputLabel sx={{ fontSize: 14 }}>Galleries</InputLabel>
                  <Select
                    labelId='galleries-category-select-label'
                    id='galleries-category-select'
                    value={selectedCategoryItem}
                    label='Galleries'
                    open={categoryOpen}
                    onClose={() => setCategoryOpen(false)}
                    onOpen={() => setCategoryOpen(true)}
                    input={
                      <Input
                        sx={{
                          fontSize: 14,
                          '&.MuiInput-underline:before': {
                            borderBottomColor: '#d2d0cf',
                          },
                        }}
                        label='Galleries'
                      />
                    }
                  >
                    {categories.map((category, index) => {
                      return (
                        <MenuItem
                          key={`galleries-category-${category.value}`}
                          value={category.value}
                          onClick={() => {
                            handleCategorySelection(category.value)
                          }}
                        >
                          {decodeHtmlEntities(category.label)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              }
              getContent={() => {
                setActiveIndex(0);
                setIsLoading(true);
                getContent(1);
              }}
              activeTabIndex={activeIndex}
            />
            {isLoading ?
              <CompanyappLoader />
              :
              <>
                {dataComponent()}
                {renderPagination()}
              </>
            }
          </main>
      }
    </>
  )
}
