import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
// import ServiceWorkerUpdateNotification from './partials/serviceWorkerUpdate';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { WindowSizeProvider } from './partials/windowSize';

// Modules
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";


// App
import store from './redux/store';
import { firebaseEnabled, firebaseConfig } from './config';

const version = require('../package.json').version;

// UI
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <WindowSizeProvider>
        <App />
      </WindowSizeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// if (firebaseEnabled) {
//   const fbconfig = firebaseConfig();

//   if (fbconfig && fbconfig.apiKey) {
//     const app = initializeApp(fbconfig);
//     const analytics = getAnalytics(app);
//   }
// }


console.log(`v-${version}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
          // const alert = <ServiceWorkerUpdateNotification />
          // ReactDOM.render(alert, document.getElementById('alert'));
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
