
export const EdjsTooltips = {
  "image": {
    "main": "Add or change image",
    "remove": "Remove image"
  },
  "buttonGroup": {
    "add": "Add another button",
    "remove": "Remove button",
    "url": "Edit button label and URL"
  },
  "embed": {
    "modal": "Edit embed data",
    "fallback": "Add a fallback image for offline use"
  },
  "gallery": {
    "add": "Add new image",
    "remove": "Remove image",
    "moveLeft": "Move image left",
    "moveRight": "Move image right"
  },
  "countryMap": {
    "modal": "Edit map data",
    "fallback": "Add a fallback image for offline use"
  },
  "hotelTxtOverlay": {
    "logo": "Select logo"
  },
  "icon": {
    "main": "Change icon",
    "remove" : "Remove icon"
  },
  "locationFooter": {
    "url": "Edit button URL"
  },
  "map": {
    "modal": "Edit map data",
    "fallback": "Add a fallback image for offline use"
  },
  "point": {
    "toggleToText": "Use text instead of image",
    "toggleToImage": "Use an image instead of text",
    "toggleOrientation": "Toggle orientation"
  },
  "popup": {
    "modal": "Edit pop-up data"
  },
  "table": {
    "columnSetting": "Add or delete column",
    "rowSetting": "Add or delete row",
    "addCol": "Add new column",
    "addRow": "Add new row",
    "csvImport": "Create table from CSV file"
  },
  "video": {
    "main": "Add or change video file",
    "remove": "Remove video"
  }
}

