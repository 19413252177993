import React from "react";

// Modules
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { Typography, Box, Link } from "@mui/material";
import * as icons from '../../../../editor/icons/icons'

export default function HelpTextParser(body) {
  const options = {
    replace: domNode => {
      switch (domNode.name) {
        case "p":
          return (
            <Typography
              className="mb-3"
              owner="admin"
              variant="body2"
              color="adminText.main"
              lh="wide"
              weight="light"
            >
              {domToReact(domNode.children, options)}
            </Typography>
          );

        case "icon":
          let attribs = domNode.attribs;

          const props = attributesToProps(attribs);
          // eslint-disable-next-line jsx-a11y/alt-text
          return (
            <button
              className={`block-btn ${attribs.size ? attribs.size : 'small'} ${attribs.iconsize ? attribs.iconsize : 'xxl-icon'} ${attribs.clear ? 'clear' : ''}`}
              dangerouslySetInnerHTML={{ __html: icons[attribs.icon] }}
            ></button>
          );
        case "tools": {
          let attribs = domNode.attribs;
          let active = attribs.active;
          return (
            <div className="help-text-toolbar">
              <div className={`help-text-toolbar-icon ${active === "bold" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.Bold }}></div>
              <div className={`help-text-toolbar-icon ${active === "italic" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.Italic }}></div>
              <div className={`help-text-toolbar-icon ${active === "sub" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.Subscript }}></div>
              <div className={`help-text-toolbar-icon ${active === "sup" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.Superscript }}></div>
              <div className={`help-text-toolbar-icon ${active === "link" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.LinkIcon }}></div>
              <div className={`help-text-toolbar-icon ${active === "slide" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.SlideLinkIcon }}></div>
              <div className={`help-text-toolbar-icon ${active === "popup" ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: icons.PopupLinkIcon }}></div>
            </div>
          )
        }

        default:
          return domNode.type === 'tag' && domNode.children ? domToReact(domNode.children, options) : null;
      }
    }
  }
  return (
    <div>
      {parse(body, options)}
    </div>
  );
}
