import React, { useState, useEffect } from 'react';

// Modules
import { useSelector } from 'react-redux';
import { Redirect } from '../../hooks/redirect';
import { useNavigate } from 'react-router';

// App
import { serverUrl } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { postNode } from '../../core/postNode';
// import {postBase64} from '../../core/postFile';
import { alertMessages } from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import AlertModal from '../../partials/alertModal';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Box, TextField, CircularProgress, Button, Stack, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';

export default function AddPresentation(props) {

  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [themes, setThemes] = useState([{ value: 0, title: 'Select a theme' }]);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [selectedThemeItem, setSelectedThemeItem] = useState({ label: 'Select a theme', value: 0 });
  const [modalVisible, setModalVisible] = useState(false);
  const [postModalVisible, setPostModalVisible] = useState(false);
  const [unauthorised, setUnauthorised] = useState(false);

  const [alertType, setAlertType] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [newNodeId, setNewNodeId] = useState(null);

  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;

  const navigate = useNavigate();

  useEffect(() => {
    if (permissions.access_presentation_creator_mode !== true) {
      setUnauthorised(true);
    }
    else {
      getTaxonomies();
    }
  }, [])

  const getTaxonomies = () => {
    getOnlineNode('api/categories?vid=themes')
      .then(response => {
        let themes = [{ tid: 0, label: 'Select a theme' }];

        response.data.forEach(item => {
          if (item.has_view_permission === true) {
            if (item.vid === 'themes') {
              themes.push({
                ...item,
                value: item.tid,
                label: item.label ? item.label : item.title
              });
            }
          }
        });
        setIsComponentLoading(false);
        setThemes(themes);
      })
      .catch(_error => {
        setIsComponentLoading(false);
      });
  };

  const saveNode = () => {
    setIsLoading(true);

    let theme;
    if (selectedTheme && selectedTheme !== 0) {
      theme = selectedTheme;
    }

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/presentation/presentation',
        },
      },
      title: {
        value: title,
      },
      body: {
        value: body,
      },
      theme: [
        {
          target_id: theme,
        },
      ]
    };

    postNode(
      'entity/presentation',
      data,
      user.csrf_token,
    )
      .then(_response => {
        if (_response.status === 201) {
          setIsLoading(false);
          setPostModalVisible(true);
          setAlertTitle(alertMessages.presentationSubmission.title);
          setAlertBody(alertMessages.presentationSubmission.message);
          setNewNodeId(_response.data.id[0].value);
        } else {
          setIsLoading(false);
          setModalVisible(true);
          setAlertType('error');
          setAlertTitle(alertMessages.postNodeFailed.title);
          setAlertBody(alertMessages.postNodeFailed.message);
          setAlertConfirmButton(true);
          setRedirectBack(false);
        }
      })
      .catch(_error => {
        setIsLoading(false);
        setModalVisible(true);
        setAlertType('error');
        setAlertTitle(alertMessages.postNodeFailed.title);
        setAlertBody(alertMessages.postNodeFailed.message);
        setAlertConfirmButton(true);
        setRedirectBack(false);
      });
  };

  const handleThemeSelection = (tid) => {
    let themeItem = themes.filter(item => {
      return item.tid === tid
    });

    setSelectedThemeItem({
      label: themeItem[0].label,
      value: themeItem[0].tid
    });
    setSelectedTheme(parseInt(tid, 10));
  };

  const getThemeTitle = () => {
    return themes.find(
      x => x.tid === selectedTheme,
    ).title;
  }

  const errorAlerts = () => {
    return (
      <AlertModal
        showAlert={modalVisible}
        showAlertCallback={() => setModalVisible(false)}
        alertType={alertType}
        alertMessageTitle={alertTitle}
        alertMessageBody={alertBody}
        confirmButton={alertConfirmButton}
      />
    )
  }

  if (unauthorised) {
    return <Redirect url='/presentations' />;
  }

  if (isComponentLoading) {
    return (
      <div className='d-flex h-100 justify-content-center align-items-center'>
        <CircularProgress size={20} />
      </div>
    );
  } else {
    return (
      <main className='create-presentation presentations screen'>
        <PageHeader
          pageName='Create a Presentation'
          filters={false}
        />

        {errorAlerts()}

        <div className='ps-modal presentation-settings-modal'>
          <div className='ps-modal-content'>
            <Container>
              <Row>
                <Col xs={12}>
                  <Box className='ls'>
                    <div className='form-group'>
                      <label htmlFor='presentation-title'>Title*</label>
                      <TextField
                        required
                        id='presentation-title'
                        variant='outlined'
                        fullWidth
                        size='small'
                        value={title}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: 28,
                            // paddingTop: '11px',
                            // paddingBottom: '11px'
                          }
                        }}
                        onInput={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='description'>Description*</label>
                      <TextField
                        required
                        id='description'
                        variant='outlined'
                        fullWidth
                        size='small'
                        value={body}
                        sx={{
                          "& .MuiInputBase-root": {
                            minHeight: 45,
                            paddingTop: '3px',
                            paddingBottom: '3px'
                          }
                        }}
                        onInput={(event) => {
                          setBody(event.target.value);
                        }}
                        multiline
                        maxRows={3}
                      />
                    </div>
                  </Box>
                </Col>
                <Col xs={12}>
                  <Box className='rs'>
                    <Box className='form-group'>
                      <label htmlFor='presentationThemes'>Theme*</label>
                      <CategorySelector
                        name='presentationThemes'
                        label='Theme'
                        categories={themes}
                        selectedCategory={selectedThemeItem?.value === 0 ? { label: selectedThemeItem?.label, value: 0 } : selectedThemeItem}
                        outlined={true}
                        variant='outlined'
                        size='small'
                        handleCategorySelection={(tid) => {
                          handleThemeSelection(tid);
                        }}
                      />
                    </Box>
                  </Box>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                    <Button
                      variant='outlined'
                      sx={{
                        backgroundColor: 'primary.contrastText',
                        textTransform: 'none',
                        marginRight: '10px'
                      }}
                      className='cancel'
                      onClick={() => {
                        navigate('/presentations-creator');
                      }}>
                      Cancel
                    </Button>
                    <Button
                      disabled={(isLoading || (title === '' || body === '' || selectedTheme === 0)) ? true : false}
                      variant='contained'
                      color='secondary'
                      className='action'
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={() => {
                        saveNode();
                      }}>
                        {isLoading ? 
                       (
                       <CircularProgress size={20} />
                       ) : (`Create Presentation`)
                      }
                    </Button>
                  </Stack>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {postModalVisible && (
          <AlertModal
            showAlert={postModalVisible}
            onClose={() => {
              if (newNodeId) {
                navigate(`/presentations/edit/${newNodeId}`);
              } else {
                navigate('/presentations-creator');
              }
            }}
            showCaLogo={true}
            alertMessageTitle={"Success"}
            alertMessageBody={alertMessages.presentationSubmission.message}
            cancelButton={false}
            confirmButton={true}
            confirmButtonLabel="Confirm"
            onConfirm={() => {
              if (newNodeId) {
                navigate(`/presentations/edit/${newNodeId}`);
              } else {
                navigate('/presentations-creator');
              }
            }}
          />
        )}
      </main>
    );
  }

}
