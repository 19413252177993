import { api } from '../config';
import { useStore } from '../hooks/useStore';

export const deleteRequest = (endpoint) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useStore();

  let params = {
    method: 'delete',
    url: endpoint,
    headers: {
      'Cache-Control': 'no-cache',
    },
  }

  if (user && user.csrf_token) {
    params.headers['X-CSRF-Token'] = user.csrf_token;
  }

  return api(params);
};
