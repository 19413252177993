import React, { Component } from 'react';

// UI components
import { CircularProgress } from '@mui/material';

export default function LoadingSpinner(props) {
  let containerStyle = 'spinner-container';

  if (props.className && props.className !== undefined) {
    containerStyle = `${props.className} spinner-container`;
  }

  return (
    <div className={containerStyle}>
      <CircularProgress size={20} />
    </div>
  );

}
