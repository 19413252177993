const defaultState = {
  showNavigation: true,
  manualToggle: false,
};

export const navigationReducer = (state = defaultState, action) => {
  

  switch (action.type) {
    case 'TOGGLE_NAVIGATION':

      return {
        showNavigation: action.payload.showNavigation,
        manualToggle: action.payload.manualToggle ? action.payload.manualToggle : false,
      };

    default:
      return state;
  }
};