import React, { useState } from 'react';

import { filesystem_root, subdomain } from '../../../config';
import { decodeHtmlEntities } from '../../../utils/helpers';

import { AccordionArrow } from '../../../editor/icons/icons';

export default function ImageBlock(props) {
  let url = props.image.url;
  let [active, setActive] = useState(false);
  if (props.live) {
    url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
  }

  return (
    <div className={`image-container ${active ? 'active' : ''}`} id={`bsp-img-gal-${props.index}`}>
      <img className='image' src={url} data-mid={props.image.mid} alt={props.image.caption ? props.image.caption : ''} />
      {props.image.caption && (
        <div className='caption-container'>
          <span className='caption'>{decodeHtmlEntities(props.image.caption)}</span>
          <button
            className='caption-toggle'
            dangerouslySetInnerHTML={{ __html: AccordionArrow }}
            onClick={() => {
              let tempActive = active;
              setActive(!tempActive);
            }
            }
          />
        </div>
      )}
    </div>
  )
}