export default class DimensionTune {
  static get isTune() {
    return true;
  }

  /**
  *
  * @param api
  * @param data
  * @param settings
  * @param block
  */
  constructor({ api, data, config, block }) {
    this.api = api;
    this.block = block;
    this.settings = config;

    this.data = {
      dimensions: {
        width: data?.dimensions?.width ? data.dimensions.width : 'auto',
        height: data?.dimensions?.height ? data.dimensions.height : 'auto'
      }
    }

    this._CSS = {
      wrapper: 'cdx-dimension-settings',
      select: 'cdx-settings-select',
      widthSettingContainer: 'cdx-setting-width-container',
      widthLabel: 'cdx-setting-width-label',
      width: 'cdx-setting-width',
      heightSettingContainer: 'cdx-setting-width-container',
      heightLabel: 'cdx-setting-width-label',
      height: 'cdx-setting-height',
      // fragment: 'fragment',
    };
  }

  wrap(blockContent) {
    this.wrapper = document.createElement("div");
    this.wrapper.append(blockContent);

    const target = this.wrapper.querySelector(".cdx-inline-media-wrapper");
    if (this.data.dimensions.width == 'auto' || this.data.dimensions.width == '0') {
      target.style.width = this.data.dimensions.width == 'auto'
    }
    else {
      target.style.width = `${this.data.dimensions.width}px`
    }
    if (this.data.dimensions.height == 'auto' || this.data.dimensions.height == '0') {
      target.style.height = this.data.dimensions.height == 'auto'
    }
    else {
      target.style.height = `${this.data.dimensions.height}px`
    }
    return this.wrapper;
  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  /**
    * rendering block tune
    * @returns {*}
    */
  render() {
    const wrapper = document.createElement('div');
    wrapper.classList.add(this._CSS.wrapper);
    const widthSettingContainer = document.createElement('div');
    const heightSettingContainer = document.createElement('div');
    const widthLabel = document.createElement('label');
    const heightLabel = document.createElement('label');
    const widthInput = document.createElement('input');
    const heightInput = document.createElement('input');

    widthSettingContainer.classList.add(this._CSS.widthSettingContainer);
    widthLabel.classList.add(this._CSS.widthLabel);
    heightSettingContainer.classList.add(this._CSS.heightSettingContainer);
    heightLabel.classList.add(this._CSS.heightLabel);

    widthSettingContainer.appendChild(widthLabel);
    widthSettingContainer.appendChild(widthInput);
    heightSettingContainer.appendChild(heightLabel);
    heightSettingContainer.appendChild(heightInput);

    widthLabel.innerHTML = "Width";
    heightLabel.innerHTML = "Height";

    widthInput.classList.add(this._CSS.width);
    widthInput.setAttribute('type', 'number');
    widthInput.setAttribute('min', '0');

    heightInput.classList.add(this._CSS.height);
    heightInput.setAttribute('type', 'number');
    heightInput.setAttribute('min', '0');


    if (this.data?.dimensions?.width) {
      widthInput.value = this.data?.dimensions?.width
    }

    if (this.data?.dimensions?.height) {
      heightInput.value = this.data?.dimensions?.height
    }

    const target = this.wrapper.querySelector(".cdx-inline-media-wrapper");

    widthInput.addEventListener('input', (e) => {
      if (e.target.value === '' || e.target.value === '0') {
        this.data.dimensions["width"] = 'auto'
      }
      else {
        this.data.dimensions["width"] = e.target.value
        target.style.width = `${this.data.dimensions.width}px`
      }
      target.style.width = this.data.dimensions.width
      this.debounce(() => this.block.dispatchChange());
    })

    heightInput.addEventListener('input', (e) => {
      console.log('height', typeof e.target.value, e.target.value)
      if (e.target.value === '' || e.target.value === '0') {
        this.data.dimensions["height"] = 'auto'
      }
      else {
        this.data.dimensions["height"] = e.target.value
        target.style.height = `${this.data.dimensions.height}px`
      }
      target.style.height = this.data.dimensions.height
      this.debounce(() => this.block.dispatchChange());
    })

    wrapper.appendChild(widthSettingContainer);
    wrapper.appendChild(heightSettingContainer);
    return wrapper;
  }
  /**
  * save
  * @returns {*}
  */
  save() {
    return this.data;
  }
};