import React from "react";

// UI components
import { Link } from "react-router-dom";
import CaLogo from "../assets/ca-logo.png"
import { Button, Typography } from "@mui/material";


export default function Error(props) {
  return (
    <div className="error-container">
      <div className="error">
        <div className="app-icon">
          <img src={CaLogo} alt="companyapp logo" />
        </div>
        {props.status ?? (
          <Typography
            className="mb-3"
            owner="admin"
            variant="body1"
            weight="light"
            lh="medium"
          >
            Error code: {props.status}
          </Typography>
        )}
        
        <Typography
            className="mb-3"
            owner="admin"
            variant="body1"
            weight="light"
            lh="wide"
          >
            {props.message}
          </Typography>

        <Link to={"/"}>
          <Button
            variant="contained"
            color="adminPrimary"
          >
            <Typography
              owner="admin"
              variant="button"
            >
              Back to Home
            </Typography>
          </Button>
        </Link>
      </div>
    </div>
  );
}

