export default class H1 {

    static get isInline() {
      return true;
    }
 
    static get sanitize() {
      return {
        h1: {}
      }
    }

    get state() {
      return this._state;
    }
  
    set state(state) {
      this._state = state;
  
      this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }
  
    constructor({api}) {
      this.api = api;
      this.button = null;
      this._state = false;
  
      this.tag = 'H1';
      this.headings = ['H1','H2','H3','H4','H5','H6']
    //   this.class = 'cdx-marker';
    }
  
    render() {
      this.button = document.createElement('button');
      this.button.type = 'button';
      this.button.innerHTML = '<svg width="18" height="14" class="icon icon--h1" xmlns="http://www.w3.org/2000/svg"><path d="M2.14 1.494V4.98h4.62V1.494c0-.498.098-.871.293-1.12A.927.927 0 0 1 7.82 0c.322 0 .583.123.782.37.2.246.3.62.3 1.124v9.588c0 .503-.101.88-.303 1.128a.957.957 0 0 1-.779.374.921.921 0 0 1-.77-.378c-.193-.251-.29-.626-.29-1.124V6.989H2.14v4.093c0 .503-.1.88-.302 1.128a.957.957 0 0 1-.778.374.921.921 0 0 1-.772-.378C.096 11.955 0 11.58 0 11.082V1.494C0 .996.095.623.285.374A.922.922 0 0 1 1.06 0c.321 0 .582.123.782.37.199.246.299.62.299 1.124zm11.653 9.985V5.27c-1.279.887-2.14 1.33-2.583 1.33a.802.802 0 0 1-.563-.228.703.703 0 0 1-.245-.529c0-.232.08-.402.241-.511.161-.11.446-.25.854-.424.61-.259 1.096-.532 1.462-.818a5.84 5.84 0 0 0 .97-.962c.282-.355.466-.573.552-.655.085-.082.246-.123.483-.123.267 0 .481.093.642.28.161.186.242.443.242.77v7.813c0 .914-.345 1.371-1.035 1.371-.307 0-.554-.093-.74-.28-.187-.186-.28-.461-.28-.825z"/></svg>'
      this.button.classList.add(this.api.styles.inlineToolButton);
  
      return this.button;
    }
  
    surround(range) {
      if (this.state) {
        this.unwrap(range);
        return;
      }
      this.wrap(range);
    }
  
    wrap(range) {
      const selectedText = range.extractContents();
      const tag = document.createElement(this.tag);

      if(this.checkTag().status){
        const tagExists = this.api.selection.findParentTag(this.checkTag().tag)
        tagExists.remove()
        // console.log(tagExists)
      }
      
      tag.appendChild(selectedText);
      range.insertNode(tag);
  
      this.api.selection.expandToTag(tag);
    }
  
    unwrap(range) {
      const tag = this.api.selection.findParentTag(this.tag);
        // this.class);
      const text = range.extractContents();
  
      tag.remove();
  
      range.insertNode(text);
    }
  
  
    checkState() {
      const mark = this.api.selection.findParentTag(this.tag);
  
      this.state = !!mark;
    }

    checkTag() {
      const tagExists = {
        status: false,
        tag: null
      }
      this.headings.forEach(tag=>{
        if(this.api.selection.findParentTag(tag)){
          tagExists.status = true
          tagExists.tag = tag
        }
      })
      return tagExists
    }
  }