import React, { Component, useState } from 'react';

// Modules
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

// UI components
import { BookOpen, Home, User, BarChart2 } from 'react-feather';
import { FormControlLabel, IconButton, Typography } from '@mui/material';


export default function RHGSidebar(props) {
  const [activeItem, setActiveItem] = useState("home")

  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;
  const currentLocation = useSelector((state => state.locationReducer.currentLocation));


  const handleClick = () => {
    if (props.isMobile) {
      props.sidebarVisibility();
    }
  };

  let showNavigation = "collapsed";
  if (props.showNavigation === true || props.showNavigation === 'true') {
    showNavigation = "expanded";
  }

  const hasCreate = permissions.access_presentation_creator_mode ? true : false;

  return (
    <div className={`navbar-container ${showNavigation} ${currentLocation === 'presentations-creator' ? 'presentations-creator' : ''}`}>
      {props.isMobile && (
        <div className="mobile-overlay" onClick={props.sidebarVisibility}></div>
      )}
      <nav className={`navbar ${showNavigation}`}>
        {typeof user.current_user !== 'undefined' ? (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("home")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'home' ? 'active' : ''}
                    control={
                      <IconButton >
                        <Home size={20} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Home
                      </Typography>}
                  />
                  {/* <div className="nav-item__icon">
                    <Home size={20} />
                  </div>
                  <span className="nav-item__label">Home</span> */}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={hasCreate ? 'presentations-creator' : 'presentations'}
                  className={({ isActive, isPending }) => {
                    if (isActive) {
                      setActiveItem("presentations")
                    }
                    return "nav-link"
                  }}
                  onClick={() => handleClick()}
                >
                  <FormControlLabel
                    variant='nav-item'
                    color={activeItem === 'presentations' ? 'active' : ''}
                    control={
                      <IconButton >
                        <BookOpen size={20} />
                      </IconButton>}
                    label={
                      <Typography variant='span' owner='client'>
                        Presentations
                      </Typography>}
                  />
                </NavLink>
              </li>

              {permissions.access_radisson_4u == true && (
                <li className="nav-item">
                  <NavLink
                    to={'radissonforyou'}
                    className={({ isActive, isPending }) => {
                      if (isActive) {
                        setActiveItem("radissonforyou")
                      }
                      return "nav-link"
                    }}
                    onClick={() => handleClick()}
                  >
                    <FormControlLabel
                      variant='nav-item'
                      color={activeItem === 'radissonforyou' ? 'active' : ''}
                      control={
                        <IconButton >
                          <BarChart2 size={20} />
                        </IconButton>}
                      label={
                        <Typography variant='span' owner='client'>
                          Radisson For You
                        </Typography>}
                    />
                  </NavLink>
                </li>
              )}
            </ul>
          </>
        ) : (
          <>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  onClick={() => handleClick()}
                >
                  <div className="nav-item__icon">
                    <Home size={20} />
                  </div>
                  <span className="nav-item__label">Home</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="nav-item">
                <NavLink
                  to="/login"
                  className="nav-link"
                  onClick={() => handleClick()}
                >
                  <div className="nav-item__icon">
                    <User size={20} />
                  </div>
                  <span className="nav-item__label">Login</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
}


// const mapStateToProps = (state) => ({
//   user: state.authReducer.user,
//   permissions: state.permissionsReducer.permissions,
// });

// export default connect(mapStateToProps)(Sidebar);

