import React from 'react';

// Modules
import { decode } from 'html-entities';

import { Typography } from '@mui/material';

export default function PostTitle(props) {
  let title = props.title;
  let style = props.className ? `${props.className} title` : 'title';

  try {
    title = decode(title)
  } catch (error) {
    // console.log('cannot decode')
  }

  if (props.maxLength) {
    let TITLE_MAX_LENGTH = props.maxLength;

    if (title.length > TITLE_MAX_LENGTH) {
      title = title.substring(0, TITLE_MAX_LENGTH);
      title = title.substring(0, title.lastIndexOf(' '));
      title += '...';
    }
  }

  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  const safeHeading = props.headingLevel ? props.headingLevel.toLowerCase() : '';

  // If not valid heading, default to <p>
  const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

  return (
    <Typography
      className={`${style} ${props.hovered ? 'isHovered' : ''}`}
      variant={Title}
      owner={props.owner ? props.owner : 'admin'}
      color={props.titleColor}
      weight={props.weight}
      lh={props.lh}
      family="secondary"
      >
      {title}
    </Typography>
  );
}