// Dependencies
import React from 'react';

// Modules
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Gallery from 'react-photo-gallery';

// App
import { serverUrl, filesystem_root, subdomain } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { postNode } from '../../core/postNode';
import { deleteRequest } from '../../core/delete';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import CommentSection from '../../partials/comments/commentSection';
import { formatTime } from '../../utils/helpers';

// UI components
import { Carousel, Row, Col } from 'react-bootstrap';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Chip, Typography, Button, Dialog, Box } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FullscreenOutlined, FullscreenExitOutlined } from '@mui/icons-material';
import SinglesHeader from '../../partials/singlesHeader';

import galleryPic1 from './gallery_dummy_data/gallerySinglePics/gallery-single-1.jpg'
import galleryPic2 from './gallery_dummy_data/gallerySinglePics/gallery-single-2.jpg'
import galleryPic3 from './gallery_dummy_data/gallerySinglePics/gallery-single-3.jpg'
import galleryPic4 from './gallery_dummy_data/gallerySinglePics/gallery-single-4.jpg'
import galleryPic5 from './gallery_dummy_data/gallerySinglePics/gallery-single-5.jpg'
import galleryPic6 from './gallery_dummy_data/gallerySinglePics/gallery-single-6.jpg'
import galleryPic7 from './gallery_dummy_data/gallerySinglePics/gallery-single-7.jpg'
import galleryPic8 from './gallery_dummy_data/gallerySinglePics/gallery-single-8.jpg'
import galleryPic9 from './gallery_dummy_data/gallerySinglePics/gallery-single-9.jpg'
import galleryPic10 from './gallery_dummy_data/gallerySinglePics/gallery-single-10.jpg'
import galleryPic11 from './gallery_dummy_data/gallerySinglePics/gallery-single-11.jpg'
import galleryPic12 from './gallery_dummy_data/gallerySinglePics/gallery-single-12.jpg'

export default function GallerySingle(props) {
  // dummyData
  const dummyData = {
    base_url: 'https://dev-cms.companyapp.net',
    body: `<p>These are some extra words which don't really mean anything. It's just demo copy that just fills up some space. It's here instead of the usual Lorem Ipsum Latin text that people use, because seeing the wrong language can be confusing and distracting. </p>`,
    created: '1692092245',
    field_allow_comments: 'False',
    field_allow_reactions: 'False',
    field_category: '1189',
    field_feature: 'True',
    field_featured_image: '/sites/default/files/styles/featured_image/public/2023-08/businessman-holds-mobile-phone-displays-graphic-graph-showing-statistics-copy.jpg?h=7ec1598e&itok=E5MZyJ0M',
    nid: '6',
    sticky: 'True',
    summary: 'This is a short description about the gallery',
    title: 'Gallery 1',
    uid: '262',
  }


  const galleryPics = [
    {
      src: galleryPic1,
      title: 'Gallery pic 1',
      description: 'Gallery pic 1 description These are some extra words which don\'t really mean anything. It\'s just demo copy that just fills up some space.',
      width: 3346,
      height: 4736,
    },
    {
      src: galleryPic2,
      title: 'Gallery pic 2',
      description: 'Gallery pic 2 description',
      width: 6000,
      height: 4000,
    },
    {
      src: galleryPic3,
      title: 'Gallery pic 3',
      description: 'Gallery pic 3 description',
      width: 5504,
      height: 6880,
    },
    {
      src: galleryPic4,
      title: 'Gallery pic 4',
      description: 'Gallery pic 4 description',
      width: 5472,
      height: 3648,
    },
    {
      src: galleryPic5,
      title: 'Gallery pic 5',
      description: 'Gallery pic 5 description',
      width: 5472,
      height: 3648,
    },
    {
      src: galleryPic6,
      title: 'Gallery pic 6',
      description: 'Gallery pic 6 description',
      width: 3648,
      height: 5472,
    },
    {
      src: galleryPic7,
      title: 'Gallery pic 7',
      description: 'Gallery pic 7 description',
      width: 5763,
      height: 3847,
    },
    {
      src: galleryPic8,
      title: 'Gallery pic 8',
      description: 'Gallery pic 8 description',
      width: 2072,
      height: 3158,
    },
    {
      src: galleryPic9,
      title: 'Gallery pic 9',
      description: 'Gallery pic 9 description',
      width: 3465,
      height: 2131,
    },
    {
      src: galleryPic10,
      title: 'Gallery pic 10',
      description: 'Gallery pic 10 description',
      width: 6000,
      height: 4000,
    },
    {
      src: galleryPic11,
      title: 'Gallery pic 11',
      description: 'Gallery pic 11 description',
      width: 3000,
      height: 2000,
    },
    {
      src: galleryPic12,
      title: 'Gallery pic 12',
      description: 'Gallery pic 12 description',
      width: 4488,
      height: 3017,
    },
  ]

  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(dummyData);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_pages, setTotal_Pages] = useState(0);
  const [isReactionsLoading, setIsReactionsLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(null);
  const [reactions, setReactions] = useState(
    {
      hasReacted: false,
      likes: 0,
      love: 0,
      suprised: 0,
    },);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [showViewer, setShowViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewFullscreen, setViewFullscreen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();
  const fullscreenRef = useRef(null);

  useEffect(() => {
    loadNode()
  }, []);
  useEffect(() => {
    if (post) {
      getCategory()
    }
  }, [post]);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    // setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
    // getCategory(_data.field_category);
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = 'api/videos/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
        } else {
          setIsLoading(false);
          // setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        // if (_error.response) {
        //   setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // }
        // else if (_error.request) {
        //   setError(true, 0, alertMessages.requestError.message);
        //   console.log('this got triggered 1');
        // } else {
        //   setError(true, 0, alertMessages.unkownError.message);
        //   console.log('this got triggered 2');
        // }
      });
  };

  const getCategory = (id) => {
    if (post.field_category) {
      getOnlineNode(
        // `api/categories?vid=news`,
        `api/categories?tid=${post.field_category}`,
        user.access_token,
      )
        .then((response) => {
          let categoryTemp = response.data.length > 0 ? response.data[0] : { title: '' }
          setCategoryName(categoryTemp.title)
        })
        .catch((_error) => {
          if (_error.response) {
            setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            //console.log('@rest request: ', _error.request);
            setError(true, 0, alertMessages.requestError.message);
          } else {
            //console.log('@rest unknown: ', _error);
            setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  }

  const setViewerIndex = (photo) => {
    let itemIndex = galleryPics.findIndex((e) => e.title === photo.title);

    if (itemIndex > -1) {
      setActiveIndex(itemIndex);
      setShowViewer(true);
    }
  }


  const galleryElement = ({ photo }) => {
    return (
      <Button
        className='gallery-element'
        style={{
          width: photo.width,
          height: photo.height
        }}
        onClick={() => {
          setViewerIndex(photo)
        }}
      >
        <img src={photo.src} alt='' />
      </Button>
    )
  }

  const renderGallery = () => {
    return (
      <Gallery photos={galleryPics}
        targetRowHeight={180}
        renderImage={galleryElement}
      />
    )
  }

  const renderPostDescription = (post) => {
    return (
      <Typography
        className='post-video-description'
        owner='client'
        variant='body1'
        color='clientText.main'
        weight='light'
      >
      </Typography>
    )
  }

  const enterFullscreen = () => {
    const element = fullscreenRef.current;

    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // For Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // For Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // For IE/Edge
        element.msRequestFullscreen();
      }
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {
      let category = 'Uncategorised';

      if (categoryName) {
        category = categoryName;
      }

      if (post.categoryLabel) {
        category = post.categoryLabel;
      }

      return (
        <main className='galleries single'>
          <article className='article'>
            <SinglesHeader
              route={"/Galleries"}
              backBtnText={"Galleries"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            <Row className='gallery-container'>
              {renderGallery()}
            </Row>
            <PostBody body={post.body} />
            <div className='article__body'>
            </div>
            <CommentSection nid={post.nid} />
          </article>
          <Dialog className='gallery-image-viewer'
            maxWidth='lg'
            fullWidth
            open={showViewer}
            onClose={() => {
              setShowViewer(false);
              setViewFullscreen(false);
            }}
            fullScreen
            ref={fullscreenRef}
          // fullScreen={fullScreen}
          // sx={{
          //   "& .MuiPaper-root": !fullScreen ? {
          //     width: '80%',
          //     height: '80%'
          //   } : {}
          // }}
          >
            <Button className="close-viewer-btn"
              onClick={() => {
                setShowViewer(false)
                setViewFullscreen(false);
              }}
            >
              <CloseRoundedIcon
              />
            </Button>
            <Button className="fullscreen-viewer-btn"
              onClick={() => {
                if (viewFullscreen) {
                  exitFullscreen();
                  setViewFullscreen(false)
                }
                else {
                  enterFullscreen();
                  setViewFullscreen(true)
                }
              }
              }
            >
              {viewFullscreen ?
                <FullscreenExitOutlined />
                :
                <FullscreenOutlined />
              }
            </Button>
            <Carousel
              className='image-viewer-carousel'
              activeIndex={activeIndex}
              controls={true}
              indicators={false}
              slide={true}
              onSelect={(index) => setActiveIndex(index)}
              interval={null}
              touch={true}
            >
              {galleryPics.map((e, i) => {
                return (
                  <Carousel.Item className='image-viewer-carousel-item'>
                    <Box className='image-item-container'>
                      <Box className='image-item-reducer'>
                        <img className='image-item' src={e.src} alt={e.title} data-description={e.description} />
                      </Box>
                      <Box className="image-caption-container">
                        <Typography className="image-caption"
                          owner='admin'
                          variant='body2'
                          color='adminText.contrastText'
                          weight='regular'
                          align='center'
                        >
                          {e.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Dialog>
        </main>
      );
    }
  }
}

